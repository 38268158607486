import { useTonAddress } from "@tonconnect/ui-react"
import { useEffect } from "react"
import { userService } from "../../../services/user/service"
import { userStore } from "../../../services/user/store"
import css from "./BlackPumaClaim.module.scss"
import { ClaimedEarly } from "./components/ClaimedEarly"
import { ClaimedList } from "./components/ClaimedList"
import { Claimer } from "./components/Claimer"
import { NftCongratsModal } from "./components/NftCongratsModal/NftCongratsModal"
import { NftInfoModal } from "./components/NftInfoModal/NftInfoModal"
import { charactersService } from "./service"
import { useCharactersStore } from "./store"

export const CharactersClaimPage = () => {
	const {
		selectedNftToShow,
		isNftInfoModalOpen,
		isNftCongratsModalOpen,
		nftClaimingItem,
	} = useCharactersStore()

	const wallet = useTonAddress()
	const userId = userStore(state => state.user?.id)

	useEffect(() => {
		if (!userId) {
			userService.getUserData()
		}
		if (userId && wallet) {
			charactersService.getCurrentCollectionData(userId, wallet)
		}
	}, [userId, wallet])

	const handleNftInfoModalClose = () => {
		charactersService.setIsNftInfoModalOpen(false)
		// userId && charactersService.getCurrentCollectionData(userId, wallet)
	}
	const handleNftCongratsModalClose = () => {
		charactersService.setIsNftCongratsModalOpen(false)
		// userId && charactersService.getCurrentCollectionData(userId, wallet)
	}

	return <div className={css.wrapper}>
		<Claimer />
		<ClaimedList />
		<ClaimedEarly />
		{selectedNftToShow ? <NftInfoModal
			open={isNftInfoModalOpen}
			onClose={handleNftInfoModalClose}
			nftInfo={selectedNftToShow}
		/> : null}
		<NftCongratsModal
			open={isNftCongratsModalOpen}
			onClose={handleNftCongratsModalClose}
			nftInfo={nftClaimingItem || selectedNftToShow}
		/>
	</div>
}
