import { StackingModal } from "./index";
import { stakingStore } from "../../store";

interface PropsTypes {
  isModalOpen: boolean;
  setIsModalOpen: (c: boolean) => void;
}

export const StackingWithdrawModal = ({ isModalOpen, setIsModalOpen }: PropsTypes) => {
  const stakedValue = stakingStore((state) => (+state.stakedBalance + state.stakingInfo.totalTicketsAmount).toFixed(2));

  return (
    <StackingModal
      title={"Withdraw"}
      type={"withdraw"}
      titleValue={stakedValue}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <></>
    </StackingModal>
  );
};
