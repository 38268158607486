import { useEffect, useState } from "react";
import { getTimeDifference } from "../utils/getTimeDifference";
import { isTimePassed } from "../utils/isTimePassed";

export const useTimer = (targetDate: Date) => {
  const {
    d: daysLeft,
    h: hoursLeft,
    m: minutesLeft,
    s: secondsLeft,
  } = getTimeDifference(targetDate.getTime());

  const [time, setTime] = useState({
    days: daysLeft,
    hour: hoursLeft,
    minute: minutesLeft,
    seconds: secondsLeft,
  });

  const [isTimerFinished, setIsTimerFinished] = useState<boolean>(
    isTimePassed(targetDate)
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!isTimePassed(targetDate)) {
      timer = setTimeout(() => {
        setTime({
          days: daysLeft,
          minute: minutesLeft,
          hour: hoursLeft,
          seconds: secondsLeft,
        });
        if (isTimerFinished) {
          setIsTimerFinished(false);
        }
      }, 1000);
    } else {
      setIsTimerFinished(true);
    }

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursLeft, minutesLeft, secondsLeft, daysLeft, time, targetDate]);

  return [time, isTimerFinished] as [
    { days: number; hour: number; minute: string; seconds: string },
    boolean,
  ];
};
