import { BOXES } from '../../mockUpData';
import { BoxItem } from './BoxItem/BoxItem';
import { Accordions } from './components/Accordions/Accordions';
import { StartStake } from './components/StartStake/StartStake';
import styles from './styles.module.scss';

export const StakingInfo = () => {
  return (
    <div className={styles.stakingInfo}>
      <div className={styles.leftColumn}>
        <StartStake />
        <Accordions />
      </div>
      <div className={styles.rightColumn}>
        {BOXES.slice(0, 5).map(box => <BoxItem key={box.name} box={box} />)}
      </div>
    </div>
  )
}

