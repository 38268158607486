import React, { useEffect, useState } from "react";

import { IPet } from "../../../types";
import { EvolutionModal } from "../EvolutionModal/EvolutionModal";
import { UpgradeModal } from "../UpgradeModal/UpgradeModal";
import { getNextEvolutionAvailability } from "../../../helpers/getNextEvolutionAvailability";
import { petsService } from "../../../service/service";

interface PropsTypes {
  isOpened: boolean;
  currentPet: IPet;
}

export const ProgressionModal = ({
  isOpened,
  currentPet,
}: PropsTypes) => {
  const isEvolutionAvailable = getNextEvolutionAvailability(currentPet);

  const [isEvolutionModalOpened, setIsEvolutionModalOpened] =
    useState(isEvolutionAvailable);

  useEffect(() => {
    setIsEvolutionModalOpened(isEvolutionAvailable);
  }, [isOpened, isEvolutionAvailable]);

  if (!isOpened) {
    return null;
  }

  const handleUpgradeModalOpen = () => {
    setIsEvolutionModalOpened(false);
  };

  const handleClose = () => {
    petsService.setIsProgressionModalOpened(false);;
  };

  return (
    <>
      <EvolutionModal
        open={isEvolutionModalOpened}
        onClose={handleClose}
        selectedPet={currentPet}
        currentPet={currentPet}
        onCancel={handleUpgradeModalOpen}
      />
      <UpgradeModal
        open={!isEvolutionModalOpened}
        onClose={handleClose}
        selectedPet={currentPet}
      />
    </>
  );
};
