export type NFTRarityDisplayProps = {
  color: string
  titleI18key: string
}

export type NFTProps = {
  imageUrl: string
  title: string
  rarity: NFTRarity
}

export enum NFTRarity {
  legendary = "legendary",
  epic = "epic",
  rare = "rare",
  uncommon = "uncommon",
  common = "common",
}

export type NftCollectionCurrent = {
  id: string,
  name: string,
  description: string,
  address: string,
  price: number,
  isCurrent: boolean,
  createdAt: string,
  updatedAt: string
}
export type NftCollectionCurrentResponse = NftCollectionCurrent

export enum ClaimStaus {
  started = "STARTED",
  processing = "PROCESSING",
  finished = "FINISHED"
}

export enum RarityType {
  common = 'puma_common',
  uncommon = 'puma_uncommon',
  rare = 'puma_rare',
  epic = 'puma_epic',
  legendary = 'puma_legendary',
}

export type GeneratedNftType = {
  claimStatus: ClaimStaus
  createdAt: string
  id: string
  signature: string
  updatedAt: string
  url: string
}

export type NftPropsRaw = {
  id: string,
  user?: {},
  userId?: string,
  clickerUserId?: string,
  collection?: {},
  collectionId: string,
  index?: number,
  url: string,
  rarity?: RarityType,
  signature: string,
  claimStatus: ClaimStaus,
  createdAt: string,
  updatedAt: string
}

export type NftCollectionClaimsResponse = {
  claims: NftPropsRaw[],
  currentProcessingClaim?: NftPropsRaw
}


export type NftCollectionClaimsRequest = {
  userId?: string
  clickerUserId?: string
  wallet?: string
}

export type EarlyNftType = {
  id: string
  rarity: NFTRarity
  nftIndex: number
  source: "clicker" | "dashboard"
  url: string
}