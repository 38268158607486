import React, { FC } from 'react';
import './ReferralsCopy.scss';
import cn from "classnames";
import rcbg from "../../../../assets/images/referrals/rcbg.png";
import { ReferralsCopyCode } from "./ReferralsCopyCode/ReferralsCopyCode";
import { ReferralsCopyLink } from "./RefferalsCopyLink/ReferralsCopyLink";

interface IReferralsCopyProps {
  className?: string;
  codeValue: string;
  linkValue: string;
}

const ReferralsCopy: FC<IReferralsCopyProps> = ({
  className,
  codeValue,
  linkValue
}) => {
  const cl = cn("referrals-copy", className);

  return (
    <div className={cl}>
      <ReferralsCopyCode className="referrals-copy__code" codeValue={codeValue} />
      <ReferralsCopyLink className="referrals-copy__link" linkValue={linkValue} />

      <div className="referrals-copy__decor">
        <img src={rcbg} alt="Decoration background" loading="lazy" />
      </div>
    </div>
  );
};


export default ReferralsCopy;
