import { NavigateFunction } from "react-router-dom";

class PublicNavigateAPI {
  navigate = (() => {}) as NavigateFunction;

  setPublicNavigate(navigateFunction: NavigateFunction) {
    this.navigate = navigateFunction;
  }
}

const publicNavigate = new PublicNavigateAPI();

export default publicNavigate;
