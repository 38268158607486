import { useSearchParams } from "react-router-dom";
import { SocialStates } from "../constants/common";
import { useEffect } from "react";
import { userService } from "../services/user/service";

export const useSocialsConnect = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const state = searchParams.get("state") as SocialStates | null;
    const code = searchParams.get("code");

    if (state && code) {
      if (state === SocialStates.twitter) {
        void userService.connectTwitter(code);
      } else {
        void userService.connectDiscord(code);
      }
    }

    (async () => {
      const telegramData = searchParams.get("telegram");

      if (!!telegramData) {
        userService.connectTelegram(JSON.parse(telegramData));
      }
    })();
  }, [searchParams]);
};
