import box from "../../assets/images/Box.png";
import boxGradient from "../../assets/images/BoxGradient.png";
import box1 from "../../assets/images/Box1.png";
import box1Gradient from "../../assets/images/Box1Gradient.png";
import box2 from "../../assets/images/Box2.png";
import box2Gradient from "../../assets/images/Box2Gradient.png";
import box3 from "../../assets/images/Box3.png";
import box3Gradient from "../../assets/images/Box3Gradient.png";
import box4 from "../../assets/images/Box4.png";
import box4Gradient from "../../assets/images/Box4Gradient.png";
import box5 from "../../assets/images/Box5.png";
import box5Gradient from "../../assets/images/Box5Gradient.png";
import box6 from "../../assets/images/Box6.png";
import box6Gradient from "../../assets/images/Box6Gradient.png";
import weaponBox from "../../assets/images/WeaponBox.png";
import weaponBoxGradient from "../../assets/images/WeaponBoxGradient.png";
import clothetBox from "../../assets/images/ClothetBox.png";
import clothetBoxGradient from "../../assets/images/ClothetGradient.png";

export const ACCORDIONS = [
  {
    id: 1,
    title: "staking.info.accordion1.name",
    description: [
      {
        desc: "staking.info.accordion1.description1",
      },
      {
        desc: "staking.info.accordion1.description2",
      },
      {
        desc: "staking.info.accordion1.description3",
      },
    ],
  },
  {
    id: 2,
    title: "staking.info.accordion2.name",
    description: [
      {
        desc: "staking.info.accordion2.description1",
      },
    ],
  },
  {
    id: 3,
    title: "staking.info.accordion3.name",
    description: [
      {
        desc: "staking.info.accordion3.description1",
      },
      {
        desc: "staking.info.accordion3.description2",
      },
    ],
  },

];

export const BOXES = [
  {
    name: "staking.info.boxes.box.name",
    image: box,
    imageGradient: boxGradient,
    blurColor: "#C0BCAF",
    info: [
      {
        title: "staking.info.boxes.box.info.foodDrink.title",
        description: "staking.info.boxes.box.info.foodDrink.desc"
      },
      {
        title: "staking.info.boxes.box.info.resourceKits.title",
        description: "staking.info.boxes.box.info.resourceKits.desc"
      }
    ]
  },
  {
    name: "staking.info.boxes.box1.name",
    image: box1,
    imageGradient: box1Gradient,
    blurColor: "#83D987",
    info: [
      {
        title: "staking.info.boxes.box1.info.cloth.title",
        description: "staking.info.boxes.box1.info.cloth.desc"
      },
      {
        title: "staking.info.boxes.box1.info.bot.title",
        description: "staking.info.boxes.box1.info.bot.desc"
      },
      {
        title: "staking.info.boxes.box1.info.foodDrink.title",
        description: "staking.info.boxes.box1.info.foodDrink.desc"
      },
      {
        title: "staking.info.boxes.box1.info.resourceKits.title",
        description: "staking.info.boxes.box1.info.resourceKits.desc"
      }
    ]
  },
  {
    name: "staking.info.boxes.box2.name",
    image: box2,
    imageGradient: box2Gradient,
    blurColor: "#3D77E1",
    info: [
      {
        title: "staking.info.boxes.box2.info.rareClothes.title",
        description: "staking.info.boxes.box2.info.rareClothes.desc"
      },
      {
        title: "staking.info.boxes.box2.info.rareBot.title",
        description: "staking.info.boxes.box2.info.rareBot.desc"
      },
      {
        title: "staking.info.boxes.box2.info.botUpgradeBox.title",
        description: "staking.info.boxes.box2.info.botUpgradeBox.desc"
      }
    ]
  },
  {
    name: "staking.info.boxes.box5.name",
    image: box5,
    imageGradient: box5Gradient,
    blurColor: "#A851FA",
    info: [
      {
        title: "staking.info.boxes.box5.info.epicClothing.title",
        description: "staking.info.boxes.box5.info.epicClothing.desc"
      },
      {
        title: "staking.info.boxes.box5.info.epicBot.title",
        description: "staking.info.boxes.box5.info.epicBot.desc"
      },
      {
        title: "staking.info.boxes.box5.info.mogul.title",
        description: "staking.info.boxes.box5.info.mogul.desc"
      },
      {
        title: "staking.info.boxes.box5.info.boost.title",
        description: "staking.info.boxes.box5.info.boost.desc"
      }
    ]
  },
  {
    name: "staking.info.boxes.box6.name",
    desc: "staking.info.boxes.box6.desc",
    image: box6,
    imageGradient: box6Gradient,
    blurColor: "#F9A44C",
    info: [
      {
        title: "staking.info.boxes.box6.info.legendaryClothing.title",
        description: "staking.info.boxes.box6.info.legendaryClothing.desc"
      },
      {
        title: "staking.info.boxes.box6.info.exclusiveMasks.title",
        description: "staking.info.boxes.box6.info.exclusiveMasks.desc"
      },
      {
        title: "staking.info.boxes.box6.info.propertyOwnershipVouchers.title",
        description:
          "staking.info.boxes.box6.info.propertyOwnershipVouchers.desc"
      }
    ]
  },
  {
    name: "staking.info.boxes.box3.name",
    desc: "staking.info.boxes.box3.desc",
    image: box3,
    imageGradient: box3Gradient,
    blurColor: "#70B2CC",
  },
  {
    name: "staking.info.boxes.box4.name",
    desc: "staking.info.boxes.box4.desc",
    image: box4,
    imageGradient: box4Gradient,
    blurColor: "#E9A93A",
  },
  {
    name: "staking.info.boxes.weapon.name",
    desc: "staking.info.boxes.weapon.desc",
    image: weaponBox,
    imageGradient: weaponBoxGradient
  },
  {
    name: "staking.info.boxes.clothet.name",
    desc: "staking.info.boxes.clothet.desc",
    image: clothetBox,
    imageGradient: clothetBoxGradient
  }
];
