import React, {FC} from 'react';
import ProgressBar from "./ProgressBar/ProgressBar";
import {useUserStore} from "../../../../../services/user/store";
import {getUserProgress} from "./progress.helpers";
import "./Progress.scss";
import cn from "classnames";


interface IProgressProps {
  className?: string;
}


const Progress: FC<IProgressProps> = ({ className }) => {
  const { user } = useUserStore();

  const userProgress = getUserProgress(user);
  const currentUserLevel = user?.levelData.currentLevel?.value || 0

  return (
    <div className={cn("progress", className)}>
      <ProgressBar color="#B364D8" className="progress__bar" progress={userProgress} />
      <p className="progress__text">{currentUserLevel} <span>LVL</span></p>
    </div>
  );
};


export default Progress;
