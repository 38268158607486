import { useTranslation } from "react-i18next"
import googleIcon from "../../../../assets/images/googleIcon.png"
import telegramIcon from "../../../../assets/images/telegramIcon.png"
import "./SignupCard.scss"
import { ArrowRight } from "../../../../assets/iconComponents/ArrowRight"
import { useQueryParam } from "../../../../newHooks/useQueryParam"
import loginService from "../../services/loginService"

type SignupTypes = "google" | "telegram"

type SignupTypesDict = Record<SignupTypes, {
  name: string
  iconUrl: string
  onClick: (refCode?: string) => void
}>

type ISignupCardProps = {
  type: SignupTypes
}

const signupDict: SignupTypesDict = {
  google: {
    name: "Google",
    iconUrl: googleIcon,
    onClick: (refCode) => refCode ?
      loginService.referralGoogleLogin(refCode) :
      loginService.loginWithGoogle
  },
  telegram: {
    name: "Telegram",
    iconUrl: telegramIcon,
    onClick: () => {
      const origin = process.env.REACT_APP_TELEGRAM_CONNECTION_URL;
      window.open(origin + '?auth=tg', "Telegram", "popup,width=350,height=250");
    }
  }
}

export const SignupCard: React.FC<ISignupCardProps> = ({ type }) => {
  const { t } = useTranslation()
  const { paramValue: refCode } = useQueryParam('ref');
  return <div
    className="signup-card"
    onClick={() => signupDict[type].onClick(refCode || undefined)}
  >
    <div className="signup-card__icon">
      <img src={signupDict[type].iconUrl} alt="icon" />
    </div>
    <div className="signup-card__content">
      <h3>
        {signupDict[type].name}
      </h3>
      <div className="signup-card__register">
        <p>{t("auth.signup.register")}</p>
        <ArrowRight />
      </div>

    </div>
  </div>
}