import { useTranslation } from "react-i18next";
import { NFT_RARITY_DICT } from "../../../../../constants/nft";
import { RarityType } from "../../types";
import css from "./RarityChip.module.scss";

type IRarityChipProps = {
  rarity: RarityType
  className?: string
  size?: "regular" | "large"
}

export const RarityChip: React.FC<IRarityChipProps> = ({
  rarity,
  className,
  size = "regular"
}) => {
  const { t } = useTranslation()
  return <div
    className={`${css.rarity} ${className} ${css[size]}`}
    style={{ backgroundColor: NFT_RARITY_DICT[rarity].color }}
  >
    {t(NFT_RARITY_DICT[rarity].titleI18key)}
  </div>
}
