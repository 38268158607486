import { localStorageKeys } from "../../../constants/localStorageKeys";
import { AppRoutes } from "../../../constants/routes";
import { BaseService } from "../../../services/BaseService";
import { $api, AUTH_API_URL } from "../../../services/interceptor";
import publicNavigate from "../../../services/PublicNavigate";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { accountLinkingStore, IAccountLinkingStore } from "../store/accountLinkingStore";
import { AccountConnectResponse, ChooseAccountForLinkingResponse1, ChooseAccountForLinkingResponse2, SelectedAccountToLink } from "../types";
import loginService from "./loginService";

class AccountLinkingAPI extends BaseService<IAccountLinkingStore> {
  constructor() {
    super(accountLinkingStore);
  }

  resetState = () => {
    this.setState({
      otpRequestLoading: false,
      otpErrorMessage: "",
      otpCode: "",
      otpRequested: false,
      isEmailExists: false,
      emailErrorMessage: null,
      email: "",
      connectAccountLoading: false,
      getAccountDataLoading: false,
      chooseAccountLoading: false,
      choosedAccountResult: null,
      emailAccountCrreationLoading: false,
      isSignUpTypeModalOpen: false,
      isNewRegistrationWithTelegram: false,
      isAccountLinkingModalOpen: false,
      accountLinkingStep: "email",
      isAccountCreationModalOpen: false,
      isAccountCreationEmailExist: false,
      accountCreationType: "emailStep",
      accountCreationExistSteps: "otp",
      accountCreationSteps: "otp",
      accountToLinking: null,
      selectedAccountToLink: null,
    })
    loginService.setState({ otpError: "" })
  }

  clearEmailError = () => {
    this.setState({ emailErrorMessage: "" });
  }

  checkIfEmailAccountExist = async (email: string) => {
    this.setState({ otpRequestLoading: true })
    try {
      const { data } = await $api.get<{ exists: boolean }>(`/auth/accounts/exists?email=${email}`, { baseURL: AUTH_API_URL })
      this.setState({ isEmailExists: data.exists, otpRequestLoading: false })
      return data.exists
    } catch (error) {
      this.setState({ otpRequestLoading: false })
      return false
    }
  }

  requestOTP = async (email: string) => {
    this.setState({ otpRequestLoading: true })
    try {
      await $api.post("/auth/otp/request", { email }, { baseURL: AUTH_API_URL })
      this.setState({
        otpRequested: true,
        otpRequestLoading: false
      })
    } catch (error) {
      this.setState({ otpRequestLoading: false })
      loginService.setState({ otpError: getErrorMessage(error) })
      throw new Error(getErrorMessage(error))
    }
  }

  linkEmailAccount = async (email: string, otpCode: string, deleteEmail: boolean) => {
    this.setState({ connectAccountLoading: true })
    try {
      const request = {
        email,
        otpCode,
        includeFbToken: false
      }
      const { data } = await $api.post<AccountConnectResponse>("/auth/otp/connect", request, { baseURL: AUTH_API_URL })
      if ("availableAccount" in data && data.availableAccount?.email) {
        const { data } = await $api.post<
          ChooseAccountForLinkingResponse1 | ChooseAccountForLinkingResponse2
        >(
          "/auth/accounts/choose-main",
          { email, reset: deleteEmail },
          { baseURL: AUTH_API_URL }
        )

        if ("access" in data) {
          localStorage.setItem("accessToken", data.access);
        } else {
          localStorage.setItem("accessToken", data.tokens.access);
          this.setState({ choosedAccountResult: data })
        }

        this.setState({
          otpErrorMessage: "",
          connectAccountLoading: false,
          accountLinkingStep: "congrats",
        })
      } else {
        this.setState({ otpErrorMessage: "Request error", connectAccountLoading: false })
      }
    } catch (error) {
      this.setState({
        connectAccountLoading: false,
        otpErrorMessage: getErrorMessage(error)
      })
      loginService.setState({ otpError: getErrorMessage(error) })
    }
  }

  setIsSignUpTypeModalOpen = (value: boolean) => {
    this.setState({ isSignUpTypeModalOpen: value })
    if (!value) {
      loginService.setState({ otpError: "", otpCode: "" })
    }
  }

  setIsNewRegistrationWithTelegram = (value: boolean) => {
    this.setState({ isNewRegistrationWithTelegram: value })
  }

  isHaveEmailAccount = (value: boolean) => {
    localStorage.setItem(localStorageKeys.isNewTgAccount, "false")
    this.setState({
      isNewRegistrationWithTelegram: false,
      isAccountLinkingModalOpen: value,
    })
  }

  setIsAccountLinkingModalOpen = (value: boolean) => {
    this.setState({ isAccountLinkingModalOpen: value })
    if (!value) {
      loginService.setState({ otpError: "", otpCode: "" })
    }
  }

  setIsAccountCreationModalOpen = (value: boolean) => {
    if (value) {
      this.setState({ isAccountCreationModalOpen: value })
    } else {
      this.resetState()
    }

  }

  setIsAccountCreationEmailExist = (value: boolean) => {
    this.setState({ isAccountCreationEmailExist: value })
  }

  /**
   * Email linking on first telegram registration
   */
  accountLinkingCheckAndGetOtp = async (email: string) => {
    try {
      const isExist = await this.checkIfEmailAccountExist(email)
      if (isExist) {
        await this.requestOTP(email)
      } else {
        this.setState({ emailErrorMessage: "The account with the specified email does not exist" });
      }
    } catch (error) {
      this.setState({ emailErrorMessage: getErrorMessage(error) });
    }
  }

  accountLinkingStepBackFromOtp = () => {
    this.setState({
      accountLinkingStep: "email",
      otpRequested: false,
    })
    loginService.setState({ otpError: "", otpCode: "" })
  }

  accountLinkingStepNext = () => {
    const currentStep = this.getState().accountLinkingStep
    this.setState({
      accountLinkingStep: currentStep === "email" ? "otp" : "congrats"
    })
  }

  setEmail = (value: string) => {
    this.setState({ email: value })
  }

  /**
   * Set state for email exist in database or not
   */
  sendEmailForLinkingFromProfileEditPage = async (email: string) => {

    try {
      const isEmailExistInDB = await this.checkIfEmailAccountExist(email)

      await this.requestOTP(email)

      this.setState({
        accountCreationType: isEmailExistInDB ? "existAccount" : "newAccount"
      })
    } catch (error) {
      this.setState({ emailErrorMessage: getErrorMessage(error) })
    }
  }

  /**
   * New email creation and linking
   */
  backFromNewEmailAccountCreationOtp = () => {
    this.setState({
      accountCreationType: "emailStep",
      accountCreationSteps: "otp",
      otpCode: "",
      otpErrorMessage: "",
      email: ""
    })
    loginService.setState({ otpError: "", otpCode: "" })
  }

  createNewAccountToStepOTP = () => {
    this.setState({ accountCreationSteps: "otp" })
  }

  createNewAccountToStepCongrats = async (email: string, otpCode: string) => {
    this.setState({ emailAccountCrreationLoading: true })
    try {
      const request = {
        email,
        otpCode,
        includeFbToken: false
      }
      const { data } = await $api.post<AccountConnectResponse>("/auth/otp/connect", request, { baseURL: AUTH_API_URL })


      if ("tokens" in data) {
        localStorage.setItem("accessToken", data.tokens.access);
      }

      this.setState({
        otpErrorMessage: "",
        emailAccountCrreationLoading: false,
        accountCreationSteps: "congrats"
      })
    } catch (error) {
      this.setState({
        emailAccountCrreationLoading: false,
        otpErrorMessage: getErrorMessage(error)
      })
      loginService.setState({ otpError: getErrorMessage(error) })
    }
  }
  /**
   * Existing email account linking
   */
  linkExistingAccountToStepOTP = () => {
    this.setState({ accountCreationExistSteps: "otp" })
  }

  backFromExistAccountOtp = () => {
    this.setState({
      accountCreationType: "emailStep",
      accountCreationExistSteps: "otp",
      otpCode: "",
      otpErrorMessage: "",
      otpRequested: false,
      otpRequestLoading: false,
      email: "",
      emailErrorMessage: ""
    })
    loginService.setState({ otpError: "", otpCode: "" })
  }

  linkExistingAccountToStepChoose = async (email: string, otpCode: string) => {
    this.setState({
      getAccountDataLoading: true
    })
    try {
      const request = {
        email,
        otpCode,
        includeFbToken: false
      }
      const { data } = await $api.post<AccountConnectResponse>("/auth/otp/connect", request, { baseURL: AUTH_API_URL })
      this.setState({
        accountToLinking: data,
        accountCreationExistSteps: "choose",
        getAccountDataLoading: false
      })
    } catch (error) {
      this.setState({ getAccountDataLoading: false })
      loginService.setState({ otpError: getErrorMessage(error) })
    }
  }

  linkExistingAccountToStepConfirmation = () => {
    this.setState({ accountCreationExistSteps: "confirmation" })
  }

  linkExistingAccountToStepCongrats = async (email: string) => {
    this.setState({ chooseAccountLoading: true })
    try {
      const deleteEmail = this.getState().selectedAccountToLink === "telegram"
      const { data } = await $api.post<
        ChooseAccountForLinkingResponse1 | ChooseAccountForLinkingResponse2
      >(
        "/auth/accounts/choose-main",
        { email, reset: deleteEmail },
        { baseURL: AUTH_API_URL }
      )
      if ("access" in data) {
        localStorage.setItem("accessToken", data.access);
      } else {
        localStorage.setItem("accessToken", data.tokens.access);
        this.setState({ choosedAccountResult: data })
      }
      this.setState({
        otpErrorMessage: "",
        accountCreationExistSteps: "congrats",
        chooseAccountLoading: false,
        selectedAccountToLink: null
      })
    } catch (error) {
      this.setState({ chooseAccountLoading: false })
    }
  }

  setSelectedAccountToLink = (value: SelectedAccountToLink) => {
    this.setState({ selectedAccountToLink: value })
  }

  closeCongrats = () => {
    publicNavigate.navigate(AppRoutes.myProfile, { replace: true })
    this.resetState()
  }
}

const accountLinkingService = new AccountLinkingAPI();
export default accountLinkingService;
