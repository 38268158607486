import React, {FC, ReactElement} from 'react';
import './LoginHeader.scss';
import {Title} from "../../../../newComponents/Title/Title";
import {Text} from "../../../../newComponents/Text/Text";

interface ILoginHeaderProps {
  title: string;
  text?: ReactElement|string;
}

export const LoginHeader: FC<ILoginHeaderProps> = ({ title, text  }) => {
  return (
    <header className="login-header">
      <div className="login-header__title-wrapper">
        <Title
          tagName="h2"
          variant="xl"
          className="login-header__title"
        >
          {title}
        </Title>

        {text && <Text className="login-header__text">{text}</Text>}
      </div>

      {/* //TODO: TEMP HIDE */}
      {/*<button className="login-header__button">*/}
      {/*  <IconInfo/>*/}
      {/*  <span>{t("login.button-info")}</span>*/}
      {/*</button>*/}
    </header>
  );
};
