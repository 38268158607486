import { IIconProps } from "./types"

export const ReferralIcon = ({ iconColor = "#FFFFFF", ...props }: IIconProps) => {
  return <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_5131_32443"
      style={{maskType: "alpha"}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="22"
    >
      <rect
        x="0.503906"
        y="0.675781"
        width="20.8695"
        height="20.8695"
      />
    </mask>
    <g mask="url(#mask0_5131_32443)">
      <path
        d="M16.1553 19.5893C15.4912 19.5893 14.9265 19.3568 14.4611 18.8919C13.9956 18.4268 13.7629 17.8622 13.7629 17.198C13.7629 17.0894 13.7715 16.9771 13.7888 16.8608C13.8172 16.6704 13.7466 16.4688 13.5805 16.3716L8.2594 13.2559C7.77358 12.9714 7.16644 13.0828 6.65094 13.3091C6.35776 13.4379 6.04725 13.5024 5.71942 13.5024C5.05522 13.5024 4.49059 13.27 4.02551 12.8052C3.56059 12.3404 3.32812 11.7761 3.32812 11.1121C3.32812 10.4481 3.56059 9.88338 4.02551 9.41801C4.49059 8.95251 5.05522 8.71976 5.71942 8.71976C6.04725 8.71976 6.35776 8.78418 6.65094 8.91302C7.16644 9.1393 7.77358 9.25068 8.2594 8.96621L13.5805 5.85053C13.7466 5.75326 13.8172 5.55168 13.7888 5.36128C13.7715 5.24505 13.7629 5.13266 13.7629 5.02411C13.7629 4.35991 13.9953 3.79527 14.4601 3.3302C14.9248 2.86528 15.4892 2.63281 16.1531 2.63281C16.8172 2.63281 17.3819 2.8652 17.8472 3.32999C18.3127 3.79477 18.5455 4.35911 18.5455 5.02303C18.5455 5.68708 18.313 6.25179 17.8481 6.71715C17.383 7.18266 16.8184 7.41541 16.1542 7.41541C15.8264 7.41541 15.5159 7.35099 15.2227 7.22215C14.7072 6.99587 14.1 6.88449 13.6142 7.16895L8.29256 10.285C8.12676 10.382 8.05635 10.5832 8.08485 10.7732C8.1021 10.8892 8.11072 11.0012 8.11072 11.1093C8.11072 11.2174 8.1021 11.33 8.08485 11.4471C8.05648 11.6382 8.12737 11.8404 8.29402 11.938L13.6142 15.0532C14.1 15.3376 14.7072 15.2262 15.2227 15C15.5159 14.8711 15.8264 14.8067 16.1542 14.8067C16.8184 14.8067 17.383 15.0391 17.8481 15.5039C18.313 15.9687 18.5455 16.533 18.5455 17.1969C18.5455 17.861 18.3131 18.4257 17.8483 18.891C17.3835 19.3565 16.8192 19.5893 16.1553 19.5893Z"
      />
    </g>
  </svg>
}
