import React from 'react';

export const IconTelegram = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_6039_45628" style={{ maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="currentColor"/>
      </mask>
      <g mask="url(#mask0_6039_45628)">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M19.3938 5.00813C19.6174 4.91403 19.8621 4.88157 20.1025 4.91414C20.3428 4.94671 20.5701 5.04312 20.7606 5.19332C20.9511 5.34353 21.0978 5.54205 21.1855 5.76821C21.2733 5.99437 21.2988 6.23991 21.2594 6.47928L19.2074 18.9261C19.0083 20.1267 17.691 20.8152 16.5899 20.2172C15.6689 19.7168 14.3009 18.946 13.0704 18.1417C12.4551 17.739 10.5705 16.4498 10.8021 15.5323C11.0012 14.7479 14.1679 11.8002 15.9774 10.0477C16.6876 9.35913 16.3637 8.96194 15.525 9.59528C13.4413 11.1678 10.0982 13.559 8.99262 14.2322C8.01729 14.8257 7.50881 14.927 6.90081 14.8257C5.79158 14.6411 4.76286 14.3552 3.92324 14.0069C2.78867 13.5364 2.84386 11.9766 3.92234 11.5224L19.3938 5.00813Z"
          fill="currentColor"/>
      </g>
    </svg>
  );
};
