import { useTonConnectUI } from "@tonconnect/ui-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "../../../../../components/Buttons/Button"
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader"
import { charactersService } from "../../service"
import { charactersStore } from "../../store"
import { ClaimStaus, NftPropsRaw } from "../../types"
import { nftClaim } from "../../utils"
import { RarityChip } from "../ClaimedEarly/RarityChip"
import { NorthShine } from "../NftCongratsModal/NorthShine"
import css from "./NftInfoModal.module.scss"

type INftInfoModalProps = {
  open: boolean
  onClose: () => void
  nftInfo: Partial<NftPropsRaw>
}

export const NftInfoModal: React.FC<INftInfoModalProps> = ({
  open,
  onClose,
  nftInfo
}) => {
  const { t } = useTranslation()
  const {
    isAbleToNewCalim,
    selectedNftToShow,
    nftCollectionCurrent
  } = charactersStore()
  const [tonConnectUI] = useTonConnectUI();

  const isCalimDisabled = !isAbleToNewCalim &&
    !(
      nftCollectionCurrent?.price &&
      nftCollectionCurrent?.address &&
      selectedNftToShow?.signature
    )

  const handleClick = async () => {
    if (nftCollectionCurrent?.price && nftCollectionCurrent?.address && selectedNftToShow?.signature) {
      const response = await nftClaim(
        `${nftCollectionCurrent?.price}`,
        nftCollectionCurrent?.address,
        selectedNftToShow?.signature,
        tonConnectUI
      )
      if (response && selectedNftToShow.id) {
        await charactersService.setClaimStatusToProcessing(selectedNftToShow.id)
      }
    }
  }


  return <ModalWithHeader
    open={open}
    title={`#${nftInfo.index || "??????"}`}
    onClose={onClose}
    bodyClassName={css.wrapper}
  >
    <>
      <h3 className={css.title}>{t("claim.characters.modal.info.title")}</h3>
      <div className={css.nft}>
        {nftInfo?.claimStatus === "PROCESSING" ? <NorthShine /> : null}
        <img
          src={nftInfo.url} alt="nft"
          className={nftInfo?.claimStatus === "STARTED" ? "" : css.bordered}
        />
        {nftInfo?.rarity ? <RarityChip rarity={nftInfo.rarity} size="large" /> : <div className={css.empty} />}
      </div>
      {selectedNftToShow?.claimStatus === ClaimStaus.started ? <Button
        onClick={handleClick}
        disabled={isCalimDisabled}
        size="large"
        variant="secondary"
      >{t("claim.characters.claim.button.claim")}</Button> : null}
    </>
  </ModalWithHeader>
}