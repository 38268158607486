import React from "react";

export const DisclaimerIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      {...props}
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6004 2.08331C7.67504 2.08331 5.21234 2.08331 3.68222 3.6088C2.1521 5.13428 2.1521 7.58952 2.1521 12.5C2.1521 17.4104 2.1521 19.8657 3.68222 21.3912C5.21234 22.9166 7.67504 22.9166 12.6004 22.9166C17.5258 22.9166 19.9885 22.9166 21.5186 21.3912C23.0488 19.8657 23.0488 17.4104 23.0488 12.5C23.0488 7.58952 23.0488 5.13428 21.5186 3.6088C19.9885 2.08331 17.5258 2.08331 12.6004 2.08331ZM12.6004 6.5104C13.0332 6.5104 13.3841 6.86017 13.3841 7.29165V13.5416C13.3841 13.9731 13.0332 14.3229 12.6004 14.3229C12.1676 14.3229 11.8168 13.9731 11.8168 13.5416V7.29165C11.8168 6.86017 12.1676 6.5104 12.6004 6.5104ZM12.6004 17.7083C13.1775 17.7083 13.6453 17.2419 13.6453 16.6666C13.6453 16.0913 13.1775 15.625 12.6004 15.625C12.0234 15.625 11.5556 16.0913 11.5556 16.6666C11.5556 17.2419 12.0234 17.7083 12.6004 17.7083Z"
        fill="#83D987"
      />
    </svg>
  );
};
