import { useEffect, useState } from "react";
import css from "../styles.module.scss";
import Input from "../../../components/Inputs";
import { Button } from "../../../components/Buttons/Button";
import { useTranslation } from "react-i18next";
import { ModalWithHeader } from "../../../components/Modals/ModalWithHeader";

interface PropsTypes {
  open: boolean;
  onClose: () => void;
  name?: string;
  loading?: boolean;
  handleSave: ({ customName }: { customName: string }) => Promise<boolean>;
}

export const EditPetNameModal = ({
  open,
  onClose,
  name,
  loading,
  handleSave,
}: PropsTypes) => {
  const [customName, setCustomName] = useState<string | undefined>(name);
  const { t } = useTranslation();

  const handleChange = async () => {
    const isChanged = await handleSave({ customName: customName! });
    isChanged && onClose();
  };

  useEffect(() => {
    setCustomName(name);
  }, [name]);

  return (
    <ModalWithHeader open={open} onClose={onClose} title={t('pets.edit-name.title')}>
      <div className={css.editPetNameModalWrapper}>
        <p className={css.inputLabel}>{t('pets.edit-name.name')}</p>
        <Input
          value={customName}
          variant={"secondary"}
          id={"edit-username"}
          className={css.inputConponent}
          placeholder={t('pets.edit-name.placeholder')}
          onChange={(e) => setCustomName(e.target.value)}
        />
        <div className={css.btnsBlock}>
          <Button
            disabled={loading}
            onClick={onClose}
            size="large"
            isUppercased

          >
            {t('buttons.cancel')}
          </Button>
          <Button
            variant={"secondary"}
            onClick={handleChange}
            disabled={loading || !customName}
            isLoading={loading}
            size="large"
            isUppercased
          >
            {t('buttons.save')}
          </Button>
        </div>
      </div>
    </ModalWithHeader>
  );
};
