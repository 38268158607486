import React, { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TaskItemProps } from "../../types";
import "../../styles/UserTask.scss";
import { Title } from "../../../../newComponents/Title/Title";
import { Text } from "../../../../newComponents/Text/Text";
import { GoToIcon } from "../IconGoTo";
import { DoneIcon } from "../IconDone";
import cn from "classnames";
import { TaskModal } from "../TaskModal";
import { Tooltip } from "@mui/material";
import { useIsMobileResolution } from "../../../../hooks/useIsMobileResolution";

export const TaskItem: FC<TaskItemProps> = ({
  task,
  index,
  disabled = false,
  disabledTooltip = "",
  hideDetailsProgress = false,
  withoutTitle = false,
  onClick
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobileResolution(1279);

  const { isTaskDone, title, description, color } = task;

  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false);

  return (
    <>
      <li
        className={cn("user-task", isTaskDone && "user-task--done")}
        onClick={() => setIsTaskModalOpen(true)}
      >
        <div className="user-task__header-new">
          {isMobile && isTaskDone &&
            <strong className="user-task__status">
              Done
              <DoneIcon />
            </strong>
          }

          <div className="user-task__title-wrapper">
            <Title
              className="user-task__title"
              tagName="h2"
              variant="l-fixed"
            >
              {t(title)}
            </Title>

            {!isMobile && isTaskDone &&
              <strong className="user-task__status user-task__status--desktop">
                Done
                <DoneIcon />
              </strong>
            }

            <Tooltip title={t(disabledTooltip)}>
              <button
                className="user-task__goto-button"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                disabled={disabled}
              >
                <GoToIcon />
              </button>
            </Tooltip>
          </div>
        </div>

        <Text
          className="user-task__description"
          variant="m"
        >
          <Trans i18nKey={description}
            components={{
              a2: <a href="https://discord.gg/pixelverse" target="_blank" rel="noopener noreferrer"> </a>,
              a3: <a href="x.com/pixelverse_xyz" target="_blank" rel="noopener noreferrer"> </a>,
            }}
          />
        </Text>
      </li>

      <TaskModal
        color={color!}
        task={task}
        num={index + 1}
        open={isTaskModalOpen}
        handleClose={() => setIsTaskModalOpen(false)}
        isProgressHidden={hideDetailsProgress}
        withoutTitle={withoutTitle}
      />
    </>
  );
};
