import { create, useStore } from "zustand";


export interface IReferralsStats {
  referralsCount: number;
  totalReferralPoints: number;
}


export interface IReferralListItem {
  id: string;
  email: string;
  username: string;
  pointsEarnedToReferrer: number;
  createdAt: string;
  iconUrl: string;
}

export type IReferralList = Array<IReferralListItem>;

export interface IReferralsStore {
  isReferralsStatsLoading: boolean;
  isReferralsStatsSuccess: boolean;
  referralsStats: IReferralsStats | null;
  isReferralsListLoading: boolean;
  isReferralsListSuccess: boolean;
  referralsList: IReferralList | [];
}


export const referralsStore = create<IReferralsStore>(() => ({
  isReferralsStatsLoading: false,
  isReferralsStatsSuccess: false,
  referralsStats: null,
  isReferralsListLoading: false,
  isReferralsListSuccess: false,
  referralsList: [],
}));


export const useReferralsStore = () => useStore(referralsStore);
