import { useTranslation } from "react-i18next";
import styles from "../StakingPage.module.scss";
import { stakingStore } from "../store";
import { transformNumber } from "../../../utils/transformNumber";
import pixFiIcon from "../../../assets/images/pixFiIcon.png";

export const PrizePool = () => {
  const { t } = useTranslation();
  const { stakingInfo } = stakingStore();
  return (
    <div className={styles.ticketsPerDayWrapper}>
      <div>
        <h3>{t("staking.staked.prize")}</h3>
      </div>
      <div className={styles.button}>
        <div className={styles.coins}>
          <img src={pixFiIcon} alt="pix-fi-icon" />
          <span>{transformNumber(+stakingInfo.prizeFund)}</span>
          <span className={styles.symbol}>pixfi</span>
        </div>
      </div>
    </div>
  );
};
