import { AxiosError } from "axios";

export const getErrorMessage = (error: unknown) => {
  const typedError = error as AxiosError<{
    code: string;
    message: string;
  }>;

  if (typedError.response?.data.message) {
    return typedError.response?.data.message;
  }

  return "";
};
