import React from "react";

export const SandClock = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33171 2.74928C4.83355 1.66663 6.55565 1.66663 9.99984 1.66663C13.444 1.66663 15.1661 1.66663 15.668 2.74928C15.7111 2.84234 15.7475 2.93901 15.7768 3.03837C16.1177 4.19437 14.9 5.53422 12.4646 8.21391L10.8332 9.99996L12.4646 11.786C14.9 14.4657 16.1177 15.8055 15.7768 16.9615C15.7475 17.0609 15.7111 17.1576 15.668 17.2506C15.1661 18.3333 13.444 18.3333 9.99984 18.3333C6.55565 18.3333 4.83355 18.3333 4.33171 17.2506C4.28858 17.1576 4.25218 17.0609 4.22288 16.9615C3.88198 15.8055 5.09968 14.4657 7.53509 11.786L9.1665 9.99996L7.53509 8.21391C5.09968 5.53422 3.88198 4.19437 4.22288 3.03837C4.25218 2.93901 4.28858 2.84234 4.33171 2.74928ZM8.33317 14.7916C7.98799 14.7916 7.70817 15.0714 7.70817 15.4166C7.70817 15.7618 7.98799 16.0416 8.33317 16.0416H11.6665C12.0117 16.0416 12.2915 15.7618 12.2915 15.4166C12.2915 15.0714 12.0117 14.7916 11.6665 14.7916H8.33317ZM7.70817 4.58329C7.70817 4.23811 7.98799 3.95829 8.33317 3.95829H11.6665C12.0117 3.95829 12.2915 4.23811 12.2915 4.58329C12.2915 4.92847 12.0117 5.20829 11.6665 5.20829H8.33317C7.98799 5.20829 7.70817 4.92847 7.70817 4.58329Z"
        fill="white"
      />
    </svg>
  );
};
