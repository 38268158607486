import { formatUnits, parseUnits } from "ethers";
import BN from "bignumber.js"

type EthersBigNumber = {
  _isBigNumber: boolean
  _hex: string
}

export function toBn(number: BN.Value | EthersBigNumber, base?: number) {
  if (typeof number === "object" && "_isBigNumber" in number && "_hex" in number) {
    return new BN(number._hex, 16)
  }
  return new BN(number, base)
}

BN.set({
  EXPONENTIAL_AT: 100,
})

export const BigNumber = BN
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BigNumber = BN
export type BigNumberValue = BN.Value


const SECOND = 1;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

export const DAY = 24 * HOUR;

export const beautifyTokenBalance = (
  balance: string,
  decimals: number,
  fraction = 5
) => {
  const exp = 10 ** fraction;

  return (+balance.slice(0, -decimals + fraction) / exp).toLocaleString(
    undefined,
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: fraction,
    }
  );
};

export const fromHRToBN = (n: number, decimals: number) => {
  n = Math.floor(n);
  const MAX_SMALL_DECIMAL = 6;

  if (decimals <= MAX_SMALL_DECIMAL) {
    return parseUnits(n.toString(), decimals);
  }

  const multiplierSmall = parseUnits("1", MAX_SMALL_DECIMAL).toString();
  const multiplierMain = parseUnits(
    "1",
    decimals - MAX_SMALL_DECIMAL
  ).toString();

  return toBn(n.toString())
    .multipliedBy(multiplierSmall)
    .multipliedBy(multiplierMain);
};

export const toHRNumber = (bn: BigNumber, decimal: number) => {
  if (!decimal) {
    return 0;
  }
  return formatUnits(bn.toString(), decimal);
};
export const toHRNumberFloat = (bn: BigNumber, decimal = 0) => {
  return toHRNumber(bn, decimal);
};

export const ceilNumber = (n: number) => Math.ceil(n * 100) / 100;

export const floorNumber = (n: number) => Math.floor(n * 100) / 100;
