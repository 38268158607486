import { Tooltip } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { ITask, TaskType } from "../types";
import ProgressBar from "../../../components/TopBar/components/MiniProfile/Progress/ProgressBar/ProgressBar";
import { handleExternalLinkOpen } from "../../../utils/handleExternalLinkOpen";
import tasksService from "../service/tasksService";
import { tasksStore } from "../store/tasksStore";
import { useShallow } from "zustand/react/shallow";
import { Loader } from "../../../components/Loader";
import css from "../../../components/Modals/Modal/styles.module.scss";

import "../styles/TaskModal.scss";
import React from "react";
import { ModalWithHeader } from "../../../components/Modals/ModalWithHeader";
import { Title } from "../../../newComponents/Title/Title";
import { Text } from "../../../newComponents/Text/Text";


export const TaskModal = ({
  task,
  num,
  open,
  isProgressHidden = false,
  withoutTitle = false,
  disabled = false,
  disabledTooltip = "",
  actionTitle = "",
  handleClose,
  onClick,
  color
}: {
  color: string;
  task: ITask;
  num: number;
  open: boolean;
  isProgressHidden?: boolean;
  withoutTitle?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  actionHidden?: boolean;
  actionTitle?: string;
  handleClose: () => void;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();
  const progress = (task.currentProgress / task.maxProgress) * 100;
  const isTaskComplete = Math.floor(progress) >= 100;
  const isSnapshotUploading = tasksStore(
    useShallow(({ isSnapshotUploading }) => isSnapshotUploading)
  );

  const isUploadDisabled =
    (task.currentProgress === 0 ||
      task.currentProgress >= 100 ||
      (task.userSnapshotResult?.snapshotsUrls?.length || 0) > 0) &&
    task.userSnapshotResult?.status !== "REJECTED";

  return (
    <ModalWithHeader
      className="task-modal"
      open={open}
      onClose={handleClose}
      title={`${t("tasks.modal.title")} ${num}`}
      classes={{
        root: css.modalRoot,
        backdrop: css.modalBackdrop,
      }}
      sx={{ outline: "none !important" }}
    >
      <div className="task-modal__content">

        <div className="task-modal__body-wrapper">
          <div className="task-modal__body">
            <div className="task-modal__body-header">
              <Title
                className="user-task__title"
                tagName="h2"
                variant="l-fixed"
              >
                {t(task.title)}
              </Title>

              {!isProgressHidden && (
                <div className="user-task__progress">
                  <ProgressBar color={color} className="user-task__progress-bar"
                    progress={isTaskComplete ? 100 : Math.floor(progress)} />
                  <p className="user-task__progress-text">
                    {isTaskComplete
                      ? <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_5815_42336" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0"
                          width="26"
                          height="25">
                          <rect x="0.541016" width="25" height="25" fill={color} />
                        </mask>
                        <g mask="url(#mask0_5815_42336)">
                          <path
                            d="M11.5827 17.2916L18.9265 9.94784L17.4681 8.4895L11.5827 14.3749L8.61397 11.4062L7.15564 12.8645L11.5827 17.2916ZM13.0411 22.9166C11.6001 22.9166 10.2459 22.6431 8.97856 22.0963C7.7112 21.5494 6.60876 20.8072 5.67126 19.8697C4.73376 18.9322 3.99158 17.8298 3.4447 16.5624C2.89783 15.2951 2.62439 13.9409 2.62439 12.4999C2.62439 11.0589 2.89783 9.70478 3.4447 8.43742C3.99158 7.17006 4.73376 6.06763 5.67126 5.13013C6.60876 4.19263 7.7112 3.45044 8.97856 2.90356C10.2459 2.35669 11.6001 2.08325 13.0411 2.08325C14.482 2.08325 15.8362 2.35669 17.1036 2.90356C18.3709 3.45044 19.4734 4.19263 20.4109 5.13013C21.3484 6.06763 22.0905 7.17006 22.6374 8.43742C23.1843 9.70478 23.4577 11.0589 23.4577 12.4999C23.4577 13.9409 23.1843 15.2951 22.6374 16.5624C22.0905 17.8298 21.3484 18.9322 20.4109 19.8697C19.4734 20.8072 18.3709 21.5494 17.1036 22.0963C15.8362 22.6431 14.482 22.9166 13.0411 22.9166Z"
                            fill={color} />
                        </g>
                      </svg>
                      : <>{Math.floor(progress)} <span>%</span></>
                    }
                  </p>
                </div>
              )}
            </div>

            <Text
              className="user-task__description"
              variant="m"
            >
              <Trans i18nKey={task.description}
                components={{
                  a0: <a href="http://i.bybit.com/12taboIc" target="_blank" rel="noopener noreferrer"> </a>,
                  a1: <a href="https://x.com/Bybit_Web3" target="_blank" rel="noopener noreferrer"> </a>,
                  a2: <a href="https://discord.gg/pixelverse" target="_blank" rel="noopener noreferrer"> </a>,
                  a3: <a href="x.com/pixelverse_xyz" target="_blank" rel="noopener noreferrer"> </a>,
                  br: <br />
                }}
              />
            </Text>
          </div>

          <div className="task-modal__footer">
            <div className="task-modal__goto-btn-wrapper">
              <Tooltip title={t(disabledTooltip)}>
                <button
                  className="task-modal__goto-btn"
                  onClick={onClick || (() => handleExternalLinkOpen(task.payload?.link || ""))}
                  disabled={disabled}
                >
                  {t(actionTitle) || t("tasks.card.go")}

                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.1 12.3C1.7134 12.6866 1.0866 12.6866 0.7 12.3C0.313401 11.9134 0.313401 11.2866 0.7 10.9L7.89289 3.70711C8.52286 3.07714 8.07669 2 7.18579 2H2C1.44772 2 1 1.55228 1 1C1 0.447715 1.44772 0 2 0H12C12.5523 0 13 0.447715 13 1V11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11V5.81421C11 4.92331 9.92286 4.47714 9.29289 5.10711L2.1 12.3Z"
                      fill="currentColor" />
                  </svg>
                </button>
              </Tooltip>
            </div>

            {task.type === TaskType.snapshot &&
              !isUploadDisabled &&
              !isSnapshotUploading && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData(e.target as HTMLFormElement);
                    tasksService.uploadSnapshot(formData, task.id);
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    id="upload-snapshot"
                    type="file"
                    accept="image/*"
                    name="file"
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files && files.length > 0) {
                        e.target.form?.requestSubmit();
                      }
                    }}
                  />
                  <label htmlFor="upload-snapshot">
                    <button
                      type="button"
                      style={{ color: task.color }}
                      onClick={() =>
                        document.getElementById("upload-snapshot")?.click()
                      }
                    >
                      {t("tasks.modal.upload")}
                    </button>
                  </label>
                </form>
              )}
            {isSnapshotUploading && <Loader />}
            {task.userSnapshotResult?.status === "REVIEW" && (
              <Tooltip
                title={
                  <span>{t("tasks.modal.pending.description")}</span>
                }
              >
                <p>{t("tasks.modal.pending.title")}</p>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </ModalWithHeader>
  );
};
