export const getFontClass = (language: string) => {
  switch (language) {
    case 'en':
    case 'es':
    case 'pt':
    case 'tr':
    case 'tl':
    case 'id':
    case 'ru':
      return `font-rubik`
    case 'vi':
      return `font-unifontex`;
    default:
      return `font-rubik`;
  }
};
