import { create, useStore } from "zustand";
import { EventPoints } from "./types";

export interface IActionPointsStore {
  actionPoints: EventPoints | null;
}

export const actionPointsStore = create<IActionPointsStore>(() => ({
  actionPoints: null,
}));

export const useActionPointsStore = () => useStore(actionPointsStore);
