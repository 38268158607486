import axios, { AxiosError } from 'axios';

export const parseAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<{ message: string | string[] }>;
    let errorMessages: string[] = [];
    if (Array.isArray(axiosError.response?.data?.message)) {
      errorMessages =
        axiosError.response?.data?.message.map(
          (msg) => msg.split('.')[1] || msg
        ) || [];
    } else {
      errorMessages = [
        axiosError.response?.data?.message.split('.')[1] ||
          'An unknown error occurred.',
      ];
    }
    return errorMessages;
  } else {
    return ['An unknown error occurred.'];
  }
};
