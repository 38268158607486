// export const locales = {
//   en: "English",
//   ru: "Русский",
//   uk: "Українська",
//   es: "Español",
//   pt: "Português",
//   tr: "Türkçe",
//   // vi: "Tiếng Việt",
//   tl: "Filipino",
//   id: "Bahasa Indonesia",
//   ko: "한국어",
//   zh: "中文",
//   vi: "Tiếng Việt",
//   ja: "日本語",
// };


export type Locale = {
  language: string;
  country: string;
};

type Locales = {
  [key: string]: Locale;
};

export const locales: Locales = {
  en: { language: "English", country: "us" },
  ru: { language: "Русский", country: "ru" },
  uk: { language: "Українська", country: "ua" },
  es: { language: "Español", country: "es" },
  pt: { language: "Português", country: "pt" },
  tr: { language: "Türkçe", country: "tr" },
  tl: { language: "Filipino", country: "ph" },
  id: { language: "Bahasa Indonesia", country: "id" },
  ko: { language: "한국어", country: "kr" },
  zh: { language: "中文", country: "cn" },
  vi: { language: "Tiếng Việt", country: "vn" },
  ja: { language: "日本語", country: "jp" },
};

export const getCountryCode = (key: string) => locales[key].country || key;
