export const calculateDropdownElementIndex = (avatarIndex: number, windowWidth: number, arrayLength: number = 0) => {
  if (windowWidth < 768 && avatarIndex === arrayLength - 1) {
    return avatarIndex
  }
  if (windowWidth < 520) {
    //2
    const index = avatarIndex % 2 === 0 ? avatarIndex + 1 : avatarIndex
    return arrayLength ? arrayLength < index ? arrayLength - 1 : index : index
  } else if (windowWidth < 768) {
    //3
    if (avatarIndex === 0) {
      return arrayLength ? arrayLength < 3 ? arrayLength : 2 : 2
    }
    const index = avatarIndex % 3 === 0 ? avatarIndex + 2 : avatarIndex % 3 === 1 ? avatarIndex + 1 : avatarIndex
    return arrayLength ? arrayLength <= index ? arrayLength - 1 : index : index
  } else {
    return 99
  }
}