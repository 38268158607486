import { useTranslation } from "react-i18next";
import PixelatedBordersWrapper from "../../../../../components/PixelatedBordersWrapper";
import { Button } from "../../../../../components/Buttons/Button";
import { IPet } from "../../../types";
import { petsService } from "../../../service/service";
import css from "../../../styles.module.scss";
import { useTimer } from "../../../../../hooks/useTimer";
import { oneDayInMs } from "../../../../../constants/time";
import { getNextEvolutionAvailability } from "../../../helpers/getNextEvolutionAvailability";
import { useUserStore } from "../../../../../services/user/store";
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader";

interface PropsTypes {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  selectedPet: IPet;
  currentPet: IPet;
}

export const EvolutionModal = ({
  open,
  onClose,
  onCancel,
  selectedPet,
  currentPet,
}: PropsTypes) => {
  const { t } = useTranslation();

  const { user } = useUserStore();

  const handleEvolve = () => {
    if (selectedPet.userPet?.id) {
      petsService.evolvePet(selectedPet.userPet.id, onClose);
    }
  };

  const lastLeveledUp = currentPet.userPet?.levelUppedAt || "0";
  const petLvl = selectedPet.userPet?.level;
  const isMaxLvl = petLvl === 20;
  const evolutionPrice = selectedPet.userPet?.upgradePrice;
  const isEvolutionDisabled =
    (!getNextEvolutionAvailability(selectedPet) &&
      !selectedPet.userPet?.nextLevelPet) ||
    (user?.spendablePoints?.amount || 0) < (evolutionPrice || 0);

  const [{ hour, minute, seconds }, isFinished] = useTimer(
    new Date(new Date(lastLeveledUp).getTime() + oneDayInMs)
  );

  return (
    <ModalWithHeader open={open} onClose={onClose} title={t("pets.upgrade")}>
      <div className={css.confirmSellPetModalWrapper}>
        <div className={css.confirmSellPetInfo}>
          <div className={css.left}>
            <PixelatedBordersWrapper
              color={"#232330"}
              width={7}
              mobileWidth={7}
              className="[&&&]:h-fit"
            >
              <div className={css.confirmSellImgWrapper}>
                <img
                  src={selectedPet.imageUrl}
                  alt="pet-img"
                  className={css.confirmSellImg}
                />
              </div>
              <div className={css.confirmSellPetName}>
                <span>{selectedPet.name}</span>
              </div>
            </PixelatedBordersWrapper>
          </div>
          <div className={css.right}>
            <h4 className={css.confirmSellPetTitle}>
              {t("pets.modal.attention")}
            </h4>
            <p className={css.confirmSellPetSubtitle}>
              {t("pets.buyNew.details")}
            </p>
            <p className={css.confirmSellPetSubtitle}>
              {t("pets.buyNew.afterBuyDetails")}
            </p>
          </div>
        </div>

        <div className={css.btnsWrapper}>
          {isFinished ? (
            isMaxLvl ? (
              <Button variant={"secondary"} className="text-red-900">
                {t("buttons.maxLvl")}
              </Button>
            ) : (
              <Button
                variant={"secondary"}
                onClick={onCancel}
                disabled={!selectedPet.userPet?.nextLevelPet}
              >
                {t("pets.upgrade")}
              </Button>
            )
          ) : (
            <Button>
              {hour}h {minute}m {seconds}s
            </Button>
          )}
          <Button
            onClick={handleEvolve}
            className="flex flex-col [&&&]:leading-[18px]"
            disabled={isEvolutionDisabled}
          >
            <span>{t("pets.buyNew.buy")}</span>
            <span>({evolutionPrice} PIX)</span>
          </Button>
        </div>
      </div>
    </ModalWithHeader>
  );
};
