import React, { FC, HTMLAttributes } from 'react';
import cn from "classnames";


interface IProgressBarProps extends HTMLAttributes<HTMLOrSVGElement> {
  progress?: number;
  color?: string;
}


const ProgressBar: FC<IProgressBarProps> = ({
  progress = 0,
  color,
  className
}) => {
  const radius = 19.5;
  const strokeWidth = 5;

  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (((progress) / 100) * circumference);


  return (
    <svg className={cn("progress-bar", className)} width="44" height="44" viewBox="0 0 44 44">
      <circle
        cx="22"
        cy="22"
        r={radius}
        fill="transparent"
        stroke="#393143"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="22"
        cy="22"
        r={radius}
        fill="transparent"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset || 0}
        strokeLinecap="round"
        transform="rotate(-90 22 22)"
      />
    </svg>
  );
};


export default ProgressBar;
