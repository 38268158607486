import { userProfileService } from "../../../service/service";
import { useUserProfileStore } from "../../../store/store";
import { Loader } from "../../../../../components/Loader";
import styles from "./reactions.module.scss"

export const Reactions = () => {
  const { user, reactions, reactionsLoading } = useUserProfileStore();

  const formatedNumber = (number: number) =>
    Intl.NumberFormat("en", { notation: "compact" }).format(number);

  const sortedReactions = [...reactions].sort(
    ({ reactionsCount: a }, { reactionsCount: b }) => b - a
  );

  return (
    <div className={styles.reactions}>
      {reactionsLoading && (
        <div className={styles.centered_loader}>
          <Loader />
        </div>
      )}
      {!reactionsLoading && (
        <div className={styles.container}>
          {sortedReactions.map((reaction) => (
            <div
              key={reaction.id}
              color={reaction.reacted ? "#B364D8" : "#232330"}
              className={styles.item_container}
            >
              <button
                className={`${styles.item} ${reaction.reacted && styles.reacted}`}
                onClick={() =>
                  user &&
                  userProfileService.toggleUserReaction({
                    userId: user.id,
                    emojiId: reaction.id,
                  })
                }
              >
                <img src={reaction.iconUrl} alt="" />
                <div className={styles.chip}>{formatedNumber(reaction.reactionsCount)}</div>

              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
