import {useTasksStore} from "../store/tasksStore";
import {UserTask} from "./UserTask";
import tasksService from "../service/tasksService";
import "../styles/UserTasks.scss";
import {ITask} from "../types";
import {Title} from "../../../newComponents/Title/Title";
import {useTranslation} from "react-i18next";
import {getDoneCount} from "../tasks.helpers";

export const UserTasks = () => {
  const { t } = useTranslation();
  const { userTasks } = useTasksStore();

  const doneUserTasks = getDoneCount(userTasks);
  const userTasksCount = userTasks.length;
  const isTasksCompleted = doneUserTasks === userTasksCount;

  const onTaskClick = (task: ITask) => {
    tasksService.handleTaskClick({
      taskType: task.type,
      taskID: task.id,
      taskPayload: task.payload,
    }).catch(console.warn);
  }

  return (
    <div className="tasks__list">
      <div className="tasks__list-header">
        <Title tagName="h2" className="tasks__list-title">{t("tasks.tab.individual")}</Title>
        <span className="tasks__list-counter">
          {!isTasksCompleted
            ? <>
              {t("tasks.card.complete")}: <strong>{doneUserTasks}</strong>/{userTasksCount}
              </>
            : <strong>{t("tasks.card.done")}</strong>
          }
        </span>
      </div>

      <ul className="user-tasks">
        {userTasks.map((task, index) => (
          <UserTask
            key={task.id}
            index={index}
            task={task}
            color={task.color}
            onClick={() => onTaskClick(task)}
          />
        ))}
      </ul>
    </div>
  );
};
