import { useEffect, useState } from "react";
import CipherCard from "../../../../assets/images/AvararsCards/cipher.png";
import CodzillaCard from "../../../../assets/images/AvararsCards/codzilla.png";
import GlitchCard from "../../../../assets/images/AvararsCards/glitch.png";
import RobotCard from "../../../../assets/images/AvararsCards/robot.png";
import SynthrenCard from "../../../../assets/images/AvararsCards/synthren.png";
import "./animations.scss";
import { mobileBreakPoint } from "../../../../constants/breakpoints";

const avatars = [CipherCard, CodzillaCard, GlitchCard, RobotCard, SynthrenCard];

const calculateColumns = (isMobile?: boolean) => {
  const columnWidth = isMobile ? 88 : 176;
  const halfScreenWidth = isMobile ? window.innerWidth : window.innerWidth / 2;
  return Math.floor(halfScreenWidth / columnWidth);
};

export const CardsAnimation = () => {
  const isMobile = window.innerWidth < mobileBreakPoint;

  const [columns, setColumns] = useState(calculateColumns(isMobile));

  const avatarHeight = isMobile ? 136 : 276;
  const viewportHeight = window.innerHeight;
  const avatarsPerColumn = Math.ceil(viewportHeight / avatarHeight) + 2;

  useEffect(() => {
    const handleResize = () => {
      setColumns(calculateColumns());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={
        "flex overflow-visible w-full md:h-full h-1/2 md:w-1/2 pr-[12px] md:pr-0 justify-center"
      }
    >
      {Array.from({ length: columns }).map((_, columnIndex) => (
        <div key={columnIndex} className="avatar-column">
          <div className="avatar-wrapper">
            {Array.from({ length: avatarsPerColumn * 2 }).flatMap((_, idx) =>
              avatars.map((avatar, avatarIdx) => (
                <img
                  key={`${columnIndex}-${avatarIdx}-${idx}`}
                  src={avatar}
                  alt={`Avatar ${avatarIdx}`}
                  className="avatar-card"
                />
              ))
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
