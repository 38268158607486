import cn from "classnames";

import { useTranslation } from "react-i18next";

import css from "./Rewards.module.scss";
import dayImg1 from "../../../../assets/images/dailyRewards/1.png";
import dayImg2 from "../../../../assets/images/dailyRewards/2.png";
import dayImg3 from "../../../../assets/images/dailyRewards/3.png";
import dayImg4 from "../../../../assets/images/dailyRewards/4.png";
import dayImg5 from "../../../../assets/images/dailyRewards/5.png";
import dayImg6 from "../../../../assets/images/dailyRewards/6.png";
import dayImg7 from "../../../../assets/images/dailyRewards/7.png";
import { CheckIconSmall } from "../../../../assets/iconComponents/new_design_icons/CheckIconSmall";

const rewardImgs = [
  dayImg1,
  dayImg2,
  dayImg3,
  dayImg4,
  dayImg5,
  dayImg6,
  dayImg7,
]

interface PropsTypesCard {
  day: number;
  isActive: boolean;
  isDone: boolean;
  reward: number;
}

export const RewardsCard = ({
  day,
  reward,
  isDone,
  isActive,
}: PropsTypesCard) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        css.rewardCardWrapper,
        isActive && css.rewardCardWrapperActive,
        isDone && css.rewardCardDisabled,
      )}
    >
      <div className={css.cardTop}>
        <div className={css.imgWrapper}>
          <img src={rewardImgs[day - 1]} alt="dayImg" draggable={false} />
        </div>
        <span className={css.rewardAmount}>{reward}</span>
      </div>


      <div className={css.cardBottom}>
        {isDone && (
          <CheckIconSmall iconColor="#83D987" />
        )}
        {t("pets.day")} {day}

      </div>

    </div>
  );
};
