import { BaseService } from "../../../services/BaseService";

import { IRetryTimerStore, retryTimersStore } from "../store/retryTimersStore";

class RetryTimersService extends BaseService<IRetryTimerStore> {
  constructor() {
    super(retryTimersStore);
  }

  setTaskLoading = (taskID: string) => {
    this.setState(({ refetchingTasksIds }) => ({
      refetchingTasksIds: [...refetchingTasksIds, taskID],
    }));
  };

  // it's userTaskID
  removeLoadingTask = (taskID: string) => {
    this.setState(({ refetchingTasksIds }) => ({
      refetchingTasksIds: refetchingTasksIds.filter((id) => id !== taskID),
    }));
  };
}

export const retryTimersService = new RetryTimersService();
