import { Tabs } from "./components/Tabs";
import { tasksStore } from "./store/tasksStore";
import { Loader } from "../../components/Loader";
import { FC, useEffect } from "react";
import tasksService from "./service/tasksService";
import { TasksList } from "./components/TasksList";
import { useShallow } from "zustand/react/shallow";
import "./Tasks.scss";

export const Tasks: FC = () => {
  const tasks = tasksStore(({
    generalTasks,
    userTasks
  }) => ({ generalTasks, userTasks }));

  const isLoading = tasksStore(
    useShallow(
      ({ isGeneralTasksLoading, isUserTasksLoading }) =>
        isUserTasksLoading || isGeneralTasksLoading
    )
  );

  useEffect(() => {
    if (!tasks.generalTasks) {
      tasksService.getGeneralTasks().catch(console.warn);
    }
    if (!tasks.userTasks) {
      tasksService.getUserTasks().catch(console.warn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tasks">
      {isLoading ? (
        <div className="tasks__loader">
          <Loader />
        </div>
      ) : (
        <div className="tasks__content">
          <Tabs />
          <TasksList />
        </div>
      )}
    </div>
  );
};
