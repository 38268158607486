import { SidebarLogo } from "../../assets/iconComponents/SidebarLogo";
import { CloseIcon } from "../../assets/iconComponents/CloseIcon";
import React, { FC, useEffect } from "react";
import { useIsSidebarStatic } from "../../hooks/useIsSidebarStatic";
import Navigation from "./components/Navigation/Navigation";
import Social from "./components/Social/Social";
import SidebarBanner from "./components/SidebarBanner/SidebarBanner";
import tgBannerAsset from "../../assets/images/banner_tbot.jpg";
import "./Sidebar.scss";
import { LanguageDropdown } from "../LanguageDropdown";
// import { airdropService } from "../../pages/claim/service";


type ISidebarProps = {
  toggleSidebar: (value?: boolean) => void
}


export const Sidebar: FC<ISidebarProps> = ({ toggleSidebar }) => {
  const isMobileSidebar = useIsSidebarStatic()

  // useEffect(() => {
  //   if (localStorage.getItem('accessToken')) {
  //     airdropService.getAirdropInfo().catch(console.warn);
  //   }
  // }, []);

  useEffect(() => {
    if (!isMobileSidebar) {
      toggleSidebar(false);
    }
  }, [isMobileSidebar, toggleSidebar]);

  return (
    <aside className="sidebar">
      <header className="sidebar__header">
        <div className="sidebar__logo">
          <SidebarLogo />
        </div>
        <CloseIcon className="sidebar__close" onClick={() => toggleSidebar()} />
      </header>

      <Navigation className="sidebar__navigation" toggleSidebar={toggleSidebar} />

      <SidebarBanner
        className={`sidebar__banner sidebar-banner_medium`}
        bgImageUrl={tgBannerAsset}
        target="_blank"
      />

      <footer className="sidebar__footer">
        <LanguageDropdown />
        <Social />
      </footer>
    </aside>
  );
};
