import {Tooltip} from "@mui/material";
import {TabsEnum} from "../types";
import {useTasksStore} from "../store/tasksStore";
import tasksService from "../service/tasksService";

import cn from "classnames";
import "../styles/Tabs.scss";
import {getInProggressCount} from "../tasks.helpers";
import {useTranslation} from "react-i18next";

export const Tabs = () => {
  const { t } = useTranslation();
  const { currentTab, generalTasks, userTasks } = useTasksStore();

  const tabs = [
    {id: TabsEnum.Individual, name: "tasks.tab.individual", soon: false, inProgressCount: getInProggressCount(userTasks)},
    {id: TabsEnum.Society, name: "tasks.tab.society", soon: false, inProgressCount: getInProggressCount(generalTasks)},
  ];

  return (
    <ul className="tabs">
      {tabs.map(({id, name, soon, inProgressCount}) =>
        <li className="tabs__item" key={id}>
          {soon
            ? (
              <Tooltip
                title="Soon"
                enterTouchDelay={0}
              >
                <button className="tabs__button" data-content="0">{t(name)}</button>
              </Tooltip>
            )
            : (
              <button
                className={cn("tabs__button", (currentTab === id) && "tabs__button--active")}
                onClick={() => tasksService.setCurrentTab(id)}
                data-content={inProgressCount}
              >
                {t(name)}
              </button>
          )}
        </li>
      )}
    </ul>
  );
};
