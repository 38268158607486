import { useAvatarsStore } from "./store";
import avatarsService from "./service";
import { userStore } from "../../../../../services/user/store";
import { useShallow } from "zustand/react/shallow";
import { AvatarsList } from "./AvatarsList";
import { useTranslation } from "react-i18next";
import styles from "./AvatarSection.module.scss";
import { Button } from "../../../../../components/Buttons/Button";
import { useUserProfileStore } from "../../../store/store";
import { userProfileService } from "../../../service/service";


type IEditModalBodyProps = {
  handleClose: (isDataUpdateNeeded?: boolean) => void
}

export const EditModalBody: React.FC<IEditModalBodyProps> = ({
  handleClose,
}) => {
  const { t } = useTranslation();

  const { selectedAvatarId, selectedAvatarToEditId } = useUserProfileStore()

  const currentAvatar = userStore(
    useShallow(({ user }) => user?.avatar?.avatar?.id || "")
  );

  const { avatars, isEditPending } = useAvatarsStore();
  const selectedAvatar = avatars.find(({ id }) => id === selectedAvatarId);

  const handleSave = async () => {
    await avatarsService.setAvatar(selectedAvatar?.userAvatar.id || "");
    handleClose(true);
  };

  return (
    <div className={`${styles.container} custom-scrollbar`}>
      <AvatarsList
        selectedAvatarToEditId={selectedAvatarToEditId}
        setSelectedAvatarToEditId={userProfileService.setSelectedAvatarToEditId}
        currentAvatar={currentAvatar}
        selectedAvatarID={selectedAvatarId || ""}
      />
      <div className={styles.avatarActions}>
        <Button
          className={styles.large_button}
          isUppercased
          onClick={() => handleClose()}>
          {t("buttons.cancel")}
        </Button>
        <Button
          className={`${styles.large_button} ${styles.purple}`}
          isUppercased
          isLoading={isEditPending}
          onClick={handleSave}
          disabled={
            selectedAvatarToEditId === selectedAvatar?.id ||
            (!selectedAvatarToEditId && currentAvatar === selectedAvatar?.id)
          }
        >
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  );
};
