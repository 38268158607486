import {createElement, FC, PropsWithChildren} from 'react';
import './Title.scss';
import cn from "classnames";


export interface ITitleProps {
  className?: string;
  tagName?: 'h1' | 'h2' | 'h3';
  isUppercased?: boolean;
  variant?: 'xl'| 'l' | 'l-fixed' | 'm' | 's' | 'xs';
}


export const Title: FC<PropsWithChildren<ITitleProps>> = ({
  tagName = 'h1',
  variant = 'l',
  isUppercased,
  className,
  children,
}) => {
  const cl = cn(
    'title',
    `title--${variant}`,
    isUppercased && 'title--uppercased',
    className
  )

  return createElement(
    tagName,
    { className: cl },
    children
  );
};
