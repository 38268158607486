import React, {FC} from 'react';
import {transformNumber} from "../../../../utils/transformNumber";
import cn from "classnames";
import "./ReferralsInfo.scss";
import {Title} from "../../../../newComponents/Title/Title";
import {useTranslation} from "react-i18next";

interface IReferralsInfoProps {
  label: string;
  value: number;
  className?: string;
  isPoints?: boolean;
}

const ReferralsInfo: FC<IReferralsInfoProps> = ({
  className,
  label,
  value,
  isPoints
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn("referrals-info", className)}>
      <Title
        variant="s"
        isUppercased
        className="referrals-info__label"
      >
        {label}
      </Title>
      <p className={cn("referrals-info__count", isPoints && "referrals-info__count--points")}>
        {transformNumber(value)}

        {isPoints && <span className="referrals-info__subtext">{t("referrals.info.currency.name")}</span>}
      </p>
    </div>
  );
};


export default ReferralsInfo;
