import { create, useStore } from "zustand";

export type IRouletteSpinnerStore = {
  rouletteRolling: boolean,
  actions: {
    roll: () => void
    stopRoll: () => void
  }
}

export const rouletteSpinnerStore = create<IRouletteSpinnerStore>((set) => ({
  rouletteRolling: false,
  actions: {
    roll: () => {
      set({ rouletteRolling: true });
    },
    stopRoll: () => {
      set({ rouletteRolling: false });
    }
  }
}));

export const useRouletteSpinnerStore = () => useStore(rouletteSpinnerStore);
