import React, {FC} from 'react';
import {CopyIcon} from "../../../../../assets/iconComponents/new_design_icons/CopyIcon";
import {useTranslation} from "react-i18next";
import {useCopyText} from "../../../../../newHooks/useCopyText";
import './ReferralsCopyCode.scss';
import cn from "classnames";

interface IReferralsCopyCodeProps {
  className?: string;
  codeValue: string;
}

export const ReferralsCopyCode: FC<IReferralsCopyCodeProps> = ({ codeValue, className }) => {
  const { t } = useTranslation();
  const { onCopyTextClick, isTextCopied } = useCopyText();

  const cl = cn('referrals-copy-code', className);

  return (
    <span className={cl}>
      <b className="referrals-copy-code__label">{t("referrals.code")}</b>

      <span className="referrals-copy-code__wrapper">
        <span className="referrals-copy-code__value">{codeValue}</span>

        <button
          className="referrals-copy-code__button"
          type="button"
          onClick={!isTextCopied ? () => onCopyTextClick(codeValue): undefined}
          disabled={isTextCopied}
        >
          <CopyIcon/>
        </button>

        {isTextCopied && <span className="referrals-copy-code__notification">Copied</span>}
      </span>

    </span>
  );
};
