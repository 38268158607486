import { SVGAttributes } from "react";

export const DiscordIcon = ({ ...props }: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.87 2.13669C17.465 1.49343 15.9817 1.03708 14.4581 0.779297C14.2496 1.15201 14.0609 1.53549 13.8929 1.92811C12.27 1.68355 10.6195 1.68355 8.99657 1.92811C8.82847 1.53553 8.63982 1.15206 8.43141 0.779297C6.90681 1.03926 5.42256 1.49669 4.01612 2.14005C1.22397 6.27108 0.467059 10.2995 0.845513 14.2708C2.48066 15.4789 4.31087 16.3977 6.25656 16.9872C6.69467 16.398 7.08235 15.7729 7.41547 15.1185C6.78275 14.8822 6.17207 14.5906 5.59048 14.2472C5.74355 14.1362 5.89325 14.0218 6.0379 13.9108C7.73017 14.7066 9.5772 15.1193 11.4473 15.1193C13.3173 15.1193 15.1644 14.7066 16.8566 13.9108C17.003 14.0302 17.1527 14.1446 17.3041 14.2472C16.7213 14.5912 16.1095 14.8833 15.4757 15.1202C15.8084 15.7742 16.1961 16.3988 16.6346 16.9872C18.582 16.4 20.4136 15.4817 22.049 14.2724C22.4931 9.66708 21.2904 5.67565 18.87 2.13669ZM7.89485 11.8285C6.84022 11.8285 5.96894 10.8714 5.96894 9.69399C5.96894 8.51658 6.80995 7.5511 7.89148 7.5511C8.97302 7.5511 9.83758 8.51658 9.81908 9.69399C9.80057 10.8714 8.96966 11.8285 7.89485 11.8285ZM14.9997 11.8285C13.9434 11.8285 13.0755 10.8714 13.0755 9.69399C13.0755 8.51658 13.9165 7.5511 14.9997 7.5511C16.0829 7.5511 16.9407 8.51658 16.9222 9.69399C16.9037 10.8714 16.0745 11.8285 14.9997 11.8285Z"
      />
    </svg>
  );
};
