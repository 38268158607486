import axios, { AxiosRequestConfig } from "axios";
import { appStage } from "../constants/appStages";
import { AppRoutes } from "../constants/routes";
import { AppStage } from "../types/common";
import authService from "./authService";

const appApiUrls: Record<AppStage, string> = {
  development: "https://api-dev-dashboard.pixelverse.xyz/api",
  staging: "https://api-stage-dashboard.pixelverse.xyz/api",
  production: "https://api-dashboard.pixelverse.xyz/api",
};

const authApiUrls: Record<AppStage, string> = {
  development: "https://api-dev-auth.pixelverse.xyz/api",
  staging: "https://api-dev-auth.pixelverse.xyz/api",
  production: "https://api-auth.pixelverse.xyz/api",
};

export const API_URL = appApiUrls[appStage];
export const AUTH_API_URL = authApiUrls[appStage];

export const $api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _isRetry?: boolean;
}

$api.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (!config.headers) {
    config.headers = {};
  }

  config.headers["Authorization"] = `${accessToken!}`;
  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest: CustomAxiosRequestConfig = error.config;
    if (error.response?.status === 401 && error.config && !error.config._isRetry) {
      try {
        if (localStorage.getItem('accessToken')) {
          !authService.isPending && await authService.sendRefreshRequest();
          originalRequest._isRetry = true;
          return $api.request(originalRequest);
        } else {
          window.location.replace("/#/");
        }
      } catch (e) {
        const path = window.location.pathname;
        if (path !== AppRoutes.staking) {
          localStorage.removeItem("accessToken");
          window.location.replace("/#/");
        }
      }
    } else if (error.response?.status === 404) {
      //TODO if error = user not found -> then remove accessToken
    }
    throw error;
  },
);
