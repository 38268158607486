import {FC} from "react";
import {NavLink} from "react-router-dom";
import {IIconProps} from "../../../../../../assets/iconComponents/new_design_icons/types";
import {AppRoutes} from "../../../../../../constants/routes";
import "./NavigationItem.scss";
import ElementWithIcon from "../../../../../Buttons/ElementWithIcon/ElementWithIcon";
import cn from "classnames";
import {useTranslation} from "react-i18next";


type ISidebarOptionProps = {
  className?: string;
  text: string;
  disabled?: boolean;
  path: AppRoutes;
  toggleSidebar: () => void;
  Icon: ({...props}: IIconProps) => JSX.Element;
};


const NavigationItem: FC<ISidebarOptionProps> = ({
  text,
  disabled,
  path,
  toggleSidebar,
  Icon,
}) => {
  const { t } = useTranslation();

  return (
    <NavLink
      key={text}
      to={path}
      className={({isActive}) =>
        cn('navigation-item', isActive && 'navigation-item--selected', disabled && 'navigation-item--disabled')
    }
      onClick={(e) => (disabled ? e.preventDefault() : toggleSidebar())}
      children={() => (
        <>
          <ElementWithIcon className="navigation-item__icon" Icon={Icon} />
          <span className="navigation-item__label">{text}</span>
          {disabled && <span className="navigation-item__chip">{t("sidebar.chip")}</span>}
        </>
      )}
    />
  );
};

export default NavigationItem;
