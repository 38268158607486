import React, {FC, PropsWithChildren} from 'react';
import cn from "classnames";
import './Card.scss';

interface ICardProps {
  className?: string;
  borderRadius?: number;
}

export const Card: FC<PropsWithChildren<ICardProps>> = ({
  className,
  children
}) => {
  const cl = cn(
    'card',
    className
  )

  return (
    <div className={cl}>
      {children}
    </div>
  );
};
