import { useEffect, useRef } from "react";
import css from "./Rewards.module.scss";
import { IRewardAction } from "../../types";
import { RewardsCard } from "./RewardsCard";
import { useDraggable } from "react-use-draggable-scroll";

interface PropsTypesCard {
  rewardAction: Array<IRewardAction & { done: boolean }>;
  currentLength: number;
}

export const RewardsList = ({
  rewardAction,
  currentLength,
}: PropsTypesCard) => {
  const ref =
    useRef<HTMLUListElement>() as unknown as React.MutableRefObject<HTMLUListElement>;
  const { events } = useDraggable(ref);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollLeft = 55
      }

    }, 1000)
  }, [])

  return (
    <div className={css.rewardsListWrapper}>
      <ul className={css.rewardsList} {...events} ref={ref}>
        {rewardAction.map((item, i) => (
          <li key={i} className={css.rewardItem}>
            <RewardsCard
              day={item.order}
              isActive={item.order === currentLength + 1}
              isDone={item.done}
              reward={item.reward}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
