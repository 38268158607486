import React from "react";
import { actionPointsStore } from "../../services/actionPoints/store";
import { useShallow } from "zustand/react/shallow";
import { Trans, useTranslation } from "react-i18next";
import { NotAuthLayout, NotAuthLayoutAside, NotAuthLayoutContent } from "../../newLayouts/NotAuthLayout";
import { NotAuthAsideContent, NotAuthAsideHeader } from "../../newComponents/NonAuth";
import { SetupStep } from "./components/SetupStep/SetupStep";
import loginBg1 from "../../assets/images/loginBg1.png";
import './index.scss';

export const Setup = () => {
  const { t } = useTranslation();

  const referralPointsReward = actionPointsStore(
    useShallow(({ actionPoints }) => actionPoints?.ENTER_REFERRAL_CODE || 30)
  );

  const asideText = <Trans
    i18nKey={"auth.signup.congrats.text"}
    values={{ points: referralPointsReward }}
  />;

  const badgeText = <Trans
    i18nKey={"auth.signup.congrats.badge"}
    values={{ points: referralPointsReward }}
    components={{ b: <b /> }}
  />;

  return (
    <NotAuthLayout className="setup-page">
      <NotAuthLayoutAside>
        <NotAuthAsideHeader />
        <NotAuthAsideContent
          badge={badgeText}
          title={t("auth.congrats")}
          text={asideText}
        />

        <div className="setup-page__image-wrapper">
          <img
            className="setup-page__image"
            src={loginBg1}
            alt="Decoration background"
            loading="lazy"
          />
        </div>
      </NotAuthLayoutAside>

      <NotAuthLayoutContent>
        <SetupStep />
      </NotAuthLayoutContent>
    </NotAuthLayout>
  );
};
