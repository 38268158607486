import { create, useStore } from "zustand";
import { CurrentEpochDataType, RaffleResultResponse, RaffleState, StakeDataType, StakedInfo, UserProofsType, UserRaffleData } from "./types";
import { PublicClient } from "viem";

export interface IStakingStore {
  loading: boolean;
  totalEntries: number;
  entriesPerDay: string;
  raffles: string;
  untilRaffleDate: Date | undefined;
  isStaking: boolean;
  isConnecting: boolean;
  unstaking: {
    loading: boolean;
    id: number;
  };
  isGettingInfo: boolean;
  penaltyDays: number;
  gettingWithdraws: boolean;
  stakedBalance: string;
  isLastEpochRewardsHidden: boolean;
  newEpoch: CurrentEpochDataType | undefined;
  stakers: StakeDataType[];
  stakingInfo: StakedInfo;
  genesis?: StakeDataType;
  stakerStakeCount: number;
  maxBPS: number;
  income: number;
  setIncome: (income: IStakingStore["income"]) => void;
  handleStakersAfterUnstake: (id: number) => void;
  isUnstakedCanceled: boolean;
  setIsUnstakedCanceled: (isUnstakedCanceled: boolean) => void;
  isClaimed: boolean;
  stakerReward?: number;
  tickets: number;
  userProofs: UserProofsType | null;
  stakingDecimals: number | null;
  tokenBalance: number | string;
  unstakedTowithdrawId: number | null;
  isCancelUnstakeSuccess: boolean;
  previousRaffleResult: RaffleResultResponse | null;
  previousRaffleStatus: RaffleState;
  isStakingInfoOpen: boolean;
  withdrawAvailableDate?: string;
  publicClient: null | PublicClient;
  isRaffleEnded: boolean;
  history: UserRaffleData[]
}

export const stakingStore = create<IStakingStore>((set, get) => {
  return {
    loading: false,
    totalEntries: 0,
    entriesPerDay: "0",
    penaltyDays: 0,
    income: 0,
    raffles: "0",
    untilRaffleDate: undefined,
    isStaking: false,
    isConnecting: false,
    isGettingInfo: false,
    isLastEpochRewardsHidden: true,
    unstaking: {
      loading: false,
      id: -1,
    },
    gettingWithdraws: false,
    isUnstakedCanceled: false,
    stakedBalance: "0",
    newEpoch: undefined,
    setIncome: (income) => set({ income }),
    setIsUnstakedCanceled: (isUnstakedCanceled) => {
      set({ isUnstakedCanceled });
    },
    stakers: [],
    stakerStakeCount: 0,
    maxBPS: 0,
    handleStakersAfterUnstake: (id) => {
      const stakers = get().stakers;
      const updatedStakers = stakers.map((staker) => (staker.id === id ? { ...staker, unstaked: true } : staker));
      set({ stakers: updatedStakers });
    },
    isClaimed: false,
    stakerReward: undefined,
    tickets: 0,
    userProofs: null,
    stakingDecimals: null,
    tokenBalance: 0,
    unstakedTowithdrawId: null,
    isCancelUnstakeSuccess: false,
    previousRaffleResult: null,
    previousRaffleStatus: RaffleState.unavailable,
    isStakingInfoOpen: false,
    stakingInfo: {
      stakedCoins: 0,
      incomeTicketsPerDay: 0,
      isWithdrawn: false,
      prizeFund: 0,
      totalTicketsAmount: 0,
    },

    publicClient: null,
    isRaffleEnded: false,
    history: []
  };
});

export const useStakingStore = () => useStore(stakingStore);
