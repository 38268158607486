import {ReactElement, ButtonHTMLAttributes, forwardRef, ForwardedRef, PropsWithChildren} from 'react';
import cn from 'classnames';
import {Loader} from "../../components/Loader";
import './Btn.scss';

export type TButtonVariant = 'pink' | 'white' | 'grey' | 'red' | 'border';
export type TButtonSize = 's' | 'm' | 'l';

interface IKitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: TButtonVariant
  size?: TButtonSize;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  label?: string;
  isFullWidth?: boolean;
  isLoading?: boolean;
}

export const Btn = forwardRef(
  (props: PropsWithChildren<IKitButtonProps>, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      className,
      variant = 'pink',
      size = 'm',
      iconLeft,
      iconRight,
      label,
      isFullWidth = false,
      isLoading = false,
      type = 'button',
      disabled,
      children,
      ...otherProps
    } = props;

    const cl = cn(
      'btn',
      `btn--${variant}`,
      `btn--${size}`,
      isLoading && 'btn--loading',
      isFullWidth && 'btn--full-width',
      className,
    );

    return (
      <button
        ref={ref}
        className={cl}
        type={type}
        disabled={disabled || isLoading}
        {...otherProps}
      >
        {isLoading
          ? <Loader color={variant === "pink" ? "#FFFFFF" : undefined} />
          : (
            <>
              {iconLeft && <span className="btn__icon btn__icon--left">{iconLeft}</span>}
              <span className="btn__label">{children}</span>
              {iconRight && <span className="btn__icon btn__icon--right">{iconRight}</span>}
            </>
          )
        }
      </button>
    );
  },
);
