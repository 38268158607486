import React from "react";
import Modal from "@mui/material/Modal";
import { Zoom } from "@mui/material";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { getFontClass } from "../../../../../utils/getFontClass";
import css from "./ConnectEmailMainModalWrapper.module.scss";


export interface IConnectEmailModalSteps {
  totalSteps: number
  currentStep: number
}

export interface ModalPropsTypes {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  text?: JSX.Element | string;
  steps?: IConnectEmailModalSteps;
  noScroll?: boolean;
  mobileFit?: boolean;
}

export const ConnectEmailMainModalWrapper = ({
  open,
  onClose,
  children,
  title = "",
  text,
  steps,
  mobileFit,
}: ModalPropsTypes) => {
  const { i18n, t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      classes={{
        root: `${css.modalRoot} ${getFontClass(i18n.language)}`,
        backdrop: css.modalBackdrop,
      }}
      sx={{ outline: "none !important" }}
    >
      <Zoom
        in={open}
        style={{ transitionDelay: open ? "200ms" : "100ms", outline: "none" }}
      >
        <div
          className={classNames(
            css.modalBody,
            {
              [css.modalContentMobileFull]: !mobileFit,
              [css.mobileFitScroll]: mobileFit,
            })}
        >
          <div className={css.modalHeader}>
            <h3>{title}</h3>
            {steps && <p>
              {t("auth.modal.step")}: <span><span>{steps.currentStep}</span>/{steps.totalSteps}</span>
            </p>}
          </div>
          {!!text && <p className={css.modalText}>{text}</p>}
          {children}
        </div>
      </Zoom>
    </Modal>
  );
};
