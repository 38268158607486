import { create, useStore } from "zustand";
import { ITask, TabsEnum } from "../types";

export interface ITasksStore {
  isUserTasksLoading: boolean;
  isGeneralTasksLoading: boolean;
  isSnapshotUploading: boolean;
  currentTab: TabsEnum;
  openedTask: string | null;
  generalTasks: Array<ITask>;
  userTasks: Array<ITask>;
}

export const tasksStore = create<ITasksStore>(() => ({
  isGeneralTasksLoading: false,
  isUserTasksLoading: false,
  isSnapshotUploading: false,
  currentTab: TabsEnum.Individual,
  openedTask: null,
  userTasks: [],
  generalTasks: [],
}));

export const useTasksStore = () => useStore(tasksStore);
