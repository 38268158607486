import React, { FC, HTMLAttributes } from "react";
import { sidebarRoutes } from "../../../../constants/routes";
import NavigationItem from "./components/NavigationItem/NavigationItem";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import "./Navigation.scss";
import { ButtonLink } from "../../../Buttons/Button/ButtonLink";
import { PV_CLAIM_PIXFI } from "../../sidebar.constants";
import { isMainnet } from "../../../../pages/staking/web3/contracts/constants";
import Info from "../Info/Info";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const addToken = async () => {
  const testTokenAddress = "0x5fE5A143E62854CBdF878aE68d31B0F1A68975E6";
  const testTokenSymbol = "Test PIXFI";
  const testTokenDecimals = 18;
  const testTokenImage = "https://dashboard.pixelverse.xyz/favicon.svg";

  const mainTokenAddress = "0xd795eb12034C2b77d787a22292c26fab5f5C70Aa";
  const mainTokenSymbol = "PIXFI";
  const mainTokenDecimals = 18;
  const mainTokenImage = "https://dashboard.pixelverse.xyz/favicon.svg";

  const tokenAddress = isMainnet ? mainTokenAddress : testTokenAddress;
  const tokenSymbol = isMainnet ? mainTokenSymbol : testTokenSymbol;
  const tokenDecimals = isMainnet ? mainTokenDecimals : testTokenDecimals;
  const tokenImage = isMainnet ? mainTokenImage : testTokenImage;

  try {
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage,
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};

interface INavigationProps extends HTMLAttributes<HTMLElement> {
  toggleSidebar: (value?: boolean) => void;
}

const Navigation: FC<INavigationProps> = ({ toggleSidebar, className }) => {
  const { t } = useTranslation();

  return (
    <nav className={cn("navigation", className)}>
      <ul className="navigation__list">
        {sidebarRoutes.map((routes) => {
          const { name, path, disabled, Icon } = routes;

          return (
            <li className={"navigation__item"} key={path}>
              <NavigationItem
                path={path}
                text={t(`sidebar.${name}`)}
                toggleSidebar={toggleSidebar}
                disabled={disabled}
                Icon={Icon}
              />
            </li>
          );
        })}
      </ul>

      <Info className="navigation__info" />

      <div className="navigation__buttons-wrapper">
        <ButtonLink
          to={PV_CLAIM_PIXFI}
          variant="secondary"
          decoration="outlined"
          style={{ color: "#B364D8", width: "100%" }}
          isUppercased
        >
          {t("sidebar.button.trade")}
        </ButtonLink>
      </div>
    </nav>
  );
};

export default Navigation;
