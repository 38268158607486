import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import cn from "classnames";
import './BaseLayout.scss';
import { useToggleSidebar } from "../../hooks/useToggleSidebar";
import { userStore } from "../../services/user/store";
import { userService } from "../../services/user/service";
import actionPointsService from "../../services/actionPoints/service";
import tasksService from "../../pages/tasks/service/tasksService";
import { Sidebar } from "../../components/Sidebar";
import { TopBarRestyled } from "../../components/TopBar/TopBarRestyled";
import { useWindowWidth } from '../../hooks/useWindowWidth';


const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const windowWidth = useWindowWidth()
  const [actualElementWidth, setActualElementWidth] = useState(0)

  const {
    sidebarRef,
    isSidebarOpen,
    toggleSidebar,
    handleClickOutsideSidebar
  } = useToggleSidebar();


  const userId = userStore(({ user }) => user?.id)

  useEffect(() => {
    setActualElementWidth(headerRef.current?.offsetHeight!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth])


  useEffect(() => {

    if (!userId && localStorage.getItem('accessToken')) {
      actionPointsService.getActionPoints().catch(console.warn);
      tasksService.getGeneralTasks().catch(console.warn);
      tasksService.getUserTasks().catch(console.warn);
    }
  }, [userId]);


  return (
    <div className="base-layout">
      <div
        ref={sidebarRef}
        className={cn("base-layout__sidebar-wrapper", isSidebarOpen && "base-layout__sidebar-wrapper--show")}
      >
        <Sidebar toggleSidebar={toggleSidebar} />
      </div>
      <div className={cn("base-layout__body", isSidebarOpen && "base-layout__body--hidden")}>
        <div ref={headerRef} className="base-layout__header">
          <TopBarRestyled toggleSidebar={toggleSidebar} />
        </div>
        <div
          className="base-layout__content"
          style={windowWidth < 768 ? {} : { height: `calc(100svh - ${actualElementWidth}px)` }}
        >{children}</div>
      </div>
      <div
        className={cn("base-layout__overlay", isSidebarOpen && "base-layout__overlay--show")}
        onClick={handleClickOutsideSidebar}
      />
    </div>
  );
};

export default BaseLayout;
