import { useTranslation } from "react-i18next";
import { Locale, locales } from "../../constants/locales";
import {FC, useEffect, useState} from "react";
import css from "../Modals/Modal/styles.module.scss";
import { Modal, Zoom } from "@mui/material";
import "./LanguagesDropdown.scss";
import { SubModalCloseIcon } from "../../assets/iconComponents/SubModalCloseIcon";
import { CircleFlag } from "react-circle-flags";
import cn from "classnames";
import { NewArrowIcon } from "../../assets/iconComponents/NewArrowIcon";

interface ILanguageDropdownProps {
  className?: string;
}

export const LanguageDropdown: FC<ILanguageDropdownProps> = ({ className }) => {
  const { t, i18n } = useTranslation();

  const changeLocale = (locale: string) => i18n.changeLanguage(locale);

  const localesOptions = Object.entries(locales).map(([key, value]) => ({
    key,
    value,
  }));

  const initialOption = localesOptions.find((langKey) => langKey.key === i18n.language);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{ key: string; value: Locale }>(initialOption || localesOptions[0]);

  useEffect(() => {
    const currentLanguage = i18n.language.split('-')[0];

    const currentOption = localesOptions.find((langKey) => langKey.key === currentLanguage);

    if (currentOption) {
      setSelectedOption(currentOption);
    }
  }, [i18n.language]);

  const onSaveClick = () => {
    changeLocale(selectedOption.key).catch(console.warn);
    setIsOpen(false);
  };

  return (
    <div className={cn("language-dropdown", className)}>
      <button type="button" className="language-dropdown__button" onClick={() => setIsOpen(true)}>
        {initialOption && <CircleFlag countryCode={initialOption.value.country} height="24" width="24" />}

        <p className="language-dropdown__button-text">
          {initialOption ? initialOption.key : "Select lang"}
        </p>

        <NewArrowIcon />
      </button>

      <Modal
        className="languages-dropdown-modal"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classes={{
          root: css.modalRoot,
          backdrop: css.modalBackdrop,
        }}
        sx={{ outline: "none !important" }}
      >
        <Zoom in={isOpen} style={{ transitionDelay: isOpen ? "200ms" : "100ms", outline: "none" }}>
          <div className="languages-dropdown-modal__content">
            <div className="languages-dropdown-modal__wrapper">
              <div className="languages-dropdown-modal__head">
                <p className="languages-dropdown-modal__title">{t("langmodal.title")}</p>
                <button className="languages-dropdown-modal__button-close" type="button" onClick={() => setIsOpen(false)}>
                  <SubModalCloseIcon />
                </button>
              </div>

              <ul className="languages-dropdown-modal__list">
                {localesOptions.map((option, index) => {
                  const {
                    key: langKey,
                    value: { country: countryIso, language },
                  } = option;
                  const isSelected = selectedOption.key === langKey;

                  return (
                    <li
                      key={index}
                      className={cn("languages-dropdown-modal__item", isSelected && "is-select")}
                      onClick={() => (!isSelected ? setSelectedOption(option) : undefined)}
                    >
                      <div className="languages-dropdown-modal__info-block">
                        <span className="languages-dropdown-modal__flag">
                          <CircleFlag countryCode={countryIso} height="24" width="24" />
                        </span>
                        {language}
                      </div>

                      {isSelected && (
                        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18.4933 7.43502C18.8053 7.70743 18.8374 8.18122 18.565 8.49325L10.7079 17.4933C10.5654 17.6564 10.3594 17.75 10.1429 17.75C9.9263 17.75 9.72031 17.6564 9.57788 17.4933L6.43502 13.8933C6.16261 13.5812 6.19473 13.1074 6.50677 12.835C6.8188 12.5626 7.29259 12.5947 7.565 12.9068L10.1429 15.8596L17.435 7.50677C17.7074 7.19473 18.1812 7.16261 18.4933 7.43502Z"
                            fill="#B364D8"
                          />
                        </svg>
                      )}
                    </li>
                  );
                })}
              </ul>

              <button
                className="languages-dropdown-modal__button-action"
                type="button"
                onClick={onSaveClick}
                disabled={initialOption?.key === selectedOption.key}
              >
                {t("buttons.save")}
              </button>
            </div>
          </div>
        </Zoom>
      </Modal>
    </div>
  );
};
