import cn from "classnames";
import styles from "./index.module.scss"

export const Label = ({
  children,
  isDarken,
  color,
  className
}: {
  children: React.ReactNode;
  isDarken?: boolean;
  color?: string;
  className?: string;
}) => (
  <div
    style={{
      backgroundColor: color ? color : "#B364D8",
    }}
    className={cn(
      isDarken && "bg-[#744590]",
      className,
      styles.label
    )}
  >
    {children}
  </div>
);
