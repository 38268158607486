import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/Buttons/Button"
import { FormEvent, useEffect } from "react";
import { InputField, InputFieldErrors } from "../../../../../newComponents/Input";
import accountLinkingService from "../../../../login/services/accountLinkingService";
import { useAccountLinkingStore } from "../../../../login/store/accountLinkingStore";

export const AccountLinkingStep1 = () => {
  const { t } = useTranslation()
  const { email, emailErrorMessage, otpRequestLoading, otpRequested } = useAccountLinkingStore()

  useEffect(() => {
    if (otpRequested) {
      accountLinkingService.accountLinkingStepNext()
    }
  }, [otpRequested])

  const onEmailChange = (value: string) => {
    if (emailErrorMessage) {
      accountLinkingService.clearEmailError();
    }

    accountLinkingService.setEmail(value);
  }

  const onSubmitEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await accountLinkingService.accountLinkingCheckAndGetOtp(email)
  };
  let emailInputStatus: InputFieldErrors | null = null;
  let emailInputMessage: string | null = null;
  if (emailErrorMessage) {
    emailInputStatus = InputFieldErrors.ERROR;
    emailInputMessage = emailErrorMessage;
  }

  return <form
    id="link-account-form"
    className="account-email-linking-modal"
    onSubmit={onSubmitEmail}
  >
    <InputField
      label={t("auth.signup.input.label")}
      type="email"
      placeholder={t("login.email")}
      onChange={onEmailChange}
      status={emailInputStatus}
      message={emailInputMessage}
    />
    <Button
      form="link-account-form"
      isLoading={otpRequestLoading}
      variant="secondary"
      size="large"
      isUppercased
    >
      {t("auth.linkAccount.step1.button")}
    </Button>
  </form>
}