import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Suspense } from "react";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

import "./index.css";
import "./styles/root.scss";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { isDevelopmentStage } from "./constants/appStages";

window.Buffer = window.Buffer || Buffer;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <>
    {/* Use "http://localhost:3000/tonconnect-manifest.json" for local. Dev: https://dev-frontend-dashboard-hg8tg.ondigitalocean.app/*/}
    <TonConnectUIProvider manifestUrl={
      isDevelopmentStage ? "https://dev-frontend-dashboard-hg8tg.ondigitalocean.app/tonconnect-manifest.json" :
        "https://dashboard.pixelverse.xyz/tonconnect-manifest.json"
    }>
      <BrowserRouter>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </BrowserRouter>
    </TonConnectUIProvider>
  </>,
);

reportWebVitals();
