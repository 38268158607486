import { IReferalStore, referralStore } from "../store/referralStore";
import { BaseService } from "../../../services/BaseService";
import { userService } from "../../../services/user/service";
import { $api } from "../../../services/interceptor";
import { getErrorMessage } from "../../../utils/getErrorMessage";

class ReferralAPI extends BaseService<IReferalStore> {
  constructor() {
    super(referralStore);
  }

  setErrorMessage(message: string) {
    this.setState({ errorMessage: message });
  }
  setSuccessMessage(message: string) {
    this.setState({ successMessage: message });
  }

  setIsPending(isPending: boolean) {
    this.setState({ isPending });
  }

  async sendReferralCode(referralCode: string) {
    try {
      this.setIsPending(true);
      const payload = {
        referralCode,
      };

      const response = await $api.put(
        `/referrals/set-referer/${referralCode}`,
        payload
      );
      await userService.getUserData();

      if (response.status === 200) {
        this.setSuccessMessage("Referral code successfully applied");
        this.setErrorMessage("");
      }
    } catch (error) {
      this.setErrorMessage(getErrorMessage(error));
    } finally {
      this.setIsPending(false);
    }
  }

  setReferer = async (referralCode: string) => {
    return $api.put(`/referrals/set-referer/${referralCode}`, { referralCode });
  };
}

const referralService = new ReferralAPI();
export default referralService;
