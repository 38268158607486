import cn from "classnames";
import "../styles/TableCell.scss";


export function TableCell({
  value,
  className,
  personal,
}: {
  value?: number | string | JSX.Element | null;
  className?: string;
  personal?: boolean;
}) {
  return (
    <div
      className={cn(
        "referral-table-cell",
        className
      )}
    >
      {value || "-"}
    </div>
  );
}
