import React, { FC, HTMLAttributes } from "react";
import { UserData } from "../../../../../services/user/types";
import "./UserInfo.scss";
import cn from "classnames";
import {userStore} from "../../../../../services/user/store";
import {useShallow} from "zustand/react/shallow";
import {transformNumber} from "../../../../../utils/transformNumber";
import {roundNumber} from "../../../../../utils/roundNumber";
import {useTranslation} from "react-i18next";
import {useTonAddress} from "@tonconnect/ui-react";
import {useAccount} from "wagmi";
import {AppRoutes} from "../../../../../constants/routes";
import {useLocation} from "react-router-dom";
import {shortenAddress} from "../../../../../utils";

interface IUserInfoProps {
  className?: string;
  user: UserData | null;
  onClick: HTMLAttributes<HTMLDivElement>["onClick"];
}

const UserInfo: FC<IUserInfoProps> = ({ user, className, onClick }) => {
  const { t } = useTranslation();
  const friendlyTonAddress = useTonAddress();
  const { address } = useAccount();
  const { pathname } = useLocation();

  const userName = user?.profile?.username || "No name";

  const userSpendablePoints = userStore(
    useShallow(({ user }) => roundNumber(user?.spendablePoints?.amount || 0))
  );

  const isStaking = pathname === AppRoutes.staking;

  return (
    <div className={cn("user-info", isStaking && 'user-info--staking',  className)} onClick={onClick}>
      {isStaking
        ? (
          <p className="user-info__content">
            <span className="user-info__username">
              {address ? shortenAddress(address) : friendlyTonAddress ? shortenAddress(friendlyTonAddress) : t("wallet.title")}
            </span>
          </p>
        )
        : (
          <>
            <div className="user-info__avatar">
              {user?.avatar?.avatar ? (
                <img src={user?.avatar.avatar.iconUrl} alt={userName}/>
              ) : (
                <span className="user-info__no-avatar">?</span>
              )}
            </div>

            <p className="user-info__content">
              <span className="user-info__username">{userName}</span>
              <span className="user-info__points">{transformNumber(userSpendablePoints)} PIX</span>
            </p>
          </>
        )
      }

      <button type="button" className="user-info__dropdown-button">
        <svg width="12" height="7" viewBox="0 0 12 7" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.07629 6.45497C6.4905 7.04075 5.54075 7.04075 4.95497 6.45497L1.07629 2.57629C0.13134 1.63134 0.800589 0.015625 2.13695 0.015625H9.89431C11.2307 0.015625 11.8999 1.63134 10.955 2.57629L7.07629 6.45497Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  );
};

export default UserInfo;
