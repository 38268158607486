import { SVGAttributes } from "react";

export const CopyIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 9.16663C5 6.8096 5 5.63109 5.73223 4.89886C6.46447 4.16663 7.64298 4.16663 10 4.16663H12.5C14.857 4.16663 16.0355 4.16663 16.7678 4.89886C17.5 5.63109 17.5 6.8096 17.5 9.16663V13.3333C17.5 15.6903 17.5 16.8688 16.7678 17.6011C16.0355 18.3333 14.857 18.3333 12.5 18.3333H10C7.64298 18.3333 6.46447 18.3333 5.73223 17.6011C5 16.8688 5 15.6903 5 13.3333V9.16663Z"
        stroke="currentColor"/>
      <path
        d="M5 15.8333C3.61929 15.8333 2.5 14.714 2.5 13.3333V8.33329C2.5 5.1906 2.5 3.61925 3.47631 2.64294C4.45262 1.66663 6.02397 1.66663 9.16667 1.66663H12.5C13.8807 1.66663 15 2.78591 15 4.16663"
        stroke="currentColor"/>
    </svg>
  );
};
