import { CSSProperties } from "react";
import bg from "../../assets/images/DashboardBG.png";
import TechnicalWorksImage from "../../assets/images/TechnicalWorksImage.png";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { getFontClass } from "../../utils/getFontClass";

interface CustomStyle extends CSSProperties {
  "--image-url"?: string;
}

const style: CustomStyle = {
  "--image-url": `url(${bg})`,
};

export const TechnicalWorks = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      style={style}
      className="flex flex-col items-center justify-center text-center text-white h-[100vh] p-9 bg-fixed before:content-[''] before:bg-[image:var(--image-url)] before:bg-cover before:bg-center before:bg-[#090a17] before:opacity-70 before:absolute before:inset-0 before:z-[-1]"
    >
      <img
        src={TechnicalWorksImage}
        alt=""
        className="mb-8 w-full h-full object-contain max-h-[432px] max-w-[432px]"
      />
      <h1
        className={cn(
          "leading-7 mb-[24px]",
          getFontClass(i18n.language)
        )}
      >
        {t("technical.title")}
      </h1>
      <p className="text-lg opacity-60 font-ptmono text-[24px]">
        {t("technical.description")}
      </p>
    </div>
  );
};
