import React from 'react';
import {Logo} from "../../Logo/Logo";
import {LanguageDropdown} from "../../../components/LanguageDropdown";
import './NotAuthAsideHeader.scss';

export const NotAuthAsideHeader = () => {
  return (
    <div className="not-auth-aside-header">
      <Logo className="not-auth-aside-header__logo" />
      <LanguageDropdown className="not-auth-aside-header__language-dropdown" />
    </div>
  );
};
