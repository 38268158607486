import { Dispatch, SetStateAction } from "react";
import { Button } from "../../../../components/Buttons/Button";
import { IPet } from "../../types";
import { ChooseBotButton } from "../Buttons/ChooseBotButton";
import { LvlUpButton } from "../Buttons/LvlUpButton";
import styles from "./SelectedPetInfo.module.scss"
import { useTranslation } from "react-i18next";
import { usePetsStore } from "../../store/petsStore";
import { petsService } from "../../service/service";


type ISelectedPetInfoButtonsProps = {
  loading: boolean
  currentPet: IPet | null
  isNotEnoughPointsToBuy: boolean
  isNotEnoughLevel: boolean
  isBought: boolean
  isPetChoosen: boolean
  isAbleToLvlUp: boolean
  openProofModal: () => void
  setCurrentPet: Dispatch<SetStateAction<IPet | null>>
}

export const SelectedPetInfoButtons: React.FC<ISelectedPetInfoButtonsProps> = ({
  currentPet,
  isAbleToLvlUp,
  isBought,
  isNotEnoughLevel,
  isNotEnoughPointsToBuy,
  isPetChoosen,
  openProofModal,
  setCurrentPet,
  loading,
}) => {
  const { t } = useTranslation()
  const { selectedPet } = usePetsStore()

  return <div className={styles.petInfoBtns}>
    <Button
      className={styles.petInfoBtn}
      decoration="outlined"
      variant="secondary"
      disabled={
        loading ||
        !selectedPet ||
        isNotEnoughPointsToBuy ||
        isNotEnoughLevel
      }
      onClick={openProofModal}
      size="large"
      isUppercased
    >
      {isBought ?
        t("buttons.pet-sell", {
          points: currentPet?.userPet?.sellPrice,
        }) :
        t("buttons.buy")
      }
    </Button>
    {isPetChoosen ? (
      isAbleToLvlUp ? (
        <Button className={styles.petInfoBtn} size="large" isUppercased>
          {t("buttons.maxLvl")}
        </Button>
      ) : (
        selectedPet && currentPet && <LvlUpButton
          onClick={() => petsService.setIsProgressionModalOpened(false)}
          isBought={isBought}
          loading={loading}
          selectedPet={selectedPet}
          currentPet={currentPet}
        />
      )
    ) : (
      <ChooseBotButton
        onClick={() => {
          setCurrentPet(selectedPet);
        }
        }
        disabled={selectedPet?.userPet?.id === currentPet?.userPet?.id || !isBought || loading}
      />
    )}
    {/* {errorMessage && (
        <div className="text-xs leading-[30px] text-red-error mt-2">
          {errorMessage}
        </div>
      )} */}
  </div>
}