export interface IUserPet {
  id: string;
  customName: string;
  pet: string;
  userId: string;
  petId: string;
  createdAt: string;
  level: number;
  levelUpPrice: number;
  levelUppedAt: string;
  sellPrice: number;
  reward: number;
  upgradePrice: number;
  nextLevelPet: {
    reward: number;
  };
}

export enum PetRace {
  CACTUSES = "CACTUSES",
  FLIES = "FLIES",
}

export interface IPet {
  id: string;
  name: string;
  userPet?: IUserPet;
  description: string;
  price: number;
  multiplier: number;
  imageUrl: string;
  requiredLevel: number;
  rarity: number;
  race: PetRace;
  descriptionLocalizationKey: string;
  nameLocalizationKey: string;
}

export interface IBuyPetResponse {
  id: string;
  customName: string;
  pet: {
    id: string;
    name: string;
    nameLocalizationKey: string;
    descriptionLocalizationKey: string;
    userPet: string;
    description: string;
    price: number;
    multiplier: number;
    requiredLevel: number;
    imageUrl: string;
  };
  userId: string;
  petId: string;
  createdAt: string;
}

export interface IReward {
  id: string;
  dailyUserActions: {
    id: string;
    daily_action: {
      id: string;
      name: string;
      reward: number;
      order: number;
    };
    daily_action_id: string;
    streak_id: string;
    created_at: string;
  }[];
  nextClaimAfter: string;
  status: "IN_PROGRESS" | "DONE";
  currentStreak: number;
  startedAt: string;
}

export interface IRewardAction {
  id: string;
  name: string;
  reward: number;
  order: number;
  iconUrl: string;
  nameLocalizationKey: string;
}

export type PetBuyInfo = {
  petName: string;
  petPrice: number;
}

export type PetSellInfo = {
  name: string;
  imageUrl: string;
  sellPrice: number;
}

export interface IPetsStore {
  loading: boolean;
  claiming: boolean;
  changingPet: boolean;
  pets: IPet[];
  reward: IReward | undefined;
  globalStreak: number;
  rewardAction: IRewardAction[];
  levelUpError: string;
  isLevelUpLoading: boolean;
  evolveError: string;
  isPetInfoModalOpened: boolean;

  isProgressionModalOpened: boolean;
  isEvolutionModalOpened: boolean;
  isPetsModalOpen: boolean;
  selectedPet: IPet | null;
  buyPetInfo: PetBuyInfo | null;
  sellPetInfo: PetSellInfo | null;
  sellError: string
  buyError: string
}

export interface IEditAvatarPayload {
  updateProfileOptions: {
    username: string;
    biography: string;
    telegram?: string | null;
    twitter?: string | null;
    discord?: string | null;
  };
  updateUserOptions: {
    email: string;
    isKol: boolean;
    role?: "USER";
  };
}
