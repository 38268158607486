import { IIconProps } from "./types"


export const CheckIconSmall = ({ iconColor = "#FFFFFF", ...props }: IIconProps) => {

  return (
    <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2129_37597)">
        <path d="M0.556641 4.84303L1.91869 5.86467C2.00563 5.92986 2.11439 5.95893 2.22226 5.94582C2.33013 5.93271 2.42876 5.87843 2.49755 5.79431L6.03349 1.47266" stroke={iconColor} strokeLinecap="round" />
      </g>
    </svg>
  );
}
