import { IIconProps } from "./types"

export const ClockIcon = ({ ...props }: IIconProps) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 8.16699V12.0003L14.3958 14.3962" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.87064 5.87063L5.34031 5.3403L5.3403 5.3403L5.87064 5.87063ZM4.65702 7.08425L3.90703 7.08802C3.9091 7.49929 4.24198 7.83217 4.65325 7.83424L4.65702 7.08425ZM7.09274 7.8465C7.50695 7.84858 7.84442 7.51449 7.8465 7.10028C7.84858 6.68607 7.51449 6.3486 7.10028 6.34652L7.09274 7.8465ZM5.39475 4.64099C5.39267 4.22678 5.0552 3.89269 4.64099 3.89477C4.22678 3.89685 3.89269 4.23432 3.89477 4.64853L5.39475 4.64099ZM4.19813 10.8398C4.25438 10.4294 3.9673 10.0511 3.55692 9.99489C3.14655 9.93864 2.76827 10.2257 2.71202 10.6361L4.19813 10.8398ZM18.5984 5.4016C14.9254 1.72859 8.98951 1.6911 5.34031 5.3403L6.40097 6.40096C9.45436 3.34757 14.4404 3.36496 17.5377 6.46226L18.5984 5.4016ZM5.4016 18.5984C9.07461 22.2714 15.0105 22.3089 18.6597 18.6597L17.599 17.599C14.5456 20.6524 9.55956 20.635 6.46226 17.5377L5.4016 18.5984ZM18.6597 18.6597C22.3089 15.0105 22.2714 9.07461 18.5984 5.4016L17.5377 6.46226C20.635 9.55956 20.6524 14.5456 17.599 17.599L18.6597 18.6597ZM5.3403 5.3403L4.12669 6.55392L5.18735 7.61458L6.40097 6.40096L5.3403 5.3403ZM4.65325 7.83424L7.09274 7.8465L7.10028 6.34652L4.66079 6.33426L4.65325 7.83424ZM5.40701 7.08048L5.39475 4.64099L3.89477 4.64853L3.90703 7.08802L5.40701 7.08048ZM2.71202 10.6361C2.32603 13.4521 3.22492 16.4217 5.4016 18.5984L6.46226 17.5377C4.62886 15.7043 3.8736 13.2074 4.19813 10.8398L2.71202 10.6361Z" fill="white" />
    </svg>

  );
}
