import { BaseService } from "../../../services/BaseService";
import { $api } from "../../../services/interceptor";
import { UserData, UserReaction } from "../../../services/user/types";
import { IUserProfileStore, userProfileStore } from "../store/store";

export interface TelegramAuthData {
  id: number;
  first_name: string;
  auth_date: number;
  hash: string;
  last_name?: string;
  photo_url?: string;
  username?: string;
}

class UserProfileAPI extends BaseService<IUserProfileStore> {
  constructor() {
    super(userProfileStore);
  }

  async getUserData(userID: string, successCallback?: () => void) {
    try {
      this.setState({ loading: true });
      const response = await $api.get<UserData>(`/users/${userID}`);

      this.setState({
        user: response.data,
      });

      successCallback && successCallback();
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  async getUserReactions(userId: string) {
    try {
      this.setState({ reactionsLoading: true });
      const response = await $api.get<Array<UserReaction>>(
        `/users/${userId}/reactions`
      );

      this.setState({ reactions: response.data });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ reactionsLoading: false });
    }
  }

  async toggleUserReaction({
    userId,
    emojiId,
  }: {
    userId: string;
    emojiId: string;
  }) {
    try {
      await $api.post(`/users/${userId}/reactions/${emojiId}/toggle`);

      this.setState((currentState) => {
        const newReactions = currentState.reactions
          ? [...currentState.reactions]
          : [];

        const reactionIndex = newReactions.findIndex(
          (reaction) => reaction.id === emojiId
        );
        if (reactionIndex !== -1) {
          const currentReaction = newReactions[reactionIndex];
          currentReaction.reacted = !currentReaction.reacted;
          currentReaction.reactionsCount += currentReaction.reacted ? 1 : -1;
        }

        return { ...currentState, reactions: newReactions };
      });
    } catch (error) {
      console.log(error);
    }
  }

  clear = () => {
    this.setState({
      user: null,
      loading: false,
      reactions: [],
    });
  };

  setSelectedAvatarId = (id: string) => {
    this.setState({
      selectedAvatarId: id
    })
  }

  setSelectedAvatarToEditId = (id: string | undefined) => {
    this.setState({
      selectedAvatarToEditId: id || ""
    })
  }

  setIsEditAvatar = (state: boolean) => {
    this.setState({
      isEditAvatar: state
    })
  }
}

export const userProfileService = new UserProfileAPI();
