import pixIcon from "../../../../../assets/icons/PIXcoin.png"
import styles from "./PriceLabel.module.scss"

type IPriceLabelProps = {
  amount: string
}

export const PriceLabel: React.FC<IPriceLabelProps> = ({ amount }) => {
  return <div className={styles.container}>
    <img src={pixIcon} alt="pix-icon" />
    <span>{amount} PIX</span>
  </div>
}