import { useEffect, useState } from "react";
import consts from "../styles/constants.module.scss"

const breakpoint = parseInt(consts.sidebarBeakpoint)

export const useIsSidebarStatic = () => {
  const [isSidebar, setIsSidebar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= breakpoint ? setIsSidebar(true) : setIsSidebar(false);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isSidebar;
};