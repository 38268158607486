import React, {FC, PropsWithChildren} from 'react';
import './NotAuthLayout.scss';
import {Card} from '../../newComponents/Card/Card';

export const NotAuthLayoutAside: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Card className="not-auth-layout-aside">
      {children}
    </Card>
  );
};
