import {UserData} from "../../../../../services/user/types";
import {roundNumber} from "../../../../../utils/roundNumber";

export const getUserProgress = (user: UserData | null) => {
  const currentXP = user?.levelData.progress || 0;
  const currentLevelPoints = user?.levelData.currentLevel?.pointsToReach || 0;
  const nextLevelPoints = user?.levelData.nextLevelPoints?.pointsToReach || 0;

  const progressInCurrentLevel =
    ((currentXP - currentLevelPoints) / (nextLevelPoints - currentLevelPoints)) * 100;

  return roundNumber(progressInCurrentLevel);
};
