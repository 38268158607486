import { useTranslation } from "react-i18next"
import { Button } from "../../../../../../components/Buttons/Button"
import { AccountCard } from "./AccountCard"
import css from "./ChooseAccountStep.module.scss"
import accountLinkingService from "../../../../../login/services/accountLinkingService"
import { useUserStore } from "../../../../../../services/user/store"
import { useMemo } from "react"
import { useAccountLinkingStore } from "../../../../../login/store/accountLinkingStore"
import { transformNumber } from "../../../../../../utils/transformNumber"

export const ChooseAccountStep = () => {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const { accountToLinking, selectedAccountToLink } = useAccountLinkingStore()

  const profiles = useMemo(() => {
    const telegramAccount = {
      id: "telegram",
      selected: selectedAccountToLink === "telegram",
      name: user?.profile.username,
      level: user?.levelData.currentLevel?.value,
      accountSocial: `@${(user?.connectedSocials.find(social => social.social.name === "Telegram")?.socialData.username || "noname")}`,
      pixAmount: transformNumber(user?.spendablePoints?.amount || 0),
      avatarUrl: user?.avatar?.avatar?.imageUrl || undefined,
      onSelect: () => accountLinkingService.setSelectedAccountToLink(selectedAccountToLink === "telegram" ? null : "telegram")
    }
    const accountToLink = accountToLinking !== null && "availableAccount" in accountToLinking ?
      accountToLinking?.availableAccount : { email: "", spendablePointsAmount: 0, username: "", level: 0, avatarUrl: "" }
    return [
      telegramAccount,
      ...(accountToLink ? [{
        id: "email",
        selected: selectedAccountToLink === "email",
        name: accountToLink?.username || undefined,
        level: accountToLink.level || 0,
        accountSocial: accountToLink?.email || undefined,
        pixAmount: transformNumber(accountToLink?.spendablePointsAmount || 0),
        avatarUrl: accountToLink.avatarUrl || undefined,
        onSelect: () => accountLinkingService.setSelectedAccountToLink(selectedAccountToLink === "email" ? null : "email")
      }] : [])
    ]
  }, [user, selectedAccountToLink, accountToLinking])


  return <div className={css.chooseAccountToLink}>
    <div className={css.avatars}>
      {profiles.map(a => <AccountCard
        key={a.id}
        {...a}
      />)}
    </div>
    <div className={css.buttons}>
      <Button
        onClick={accountLinkingService.backFromExistAccountOtp}
        size="large"
        isUppercased
      >{t("auth.linkAccount.step2.button.back")}</Button>
      <Button
        onClick={accountLinkingService.linkExistingAccountToStepConfirmation}
        variant="secondary"
        size="large"
        isUppercased
      >{t("auth.modal.accountCreation.exist.choose.button")}</Button>
    </div>
  </div>
}