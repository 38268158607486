import { $api } from "../../../services/interceptor";
import { ITasksStore, tasksStore } from "../store/tasksStore";
import { BaseService } from "../../../services/BaseService";
import {
  IRawTask,
  IRawUserTask,
  IRawUserTasks,
  ITask,
  ITaskPayload,
  RawTaskType,
  TabsEnum,
  TaskType,
  TwitterTaskType,
  // ITask
} from "../types";
import { handleExternalLinkOpen } from "../../../utils/handleExternalLinkOpen";
import { getSocial } from "../../../utils/getSocial";
import { SocialName } from "../../../services/user/types";
import { retryTimersService } from "./retryTimersService";
import { walletService } from "../../../components/WalletSection/service";

// function cloneObject(obj: ITask, count: number): ITask[] {
//   const result = [];
//   for (let i = 0; i < count; i++) {
//     result.push(JSON.parse(JSON.stringify(obj)));
//   }
//   return result;
// }

export const taskHardcodes = [
  "tasks.individual.twitter.trust.sub.title",
  "tasks.individual.twitter.trust.like.title",
  "tasks.individual.twitter.trust.retweet.title",
  "tasks.individual.screenshot.OKX.title",
];

type Comparator<T> = (item: T) => string;

function moveObjectsForward<T>(
  arr: T[],
  order: string[],
  comparator: Comparator<T>
): T[] {
  const orderElements: T[] = [];
  const restElements: T[] = [];

  arr.forEach((value) => {
    if (order.includes(comparator(value))) {
      orderElements.push(value);
    } else {
      restElements.push(value);
    }
  });

  orderElements.sort(
    (a, b) => order.indexOf(comparator(a)) - order.indexOf(comparator(b))
  );

  return [...orderElements, ...restElements];
}

const tasksColors: Record<RawTaskType, string> = {
  [TaskType.telegram]: "#1DA0F1",
  [TaskType.twitter]: "#1DA0F1",
  [TaskType.referral]: "",
  [TaskType.discord]: "",
  [TaskType.snapshot]: "#EEA12B",
  [RawTaskType.registerWithTelegramTask]: "#1DA0F1",
};

const mapRawUserTasks = (
  rawUserTasks: Array<IRawUserTask>,
  progress: number
): Array<ITask> => {
  if (!rawUserTasks.length) {
    return [];
  }

  return rawUserTasks.map(
    ({
      id,
      title,
      description,
      redirectUrl,
      type,
      userTaskId,
      twitterTask,
      rewardPointsAmount,
      userSnapshotResult,
    }) => ({
      id,
      title: title,
      description: description,
      currentProgress: progress,
      maxProgress: 100,
      isTaskDone: (progress >= 100),
      color: tasksColors[type],
      iconURL: "",
      payload: {
        link: redirectUrl,
        userTaskID: userTaskId,
        twitterTaskInfo: twitterTask,
      },
      type: type as unknown as TaskType,
      pointsReward: rewardPointsAmount,
      userSnapshotResult,
    })
  );
};

export const baseTwitterAdress = "https://twitter.com/intent";
const handleTwitterTaskClick = (
  taskType: TwitterTaskType,
  resourceId: string
) => {
  switch (taskType) {
    case TwitterTaskType.SUBSCRIBE:
      handleExternalLinkOpen(`${baseTwitterAdress}/user?user_id=${resourceId}`);
      break;
    case TwitterTaskType.RETWEET_POST:
      handleExternalLinkOpen(
        `${baseTwitterAdress}/retweet?tweet_id=${resourceId}`
      );
      break;
    case TwitterTaskType.LIKE_POST:
      handleExternalLinkOpen(
        `${baseTwitterAdress}/like?tweet_id=${resourceId}`
      );
      break;

    default:
      return;
  }
};

class TasksAPI extends BaseService<ITasksStore> {
  constructor() {
    super(tasksStore);
  }

  setCurrentTab(tab: TabsEnum) {
    this.setState({ currentTab: tab });
  }

  setOpenedTask(task: string) {
    const openedTask = this.getState().openedTask;
    if (openedTask === task) {
      this.setState({ openedTask: null });
    } else {
      this.setState({ openedTask: task });
    }
  }

  getGeneralTasks = async () => {
    this.setState({ isGeneralTasksLoading: true });

    try {
      const response = await $api.get<Array<IRawTask>>("/global-tasks");

      this.setState({
        generalTasks: response.data.map(
          ({
            id,
            target,
            progress,
            descriptionLocalizationKey,
            titleLocalizationKey,
            color,
            iconUrl,
            payload,
            userSnapshotResult,
          }) => ({
            id,
            title: titleLocalizationKey,
            description: descriptionLocalizationKey,
            currentProgress: progress,
            maxProgress: target,
            isTaskDone: (progress >= target),
            color,
            iconURL: iconUrl,
            payload,
            type: TaskType.general,
            userSnapshotResult,
          })
        ),
      });
    } catch (error) {}
    this.setState({ isGeneralTasksLoading: false });
  };

  getUserTasks = async () => {
    this.setState({ isUserTasksLoading: true });

    try {
      const response = await $api.get<IRawUserTasks>("/tasks/my");

      const sortedAvailableTasks = moveObjectsForward(
        response.data.available,
        taskHardcodes,
        ({ title }) => title
      );

      const userTasks = mapRawUserTasks(sortedAvailableTasks, 0)
        .concat(mapRawUserTasks(response.data.inProgress, 50))
        .concat(mapRawUserTasks(response.data.done, 100));

      this.setState({
        userTasks,
      });
    } catch (error) {}
    this.setState({ isUserTasksLoading: false });
  };

  startTask = async (taskID: string) => {
    try {
      await $api.post(`/tasks/start/${taskID}`);
    } catch (e) {
      console.log(e);
    }
  };

  checkUserTask = async (userTaskID: string, isOkx?: boolean) => {
    try {
      retryTimersService.setTaskLoading(userTaskID);
      await $api.get(
        isOkx
          ? `/user-tasks/${userTaskID}/check-completed?wallet=okx`
          : `/user-tasks/${userTaskID}/check-completed`
      );
      await this.getUserTasks();
    } catch (e) {
      console.log(e);
    }
    retryTimersService.removeLoadingTask(userTaskID);
  };

  refreshTelegramTask = async (userTaskID: string) => {
    try {
      retryTimersService.setTaskLoading(userTaskID);
      await $api.post(`/telegram-tasks/subscribe/${userTaskID}/check`);
      await this.getUserTasks();
    } catch (e) {
      console.log(e);
    }
    retryTimersService.removeLoadingTask(userTaskID);
  };

  refreshRegisterWithTelegramTask = async (userTaskID: string) => {
    try {
      retryTimersService.setTaskLoading(userTaskID);
      await $api.post(`/register-with-telegram-tasks/${userTaskID}/check`);
      await this.getUserTasks();
    } catch (e) {
      console.log(e);
    }
    retryTimersService.removeLoadingTask(userTaskID);
  };

  handleTaskClick = async ({
    taskType,
    taskID,
    taskPayload,
  }: {
    taskType: TaskType;
    taskID: string;
    taskPayload?: ITaskPayload;
  }) => {
    const { userTasks, generalTasks } = tasksStore.getState();
    const currentTask =
      taskType === TaskType.general
        ? generalTasks.find(({ id }) => id === taskID)! || {}
        : userTasks.find(({ id }) => id === taskID)! || {};

    switch (taskType) {
      case TaskType.twitter:
        if (
          currentTask.payload?.twitterTaskInfo?.type &&
          currentTask.payload?.twitterTaskInfo?.twitterResourceId
        ) {
          handleTwitterTaskClick(
            currentTask.payload?.twitterTaskInfo?.type,
            currentTask.payload?.twitterTaskInfo?.twitterResourceId
          );
        }

        if (currentTask.currentProgress === 0) {
          await this.startTask(taskID);
          this.getUserTasks();
        }
        return;

      case TaskType.telegram:
        handleExternalLinkOpen(taskPayload?.link || "");

        if (currentTask.currentProgress === 0) {
          await this.startTask(taskID);
          this.getUserTasks();
        }
        return;

      case TaskType.snapshot:
        handleExternalLinkOpen(taskPayload?.link || "");

        if (currentTask.currentProgress === 0) {
          await this.startTask(taskID);
          this.getUserTasks();
        }
        return;

      case TaskType.registerWithTelegramTask:
        handleExternalLinkOpen(taskPayload?.link || "");

        if (currentTask.currentProgress === 0) {
          await this.startTask(taskID);
          this.getUserTasks();
        }
        return;
      case TaskType.connectBybitWallet:
        if (currentTask.currentProgress === 0) {
          await this.startTask(taskID);
          this.getUserTasks();
        }
        walletService.openWalletModal();
        return;
      case TaskType.connectOkxWallet:
        if (currentTask.currentProgress === 0) {
          await this.startTask(taskID);
          this.getUserTasks();
        }
        walletService.openWalletModal();
        return;

      default:
        break;
    }
  };

  get isTwitterTaskAvailable() {
    const isUserTwitterConnected = !!getSocial(SocialName.Twitter);
    return isUserTwitterConnected;
  }
  get isTelegramTaskAvailable() {
    const isUserTelegramConnected = !!getSocial(SocialName.Telegram);
    return isUserTelegramConnected;
  }

  getUserTaskProps = (
    taskType: TaskType,
    taskID: string
  ): {
    disabled?: boolean;
    disabledTooltip?: string;
    actionHidden?: boolean;
    actionTitle?: string;
    refreshAction?: () => void;
  } => {
    const { userTasks, generalTasks } = tasksStore.getState();
    const currentTask =
      taskType === TaskType.general
        ? generalTasks.find(({ id }) => id === taskID)! || {}
        : userTasks.find(({ id }) => id === taskID)! || {};

    switch (taskType) {
      case TaskType.twitter:
        return this.isTwitterTaskAvailable
          ? {}
          : {
              disabled: true,
              disabledTooltip: "tasks.individual.twitter.disabled",
            };

      case TaskType.telegram:
        const telegramProps: Record<string, any> = {};

        if (
          currentTask.currentProgress > 0 &&
          currentTask.currentProgress < 100
        ) {
          telegramProps.refreshAction = () => {
            if (currentTask.payload?.userTaskID) {
              this.refreshTelegramTask(currentTask.payload?.userTaskID);
            }
          };
        }

        if (!this.isTelegramTaskAvailable) {
          telegramProps.disabled = true;
          telegramProps.disabledTooltip = "tasks.individual.telegram.disabled";
        }

        return telegramProps;

      case TaskType.registerWithTelegramTask:
        const RegisterTelegramProps: Record<string, any> = {};

        if (
          currentTask.currentProgress > 0 &&
          currentTask.currentProgress < 100
        ) {
          RegisterTelegramProps.refreshAction = () => {
            if (currentTask.payload?.userTaskID) {
              this.refreshRegisterWithTelegramTask(
                currentTask.payload?.userTaskID
              );
            }
          };
        }

        if (!this.isTelegramTaskAvailable) {
          RegisterTelegramProps.disabled = true;
          RegisterTelegramProps.disabledTooltip =
            "tasks.individual.telegram.disabled";
        }

        return RegisterTelegramProps;

      case TaskType.connectBybitWallet:
        const props: Record<string, any> = {};

        if (currentTask.currentProgress < 100) {
          props.actionTitle = "tasks.individual.connectWallet.connect";
        }

        if (currentTask.currentProgress >= 100) {
          props.actionHidden = true;
        }

        if (
          currentTask.payload?.userTaskID &&
          currentTask.currentProgress < 100
        ) {
          const userID = currentTask.payload?.userTaskID;

          props.refreshAction = () => {
            this.checkUserTask(userID);
          };
        }

        return props;
      case TaskType.connectOkxWallet:
        const okxProps: Record<string, any> = {};

        if (currentTask.currentProgress < 100) {
          okxProps.actionTitle = "tasks.individual.connectWallet.connect";
        }

        if (currentTask.currentProgress >= 100) {
          okxProps.actionHidden = true;
        }

        if (
          currentTask.payload?.userTaskID &&
          currentTask.currentProgress < 100
        ) {
          const userID = currentTask.payload?.userTaskID;

          okxProps.refreshAction = () => {
            this.checkUserTask(userID, true);
          };
        }

        return okxProps;
      default:
        return {};
    }
  };

  uploadSnapshot = async (formData: FormData, taskId: string) => {
    this.setState({ isSnapshotUploading: true });

    const file = formData.get("file");

    if (file && file instanceof Blob) {
      const newFormData = new FormData();
      newFormData.append("files", file, (file as File).name);

      try {
        const uploadResponse = await $api.post<
          [
            {
              imageUrl: string;
              imageName: string;
            },
          ]
        >("/files/upload", newFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const imageUrl = uploadResponse.data[0].imageUrl;

        await $api.post<
          [
            {
              imageUrl: string;
              imageName: string;
            },
          ]
        >("/snapshot-tasks/add-snapshots", {
          urls: [imageUrl],
          taskId: taskId,
        });

        this.getUserTasks();
      } catch (error) {
      } finally {
        this.setState({ isSnapshotUploading: false });
      }
    } else {
      this.setState({ isSnapshotUploading: false });
    }
  };
}

const tasksService = new TasksAPI();
export default tasksService;
