import React from 'react';

export const IconGoogle = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 9.21609C18.5 8.60825 18.44 7.98081 18.34 7.39258H9.67749V10.8631H14.6389C14.4388 11.9808 13.7787 12.9611 12.7984 13.5886L15.7592 15.8435C17.4997 14.2552 18.5 11.9415 18.5 9.21609Z"
        fill="currentColor"/>
      <path
        d="M9.67768 17.9994C12.1584 17.9994 14.239 17.1955 15.7594 15.823L12.7986 13.5877C11.9783 14.1367 10.918 14.4505 9.67768 14.4505C7.277 14.4505 5.25642 12.8623 4.51621 10.7446L1.47534 13.0387C3.03579 16.0779 6.19669 17.9994 9.67768 17.9994Z"
        fill="currentColor"/>
      <path
        d="M4.51615 10.7261C4.13604 9.60843 4.13604 8.39276 4.51615 7.27512L1.47528 4.96143C0.174907 7.51042 0.174907 10.5104 1.47528 13.0398L4.51615 10.7261Z"
        fill="currentColor"/>
      <path
        d="M9.67768 3.56893C10.9781 3.54932 12.2584 4.03951 13.1987 4.92185L15.8194 2.33365C14.159 0.804254 11.9583 -0.0192654 9.67768 0.000342209C6.19669 0.000342209 3.03579 1.92189 1.47534 4.96107L4.51621 7.27477C5.25642 5.13754 7.277 3.56893 9.67768 3.56893Z"
        fill="currentColor"/>
    </svg>
  );
};
