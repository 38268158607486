import { create, useStore } from "zustand";
import { IPetsStore } from "../types";

export const petsStore = create<IPetsStore>(() => ({
  pets: [],
  changingPet: false,
  loading: false,
  claiming: false,
  reward: undefined,
  globalStreak: 0,
  rewardAction: [],
  levelUpError: "",
  isLevelUpLoading: false,
  evolveError: "",

  isPetInfoModalOpened: false,

  isProgressionModalOpened: false,
  isEvolutionModalOpened: false,
  isPetsModalOpen: false,
  selectedPet: null,
  buyPetInfo: null,
  sellPetInfo: null,
  sellError: "",
  buyError: "",
}));

export const usePetsStore = () => useStore(petsStore);
