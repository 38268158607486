import axios from "axios";
import { AUTH_API_URL } from "./interceptor";
import { AuthAccess } from "../types/auth";
import { AppRoutes } from "../constants/routes";

class AuthAPI {
  isPending = false
  async sendRefreshRequest() {
    try {
      const storageAccessToken = localStorage.getItem("accessToken");
      this.isPending = true
      const resp = await axios.post<AuthAccess>(
        `${AUTH_API_URL}/auth/refresh`,
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `${storageAccessToken!}`,
          },
        }
      );
      const accessToken: string = resp.data.access;
      localStorage.setItem("accessToken", accessToken);
      return true;
    } catch (error) {
      const path = window.location.pathname;
      if (path !== AppRoutes.staking) {
        localStorage.removeItem("accessToken");
        window.location.replace("/#/");
      }

      return false;
    } finally {
      this.isPending = false
    }
  }
}

const authService = new AuthAPI();
export default authService;
