import {useTranslation} from "react-i18next";
import "./styles/ReferralsTable.scss";
import {TableHeader} from "./components/TableHeader";
import {TableRow} from "./components/TableRow";
import {SortQuery} from "../../referrals.types";
import cn from "classnames";
import React from "react";
import {Loader} from "../../../../components/Loader";
import bgEmpty from '../../../../assets/images/referrals/rt-empty.png';


export function ReferralsTable({
   sortQuery,
   referrals,
   isLoading,
   className
}: {
  sortQuery?: SortQuery,
  referrals: Array<any>,
  className?: string,
  isLoading?: boolean,
}) {
  const {t} = useTranslation();

  const isReferralsEmpty = !referrals.length;

  return (
    <div className={cn(
      "referral-table",
      isReferralsEmpty && 'referral-table--empty',
      className
    )}>
      <TableHeader sortQuery={sortQuery}/>

      <div className={cn('referral-table__inner', isReferralsEmpty && 'referral-table__inner--empty')}>
        {isLoading && <Loader className="referral-table__loader"/>}

        {!isLoading && (<>
          {referrals.map(
            (
              {
                id,
                username,
                pointsEarnedToReferrer,
                iconUrl,
                createdAt,
              },
              i
            ) => (
              <TableRow
                className="referral-table__row"
                id={id}
                points={pointsEarnedToReferrer}
                key={i}
                username={username}
                iconUrl={iconUrl}
                createdAt={createdAt}
              />
            )
          )}

          {isReferralsEmpty && (
            <div className="referral-table__empty">
              <img src={bgEmpty} alt="Empty referrals" loading="lazy" />
              <p className="referral-table__empty-text">{t("referrals.table.empty")}</p>
            </div>
          )}
          </>
        )}
      </div>
    </div>
  );
}
