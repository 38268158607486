import { MONTHS } from "../constants/time";

export const formatDate = (dateISO: string) => {
  const date = new Date(dateISO);
  return `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;
};;

const now = new Date();
export const utc_timestamp = Date.UTC(
  now.getUTCFullYear(),
  now.getUTCMonth(),
  now.getUTCDate(),
  now.getUTCHours(),
  now.getUTCMinutes(),
  now.getUTCSeconds(),
  now.getUTCMilliseconds(),
);

export function getDMY(date: Date): string {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Місяці починаються з 0
  const year = date.getFullYear().toString();

  return `${day}.${month}.${year}`;
}

export const timer = (
  endDate: Date,
  returnAsObj = false,
): string | { label: string; value: string }[] => {
  const timeRemainingSeconds = Math.max(
    (endDate.getTime() - new Date().getTime()) / 1000,
    0,
  );
  const days = Math.floor(timeRemainingSeconds / 86400)
    .toString()
    .padStart(2, "0");
  const hours = Math.floor((timeRemainingSeconds % 86400) / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeRemainingSeconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  if (!returnAsObj) {
    return `${days}d:${hours}h:${minutes}m`;
  } else {
    return [
      { label: "d", value: days },
      { label: "h", value: hours },
      { label: "m", value: minutes },
    ];
  }
};

export function getUTCDate(date: Date): string {
  const day = date.getUTCDate();
  const month = MONTHS[date.getUTCMonth()];
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${hours}:${minutes} UTC`;
}
