import { create, useStore } from "zustand";
import { UserData, UserReaction } from "../../../services/user/types";

export interface IUserProfileStore {
  user: UserData | null;
  loading: boolean;
  reactions: Array<UserReaction>;
  reactionsLoading: boolean;

  isEditAvatar: boolean
  selectedAvatarId: string | undefined
  selectedAvatarToEditId: string | undefined
}

export const userProfileStore = create<IUserProfileStore>(() => ({
  user: null,
  loading: false,
  reactions: [],
  reactionsLoading: false,

  isEditAvatar: false,
  selectedAvatarId: undefined,
  selectedAvatarToEditId: undefined
}));

export const useUserProfileStore = () => useStore(userProfileStore);
