import { RoutesParams } from "../constants/routes";

export const createRoutingLink = (
  path: string,
  params: Record<RoutesParams, string | undefined>
) => {
  const paramsEntries = Object.entries(params);

  return paramsEntries.reduce((resultPath, [key, value]) => {
    return resultPath.replace(":" + key, value || "");
  }, path);
};
