import { useEffect, useState } from "react";
import { mobileBreakPoint } from "../constants/breakpoints";

export const useIsMobileResolution = (breakPoint?: number) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= (breakPoint || mobileBreakPoint) ? setIsMobile(true) : setIsMobile(false);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobile;
};
