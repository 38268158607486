import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import { Loader } from "../../Loader";
import styles from "./button.module.scss"
import classNames from "classnames";
import { NavLink, To } from "react-router-dom";

type IButtonLinkProps = {
  to: To,
  isUppercased?: boolean
  isLoading?: boolean
  variant?: "primary" | "secondary" | "green"
  className?: string
  size?: "regular" | "large"
  decoration?: "filled" | "outlined"
  decorationBefore?: JSX.Element
  decorationAfter?: JSX.Element
}

export const ButtonLink: React.FC<IButtonLinkProps & PropsWithChildren & ButtonHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  to,
  className,
  isLoading = false,
  isUppercased = false,
  variant = "primary",
  size = "regular",
  decoration = "filled",
  decorationBefore,
  decorationAfter,
  ...props
}) => {

  return (

    <NavLink
      {...props}
      to={to}
      className={classNames(
        styles.button,
        isUppercased && styles.upper,
        styles[size],
        styles[variant],
        styles[decoration],
        className
      )}
      target="_blank"

    >
      {isLoading ? (
        <Loader color={variant !== "primary" ? "#FFFFFF" : undefined} />
      ) : (<>
        {decorationBefore || null}
        {children}
        {decorationAfter || null}
      </>
      )}
    </NavLink>
  );
}
