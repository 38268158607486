import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Convexity } from "../../../assets/iconComponents/Convexity";
import cupCracked from "../../../assets/icons/cupCracked.svg";
import cupTea from "../../../assets/icons/cupTea.svg";
import arrowDown from "../../../assets/icons/keyboardDoubleArroDown.svg";
import cupDefault from "../../../assets/icons/cupDefault.svg";
import styles from "../StakingPage.module.scss";
import { stakingStore } from "../store";
import { RaffleState } from "../types";
import { Question } from "../../../assets/iconComponents/Question";
import { stakingService } from "../service";
import { Button } from "../../../components/Buttons/Button";
import { useIsMobileResolution } from "../../../hooks/useIsMobileResolution";
import { ClockIcon } from "../../../assets/iconComponents/new_design_icons/ClockIcon";
import hourglassIcon from "../../../assets/icons/hourglass.svg";

const ruffleDict: Record<
  RaffleState | "waitingForResult",
  {
    iconUrl: string;
    text: string;
  }
> = {
  won: {
    iconUrl: cupDefault,
    text: "staking.ruffle.last.won",
  },
  lose: {
    iconUrl: cupCracked,
    text: "staking.ruffle.last.lose",
  },
  unavailable: {
    iconUrl: cupTea,
    text: "staking.ruffle.last.unavailable",
  },
  waitingForResult: {
    iconUrl: hourglassIcon,
    text: "staking.ruffle.last.waitingForResult"
  }
};

type IRaffleResultProps = {
  handleShowHistoryModal: () => void
}

export const RaffleResult: React.FC<IRaffleResultProps> = ({
  handleShowHistoryModal
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobileResolution()
  const { previousRaffleStatus, loading } = stakingStore();

  const isRaffleEndedCondition = !loading && false//isRaffleEnded

  return (
    <div className={styles.rufflePlaceholder}>
      <img src={arrowDown} alt="arrow-down" />
      <div className={classNames(styles.ruffleWrapper, isRaffleEndedCondition ? styles.waitingForResult : styles[previousRaffleStatus])}>
        <Convexity className={styles.ruffleConvexity} />

        <div className={styles.ruffleLeft}>
          <h3>{t("staking.lastRuffle")}</h3>
        </div>

        <div className={styles.delimiter} />

        <div className={styles.ruffleRight}>
          <div className={styles.ruffleInfo}>
            <div className={styles.ruffleIcon}>
              <img src={isRaffleEndedCondition ?
                ruffleDict.waitingForResult.iconUrl :
                ruffleDict[previousRaffleStatus].iconUrl
              }
                alt="ruffle-icon"
              />
            </div>
            <span>
              <Trans
                i18nKey={isRaffleEndedCondition ? ruffleDict.waitingForResult.text : ruffleDict[previousRaffleStatus].text}
                components={{
                  violet: <span className={styles.violet} />,
                  purple: <span className={styles.purple} />,
                  opacity: <span style={{ opacity: 0.4 }} />,
                  br: <br />,
                }}
              />
            </span>
          </div>
          {previousRaffleStatus === RaffleState.won && (
            <div className={styles.ruffleClaimButtonsContainer}>
              <Button
                variant="white"
                isUppercased
                disabled
              >
                {t("staking.ruffle.last.claim")}
              </Button>
              <Button
                onClick={handleShowHistoryModal}
                variant="white"
                isUppercased
                decoration="outlined"
                iconButton
              >
                <ClockIcon />
              </Button>
              <Button
                onClick={() => stakingService.setStakingInfoIsOpen(true)}
                variant="white"
                isUppercased
                decoration="outlined"
                iconButton
              >
                <Question isBlack={false} width={25} height={25} />
              </Button>
            </div>
          )}
          {(previousRaffleStatus === RaffleState.unavailable ||
            previousRaffleStatus === RaffleState.lose
          ) && (
              <div className={styles.butonsWrapper}>
                <Button
                  className={styles.rewardsButton}
                  variant="primary"
                  decorationColor={isMobile ? "purple" : "gray"}
                  onClick={() => stakingService.setStakingInfoIsOpen(true)}
                >
                  {t("staking.button.rewards")}
                </Button>
                <Button
                  onClick={handleShowHistoryModal}
                >
                  <span>{t("staking.button.history")}</span>
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
