import { PointActions } from "../services/actionPoints/types";
import { UserData } from "../services/user/types";

export const getIsActionPointsRecieved = (
  action: PointActions,
  user: UserData | null
) => {
  if (!user) {
    return true;
  }

  const doneActions = user.levelData.actionLevelPoints;

  return !!doneActions.find(({ action: actionName }) => actionName === action);
};
