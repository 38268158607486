import React, { FC, useEffect, useState } from "react";
import "./DropdownMenu.scss";
import cn from "classnames";
import { useWalletStore } from "../../../../WalletSection/store";
import { walletService } from "../../../../WalletSection/service";
import { actionPointsStore } from "../../../../../services/actionPoints/store";
import { userStore } from "../../../../../services/user/store";
import { useTranslation } from "react-i18next";
import { Connector, CreateConnectorFn, useAccount, useConnect, useConnectors, useDisconnect, useSignMessage } from "wagmi";
import { WalletProvider } from "../../../../../constants/walletProviders";
import { isMetamaskExtensionInstalled } from "../../../../../utils/metamask";
import { injected, walletConnect } from "wagmi/connectors";
import MetamaskIcon from "../../../../../assets/icons/metamask-icon.svg";
import WalletConnectIcon from "../../../../../assets/icons/wallet-connect.svg";
import BybitIcon from "../../../../../assets/icons/bybitIcon.svg";
import OKXIcon from "../../../../../assets/icons/okx-logo.svg";
import trustIcon from "../../../../../assets/icons/trustIcon.svg";
import { shortenAddress } from "../../../../../utils";
import { ArrowRight } from "../../../../../assets/iconComponents/ArrowRight";
import csss from "../../../../WalletSection/styles.module.scss";
import { WalletIcon } from "../../../../../assets/iconComponents/WalletIcon";
import { NewArrowIcon } from "../../../../../assets/iconComponents/NewArrowIcon";
import Button from "../Button/Button";
import { logoutService } from "../../../../../services/logoutService";
import { CopyIcon } from "../../../../../assets/iconComponents/CopyIcon";
import { ModalWithHeader } from "../../../../Modals/ModalWithHeader";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";

interface IDropdownMenuProps {
  className?: string;
}

const DropdownMenu: FC<IDropdownMenuProps> = ({ className }) => {
  const logout = logoutService;

  const { isWalletModalOpened } = useWalletStore();
  const { actionPoints } = actionPointsStore();
  const { user } = userStore();

  const { t } = useTranslation();
  const { connect } = useConnect();
  const connectors = useConnectors();
  const { disconnect } = useDisconnect();
  const { address } = useAccount();

  const [, setCopied] = useState<boolean>(false);

  const [provider, setProvider] = useState<WalletProvider>(WalletProvider.bybit);

  const [isWalletDetailsOpen, setIsWalletDetailsOpen] = useState<boolean>(false);
  const [isTonWalletDetailsOpen, setIsTonWalletDetailsOpen] = useState<boolean>(false);
  const friendlyTonAddress = useTonAddress();
  const [isConsciousChoice, setIsConsciousChoice] = useState(false);
  const [tonConnectUI] = useTonConnectUI();

  const { signMessage } = useSignMessage({
    mutation: {
      onSuccess: async (data: string) => {
        try {
          await walletService.connectWallet(address?.toString() || "", data, provider);
        } catch (error) {}
      },
      onSettled: () => {
        setIsConsciousChoice(false);
      },
    },
  });

  const copyAddress = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };

  const isMmInstalled = isMetamaskExtensionInstalled();
  const isBybitInstalled = !!window.bybitWallet;
  const isOKXInstalled = !!window.okxwallet;

  const handleConnectWalletClick = (item: CreateConnectorFn | Connector, i: number) => {
    let connector;
    if (i === 0 && !isMmInstalled) {
      window.open(`https://metamask.app.link/dapp/${window.location.host}`, "_self");
    } else if (i === 2 && !isBybitInstalled) {
      window.open("https://bit.ly/3WZhGAb", "_blank");
      window.bybitWallet?.enable();
    } else if (i === 3 && !isOKXInstalled) {
      const encodedUrl =
        "https://www.okx.com/download?deeplink=" +
        encodeURIComponent("okx://wallet/dapp/url?dappUrl=" + encodeURIComponent(window.location.href));

      window.location.href = encodedUrl;
    } else {
      if (item.name === "MetaMask") {
        setProvider(WalletProvider.metamask);

        connector =
          connectors.find((connector) => connector.id === "io.metamask") ||
          connectors.find((connector) => connector.id === "metaMask")!;
      } else if (item.name === "Bybit") {
        setProvider(WalletProvider.bybit);
        connector = injected({
          target: {
            id: "com.bybit",
            name: "Window Provider",
            // @ts-ignore
            provider: window.bybitWallet!,
          },
        });
      } else if (item.name === "OKX") {
        connector = connectors.find((connector) => connector.id === "com.okex.wallet");
        setProvider(WalletProvider.okx);
      } else if (item.name === "TRUST") {
        connector = connectors.find((connector) => connector.id === "com.trustwallet.app");
        setProvider(WalletProvider.trust);
      } else {
        connector = walletConnect({ projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "" });

        setProvider(WalletProvider.walletConnect);
      }

      connect({ connector: connector || item });
      setIsConsciousChoice(true);
    }
  };

  useEffect(() => {
    if (address && isConsciousChoice) {
      signMessage({
        message: t("wallet.warning"),
      });
      walletService.closeWalletModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const appConnectors = connectors.length
    ? [
        {
          ...connectors.find((connector) => connector.id === "io.metamask")!,
          icon: MetamaskIcon,
          name: "MetaMask",
          reward: actionPoints?.CONNECT_WALLET,
          showReward: user?.connectedWallets?.find((wallet) => wallet.provider === WalletProvider.metamask),
        },
        {
          ...connectors.find((connector) => connector.id === "walletConnect")!,
          icon: WalletConnectIcon,
          reward: actionPoints?.CONNECT_WALLET,
          showReward: user?.connectedWallets?.find((wallet) => wallet.provider === WalletProvider.walletConnect),
        },
        {
          ...connectors.find((connector) => connector.id === "com.bybit")!,
          icon: BybitIcon,
          name: "Bybit",
          reward: actionPoints?.CONNECT_WALLET_BYBIT,
          showReward: user?.connectedWallets?.find((wallet) => wallet.provider === WalletProvider.bybit),
        },
        {
          ...connectors.find((connector) => connector.id === "com.okex.wallet")!,
          icon: OKXIcon,
          name: "OKX",
          reward: actionPoints?.CONNECT_WALLET_OKX,
          showReward: user?.connectedWallets?.find((wallet) => wallet.provider === WalletProvider.okx),
        },
        {
          ...connectors.find((connector) => connector.id === "com.trustwallet.app")!,
          icon: trustIcon,
          name: "TRUST",
          reward: actionPoints?.CONNECT_WALLET,
          showReward: user?.connectedWallets?.find((wallet) => wallet.provider === WalletProvider.trust),
        },
      ]
    : [];

  const _disconnect = () => {
    disconnect();
    setIsWalletDetailsOpen(false);
  };

  const disconnectTon = () => {
    tonConnectUI.disconnect().finally(() => setIsTonWalletDetailsOpen(false));
  };

  return (
    <div className={cn("dropdown-menu", className)}>
      <div className="dropdown-menu__wallet">
        {!address && !isWalletDetailsOpen && !isTonWalletDetailsOpen && (
          <div className="dropdown-menu__wallet-container">
            <span className="dropdown-menu__wallet-label">
              <WalletIcon /> {t("dropdownmenu.wallet.label")}
            </span>
            <button
              type="button"
              className="dropdown-menu__wallet-connect-button"
              onClick={() => walletService.openWalletModal()}
            >
              {t("wallet.title")}
            </button>
          </div>
        )}

        {!friendlyTonAddress && !isTonWalletDetailsOpen && !isWalletDetailsOpen && (
          <div className="dropdown-menu__wallet-container">
            <span className="dropdown-menu__wallet-label">
              <WalletIcon /> {t("dropdownmenu.wallet.labelTon")}
            </span>
            <button type="button" className="dropdown-menu__wallet-connect-button" onClick={() => tonConnectUI.openModal()}>
              {t("wallet.title")}
            </button>
          </div>
        )}

        {address && !isWalletDetailsOpen && !isTonWalletDetailsOpen && (
          <div className="dropdown-menu__wallet-container">
            <span className="dropdown-menu__wallet-label dropdown-menu__wallet-label--big">
              <WalletIcon /> {t("dropdownmenu.wallet.label")}
            </span>
            <span className="dropdown-menu__wallet-address" onClick={() => setIsWalletDetailsOpen(true)}>
              {shortenAddress(address)}
              <NewArrowIcon />
            </span>
          </div>
        )}
        {friendlyTonAddress && !isTonWalletDetailsOpen && !isWalletDetailsOpen && (
          <div className="dropdown-menu__wallet-container" style={{ marginTop: 10 }}>
            <span className="dropdown-menu__wallet-label dropdown-menu__wallet-label--big">
              <WalletIcon /> {t("dropdownmenu.wallet.labelTon")}
            </span>
            <span className="dropdown-menu__wallet-address" onClick={() => setIsTonWalletDetailsOpen(true)}>
              {shortenAddress(friendlyTonAddress)}
              <NewArrowIcon />
            </span>
          </div>
        )}

        {address && isWalletDetailsOpen && (
          <>
            <div className="dropdown-menu__wallet-header" onClick={() => setIsWalletDetailsOpen(false)}>
              <NewArrowIcon /> {t("dropdownmenu.back")}
            </div>
            <div className="dropdown-menu__wallet-container">
              <span className="dropdown-menu__wallet-label dropdown-menu__wallet-label--gray">{t("wallet.address")}</span>

              <div className="dropdown-menu__wallet-address">
                <span>{shortenAddress(address)}</span>
                <button type="button" onClick={copyAddress}>
                  <CopyIcon />
                </button>
              </div>
            </div>
          </>
        )}
        {friendlyTonAddress && isTonWalletDetailsOpen && (
          <>
            <div className="dropdown-menu__wallet-header" onClick={() => setIsTonWalletDetailsOpen(false)}>
              <NewArrowIcon /> {t("dropdownmenu.back")}
            </div>
            <div className="dropdown-menu__wallet-container">
              <span className="dropdown-menu__wallet-label dropdown-menu__wallet-label--gray">{t("wallet.address")}</span>

              <div className="dropdown-menu__wallet-address">
                <span>{shortenAddress(friendlyTonAddress)}</span>
                <button type="button" onClick={copyAddress}>
                  <CopyIcon />
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <Button
        className="dropdown-menu__button"
        label={
          (address && isWalletDetailsOpen) || (friendlyTonAddress && isTonWalletDetailsOpen)
            ? t("wallet.disconnect")
            : t("wallet.logout")
        }
        onClick={() =>
          address && isWalletDetailsOpen
            ? _disconnect()
            : friendlyTonAddress && isTonWalletDetailsOpen
              ? disconnectTon()
              : logout()
        }
      />

      <ModalWithHeader
        open={isWalletModalOpened}
        onClose={walletService.closeWalletModal}
        title={t("wallet.title")}
        modalContentClassName={csss.modalContent}
      >
        <div className={csss.modalWrapper}>
          {/* <p className={csss.modalSubtitle}>{t("wallet.description")}</p> */}
          <ul className={`custom-scrollbar ${csss.connectorsList}`}>
            {appConnectors.map((item, i) => (
              <li key={i} className={csss.connectorItemBody} onClick={() => handleConnectWalletClick(item, i)}>
                {/* {!item.showReward && (
                  <span className={csss.reward}>+{item.reward} PIX</span>
                )}
                <div /> */}
                <div className={csss.connectorIconWrapper}>
                  <img src={item?.icon} alt="connector" className={csss.connectorIconWrapper__icon} />
                </div>

                <div className={csss.connectWrapper}>
                  <span className={csss.connectorTitle}>{item.name}</span>
                  <div className={csss.connectText}>
                    {t("profile.info.socials.connect")} <ArrowRight fill="white" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </ModalWithHeader>
    </div>
  );
};

export default DropdownMenu;
