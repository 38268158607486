import React, { FC, HTMLAttributes, useCallback, useState } from 'react';
import './MiniProfile.scss';
import cn from "classnames";
import { useUserStore } from "../../../../services/user/store";
import UserInfo from "./UserInfo/UserInfo";
import DropdownMenu from "./Dropdown/DropdownMenu";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import Progress from "./Progress/Progress";
import {AppRoutes} from "../../../../constants/routes";
import {useLocation} from "react-router-dom";


interface IMiniProfileProps extends HTMLAttributes<HTMLDivElement> { }


const MiniProfile: FC<IMiniProfileProps> = ({ className }) => {
  const { user } = useUserStore();
  const { pathname } = useLocation();

  const [showDropdownMenu, setDropdownMenu] = useState<boolean>(false);

  const ref = useOutsideClick(() => setDropdownMenu(false));

  const toggleDropdownMenu = useCallback(() => {
    setDropdownMenu((prevState) => !prevState);
  }, [setDropdownMenu]);

  const isStaking = pathname === AppRoutes.staking;

  return (
    <div className={cn("mini-profile", className)}>
      {!isStaking && <Progress className="mini-profile__progress" />}

      <div className="mini-profile__wrapper" ref={ref}>
        <UserInfo className="mini-profile__user-info" user={user} onClick={toggleDropdownMenu} />
        <DropdownMenu
          className={cn("mini-profile__dropdown-menu", showDropdownMenu && "mini-profile__dropdown-menu--show")}
        />
      </div>
    </div>
  );
};


export default MiniProfile;
