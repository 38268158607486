import React, { SVGAttributes } from "react";

export const WalletIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M20.0455 8.0397C19.9936 8.03642 19.937 8.03644 19.8784 8.03646L19.8638 8.03647H17.5643C15.6689 8.03647 14.0472 9.52839 14.0472 11.474C14.0472 13.4195 15.6689 14.9115 17.5643 14.9115H19.8638L19.8784 14.9115C19.937 14.9115 19.9936 14.9115 20.0455 14.9082C20.8154 14.8596 21.4963 14.2572 21.5535 13.4157C21.5573 13.3605 21.5572 13.301 21.5572 13.2459L21.5572 13.2309V9.71702L21.5572 9.70206C21.5572 9.64693 21.5573 9.58745 21.5535 9.53227C21.4963 8.69078 20.8154 8.08831 20.0455 8.0397ZM17.3604 12.3906C17.8484 12.3906 18.2439 11.9802 18.2439 11.474C18.2439 10.9677 17.8484 10.5573 17.3604 10.5573C16.8725 10.5573 16.4769 10.9677 16.4769 11.474C16.4769 11.9802 16.8725 12.3906 17.3604 12.3906Z"
            fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M19.8778 16.2865C20.0145 16.2829 20.1179 16.4095 20.0808 16.5411C19.8968 17.1942 19.6047 17.7508 19.1361 18.2195C18.4501 18.9055 17.5802 19.2099 16.5055 19.3544C15.4612 19.4948 14.1269 19.4948 12.4423 19.4948H10.5055C8.82089 19.4948 7.48655 19.4948 6.44227 19.3544C5.36755 19.2099 4.49768 18.9055 3.81168 18.2195C3.12568 17.5335 2.82124 16.6636 2.67675 15.5889C2.53635 14.5446 2.53636 13.2103 2.53638 11.5257V11.4222C2.53636 9.73763 2.53635 8.4033 2.67675 7.35902C2.82124 6.2843 3.12568 5.41443 3.81168 4.72843C4.49768 4.04243 5.36755 3.73799 6.44227 3.5935C7.48655 3.4531 8.82088 3.45311 10.5055 3.45313L12.4423 3.45313C14.1269 3.45311 15.4612 3.4531 16.5055 3.5935C17.5802 3.73799 18.4501 4.04243 19.1361 4.72843C19.6047 5.1971 19.8968 5.75372 20.0808 6.40679C20.1179 6.5384 20.0145 6.66501 19.8778 6.66146L17.5642 6.66147C14.964 6.66147 12.6721 8.71563 12.6721 11.474C12.6721 14.2323 14.964 16.2865 17.5642 16.2865L19.8778 16.2865ZM5.97388 7.11979C5.59418 7.11979 5.28638 7.4276 5.28638 7.80729C5.28638 8.18699 5.59418 8.49479 5.97388 8.49479H9.64054C10.0202 8.49479 10.328 8.18699 10.328 7.80729C10.328 7.4276 10.0202 7.11979 9.64054 7.11979H5.97388Z"
            fill="currentColor"/>
    </svg>
  );
};
