import { $api, AUTH_API_URL } from "./interceptor";

export const handleLogout = async (): Promise<void> => {
  const request = await $api.post('auth/logout', undefined, { baseURL: AUTH_API_URL })
  try {
    if (request.status === 200) {
      localStorage.removeItem('accessToken');
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
};

export const logoutService = handleLogout;