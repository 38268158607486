import { SVGAttributes } from "react";

export const TelegramIcon = ({ ...props }: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0368 4.15096C18.2604 4.05685 18.5051 4.0244 18.7455 4.05697C18.9859 4.08954 19.2132 4.18594 19.4036 4.33615C19.5941 4.48635 19.7409 4.68487 19.8286 4.91103C19.9163 5.13719 19.9418 5.38274 19.9025 5.6221L17.8505 18.0689C17.6514 19.2695 16.3341 19.9581 15.233 19.36C14.3119 18.8597 12.9439 18.0888 11.7135 17.2845C11.0982 16.8819 9.2136 15.5926 9.44522 14.6751C9.64426 13.8907 12.8109 10.943 14.6205 9.19048C15.3307 8.50196 15.0068 8.10477 14.1681 8.7381C12.0844 10.3106 8.74131 12.7019 7.63569 13.375C6.66036 13.9685 6.15188 14.0699 5.54388 13.9685C4.43464 13.784 3.40593 13.4981 2.56631 13.1497C1.43174 12.6792 1.48693 11.1194 2.56541 10.6652L18.0368 4.15096Z"
      />
    </svg>
  );
};
