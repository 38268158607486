import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { FreeMode } from "swiper/modules";

import { NewestArrowIcon } from "../../../../assets/iconComponents/NewestArrowIcon";

import styles from "../../StakingPage.module.scss";
import { rewards } from "./rewards";

export const Rewards = () => {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  const slideNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  return (
    <div className={styles.rewardsWrapper}>
      <div style={{ width: "100%", flex: 1 }}>
        <div className={styles.header}>
          <div className={styles.title}>
            <h3>{t("staking.rewards.title")}</h3>
            <h5>{t("staking.rewards.details")}</h5>
          </div>
          <div className={styles.buttons}>
            <div onClick={slidePrev}>
              <NewestArrowIcon />
            </div>
            <div onClick={slideNext}>
              <NewestArrowIcon />
            </div>
          </div>
        </div>

        <Swiper
          spaceBetween={8}
          slidesPerView={"auto"}
          freeMode
          modules={[FreeMode]}
          onSwiper={setSwiper}
          style={{ width: "100%", height: "100%" }}
        >
          {rewards.slice(0, 5).map(({ iconUrl, backgroundUrl, name }, i) => (
            <SwiperSlide key={i} style={{ flexGrow: 0, flexShrink: 1, alignSelf: "center" }}>
              <div className={styles.rewardCard}>
                <div className={styles.rewardCardBackground} style={{ background: `url(${backgroundUrl})` }}>
                  <img className={styles.rewardCardImage} src={iconUrl} alt="" />
                </div>
                <div className={styles.name}>{t(name)}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
