import { HalfCircleProgressBar } from "./HalfCircleProgressBar"
import styles from "./HalfCircleProgress.module.scss"
import { PropsWithChildren, useEffect, useRef, useState } from "react"
import { useWindowWidth } from "../../hooks/useWindowWidth"

type IHalfCircleProgressDisplay = {
  value: number
  className?: string
  barLineWidth?: number
  backLight?: boolean
}

export const HalfCircleProgressDisplay: React.FC<PropsWithChildren & IHalfCircleProgressDisplay> = ({
  children,
  value,
  className,
  barLineWidth,
  backLight,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const windowWidth = useWindowWidth()
  const [actualElementWidth, setActualElementWidth] = useState(0)
  const [actualElementHeight, setActualElementHeight] = useState(0)
  const [childrenFontSize, setChildrenFontSize] = useState<"size-36" | "size-56">("size-56")

  useEffect(() => {
    setActualElementWidth(containerRef.current?.getBoundingClientRect().width!)
    setActualElementHeight(containerRef.current?.getBoundingClientRect().height!)
  }, [windowWidth])

  useEffect(() => {
    setChildrenFontSize(actualElementHeight < 240 ? "size-36" : "size-56")
  }, [actualElementHeight])

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return <div ref={containerRef} className={`${styles.container} ${className || ""}`}>
    <div className={`${styles.display} ${styles[childrenFontSize]}`}>
      <HalfCircleProgressBar
        progress={value}
        color="#B364D8"
        lineWidth={barLineWidth}
        width={actualElementWidth}
      />
      <HalfCircleProgressBar
        progress={100}
        lineWidth={barLineWidth}
        width={actualElementWidth}
      />
      {children}
    </div>
    {backLight ? <div className={styles.shine} /> : <div className={styles.bottomFade} />}
  </div>
}