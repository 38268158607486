export const validationSum = (price: string): string | undefined => {
    const re = /^[0-9\b.]+$/;
    let priceValid = price;
    if (price === '' || re.test(price)) {
        if (price.length === 2 && price.charAt(0) === '0') {
            priceValid = `0,${price.charAt(2)}`;
        }
        if (price.split('.').length - 1 === 2) {
            priceValid = priceValid.slice(0, -1)
        }
        if ((price.length === 1 && price.charAt(0) === ',') || price.charAt(0) === '.' || price.charAt(0) === '-') {
            priceValid = `0,${price.charAt(2)}`;
        }
        priceValid = priceValid.replace(/(\.,|,\.)|(\.\.|,,)|,/g, '.');
        return priceValid;
    }
    return priceValid.slice(0, -1);
};