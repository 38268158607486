import { BigNumber } from "../../utils/bigNumber";

export interface EpochDataType {
  epochEndTimestamp: string;
  epochStartTimestamp: string;
  pointsPer1MShares: string;
}

export type CurrentEpochDataResponseType = [string, EpochDataType, boolean];

export interface CurrentEpochDataType {
  epochIndex: string;
  epoch: EpochDataType;
  isValid: boolean;
}

export interface StakeContractDataType {
  amount: string;
  penaltyBP: string;
  penaltyDays: number;
  stakedTimestamp: string;
  shares: string;
  unstaked: boolean;
  rewardPerSecond: BigNumber;
  reward: BigNumber;
}

export interface StakeDataType extends StakeContractDataType {
  id: number;
  withdrawTimestamp: string;
  withdrawn: boolean;
  isGenesis?: boolean;
  onToggleGenesisModal?: () => void;
}

export interface UserProofsType {
  amount: number;
  index: number;
  proofs: string[];
}

export type StakersResponse = [boolean, string, number, number, number, string];

export enum RaffleState {
  won = "won",
  lose = "lose",
  unavailable = "unavailable",
}

export interface StakedInfo {
  stakedCoins: number;
  incomeTicketsPerDay: number;
  isWithdrawn: boolean;
  prizeFund: number;
  totalTicketsAmount: number;
}

export interface IRawStakedInfo {
  earnedPoints: number;
  incomePointsPerDay: number;
  isWithdrawn: boolean;
  prizeFund: number;
  totalUserBalance: number;
  epochEndDate: string;
  epochStartDate: string;
}

export interface WithdrawAvailableDate {
  withdrawAvailableDate?: string;
}

export type RaffleResultResponse = {
  id: string;
  wallet: string[] | string;
  amount: number;
  index: number;
  proofs: string[];
};

export type UserRaffleData = RaffleResultResponse & CurrentEpochDataType & {
  status: RaffleState
}

export interface WithdrawSignature {
  amount: string;
  receiver: string;
  signature: string;
  used: boolean;
}
