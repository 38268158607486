import OTPInput from "react-otp-input";
import {FC, useRef, useState} from "react";
import loginService from "../../services/loginService";
import { useLoginStore } from "../../store/loginStore";
import './OTPCodeInput.scss';
import cn from "classnames";

interface IOTPCodeInputProps {
  className?: string;
}

export const OTPCodeInput: FC<IOTPCodeInputProps> = ({className}) => {
  const lastOtpInputRef = useRef<HTMLElement | null>(null);
  const { otpCode, otpError } = useLoginStore();

  const [activeInputIndex, setActiveInputIndex] = useState(-1);

  const handleOTPChange = (otp: string) => {
    if (otpError) {
      loginService.clearOTPError();
    }

    loginService.setOTPCode(otp);
  };

  return (
    <div className={cn("otp-code-input", className)}>
      <OTPInput
        renderInput={({ ref, onFocus, onBlur, ...inputProps }, index) => {
          return (
            <div className="otp-code-input__input-wrapper">
              <input
                ref={(el) => {
                  if (index === 5) {
                    lastOtpInputRef.current = el;
                  }
                  ref(el);
                }}
                {...inputProps}
                className={cn(
                  "otp-code-input__input",
                  otpError && "otp-code-input__input--error",
                  inputProps.value && "otp-code-input__input--has-value",
                  (activeInputIndex === index && !inputProps.value) && "otp-code-input__input--selected", // цвет цифры с opacity
                )}
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="0"
                onFocus={(props) => {
                  setActiveInputIndex(index);
                  onFocus(props);
                }}
                onBlur={(props) => {
                  setActiveInputIndex(-1);
                  onBlur(props)
                }}
              />
            </div>
          );
        }}
        onChange={handleOTPChange}
        value={otpCode}
        numInputs={6}
        containerStyle="otp-code-input__input-wrap"
        shouldAutoFocus
        skipDefaultStyles
      />

      <div className="otp-code-input__error">{otpError}</div>
    </div>
  );
};
