import React from "react";

export const SubModalCloseIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      {...props}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40541 5.93053C7.72199 5.24711 6.61395 5.24711 5.93053 5.93053C5.24711 6.61395 5.24711 7.72199 5.93053 8.4054L10.5265 13.0013L5.93058 17.5972C5.24716 18.2806 5.24716 19.3887 5.93058 20.0721C6.614 20.7555 7.72204 20.7555 8.40546 20.0721L13.0013 15.4762L17.5971 20.072C18.2806 20.7554 19.3886 20.7554 20.072 20.072C20.7554 19.3886 20.7554 18.2806 20.072 17.5972L15.4762 13.0013L20.0721 8.40545C20.7555 7.72204 20.7555 6.614 20.0721 5.93058C19.3887 5.24716 18.2806 5.24716 17.5972 5.93058L13.0013 10.5265L8.40541 5.93053Z"
        fill="#737374"
      />
    </svg>
  );
};
