import React, {FC, PropsWithChildren} from 'react';
import './NotAuthLayout.scss';
import {Card} from "../../newComponents/Card/Card";
import cn from "classnames";

interface INotAuthLayoutContentProps {
  className?: string;
}

export const NotAuthLayoutContent: FC<PropsWithChildren<INotAuthLayoutContentProps>> = ({ children, className }) => {
  return (
    <Card className={cn("not-auth-layout-content", className)}>
      {children}
    </Card>
  );
};
