import { useMediaQuery } from "@mui/material";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from 'react';
import { BoxItemModal } from './BoxItemModal';
import { useWindowWidth } from "../../../../../hooks/useWindowWidth";
import classNames from "classnames";

export interface IBoxItem {
  description: string;
  title: string;
}

export const BoxItem = ({ box }: { box: { name: string; desc?: string; image: string; blurColor?: string, imageGradient: string, info?: { title: string; description: string }[] } }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [itemHeight, setItemHeight] = useState(222)
  const window = useWindowWidth()
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [item, setItem] = useState<null | IBoxItem>(null)
  const itemContainerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation();

  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    itemContainerRef.current && setItemHeight(
      itemContainerRef.current.clientHeight + (isMobile ? 64 : 0)
    )
  }, [window, isMobile])

  const boxes = [
    "staking.info.boxes.partOfChest",
    "staking.info.boxes.chest",
    "staking.info.boxes.newBot",
    "staking.info.boxes.comboBoost",
    "staking.info.boxes.partOfChest",
    "staking.info.boxes.chest",
    "staking.info.boxes.comboBoost",
    "staking.info.boxes.newBot",
  ];

  const onClickItemHandler = (item: IBoxItem) => {
    setIsOpenModal(true)
    setItem(item)
  }

  const onClickContainerHandler = () => {
    setIsDropdownOpen(prev => !prev)
  }

  return (
    <>
      <div
        className={`${styles.boxItem} ${isDropdownOpen ? styles.active : ""}`}
        onClick={onClickContainerHandler}
        style={isDropdownOpen ? { minHeight: `${itemHeight + 32}px` } : {}}
      >
        <div ref={itemContainerRef} className={styles.boxItem__content}>
          {!isMobile && (
            <>
              <img src={box.image} alt="" className={styles.boxItem__boxImage_blured} />
              <img src={box.image} alt="" className={styles.boxItem__boxImage} />
              <div className={classNames(
                styles.boxItem__boxGradient,
                isDropdownOpen && styles.expanded
              )}
                style={{ backgroundColor: box.blurColor }}
              />
            </>
          )}

          <div className={styles.boxItem__descriptionBlock}>
            <div>
              <p className={styles.boxItem__nameLabel}>{t("staking.info.boxes.nameLootbox", { name: t(box.name) })}</p>
              <h4 className={styles.boxItem__name}>{t(box.name)}</h4>
              {box.desc && <p className={styles.boxItem__description}>{t(box.desc)}</p>}
            </div>
            <p className={styles.boxItem__include}>
              <span className={styles.boxItem__includeIcon} />
              {t("staking.info.boxes.whatinclude")}
            </p>
          </div>
          <div className={styles.gridContainer}>
            {box.info ? box.info.map((box, index) => (
              <div onClick={() => onClickItemHandler(box)} key={index} className={styles.gridItem}>
                {t(box.title)}
              </div>
            )) : boxes.map((box, index) => (
              <div key={index} className={styles.gridItem}>
                {t(box)}
              </div>
            ))}
          </div>
        </div>
        {isMobile && (
          <>
            <img src={box.image} alt="" className={styles.boxItem__boxImage} />
            <img src={box.imageGradient} alt="" className={styles.boxItem__boxGradient} />
          </>
        )}
      </div>
      <BoxItemModal isOpen={isOpenModal} handleClose={() => setIsOpenModal(false)} item={item} />
    </>
  );
};
