import { useTranslation } from "react-i18next";
import { getReferralLink } from "../../../utils/getReferralLink";
import { useUserStore } from "../../../services/user/store";
import "./Referrals.scss";
import {Title} from "../../../newComponents/Title/Title";
import {Text} from "../../../newComponents/Text/Text";
import {Card} from "../../../newComponents/Card/Card";
import {useEffect} from "react";
import {referralsService} from "../referrals.service";
import {useReferralsStore} from "../referrals.store";
import ReferralsCopy from "./ReferralsCopy/ReferralsCopy";
import ReferralsInfo from "./ReferralsInfo/ReferralsInfo";
import {ReferralsTable} from "./ReferralsTable";
import {roundNumber} from "../../../utils/roundNumber";

export const Referrals = () => {
  const { t } = useTranslation();
  const { user } = useUserStore();

  const {
    referralsStats,
    referralsList,
    isReferralsListLoading
  } = useReferralsStore();

  const referralCode = user?.referralCode;
  const referralLink = getReferralLink(referralCode || "");
  const { referralsCount, totalReferralPoints } = referralsStats || {};

  useEffect(() => {
    referralsService.getReferralsStats().catch(console.warn);
    referralsService.getReferralsList().catch(console.warn);
  }, [])


  return (
    <section className="referrals">
      <Card className="referrals__left-col">
        <Title>{t("referrals.title")}</Title>
        <Text className="referrals__desc" variant='s'>{t("referrals.description")}</Text>
        <ReferralsCopy className="referrals__copy" codeValue={referralCode!} linkValue={referralLink} />
      </Card>

      <div className="referrals__right-col">
        <ReferralsInfo
          className="referrals__count"
          label={t("referrals.table.title")}
          value={referralsCount || 0}
        />
        <ReferralsInfo
          className="referrals__points"
          label={t("referrals.earned")}
          isPoints
          value={roundNumber(totalReferralPoints || 0, 2)}
        />

        <Card className="referrals__table-wrapper">
          <Title>{t("referrals.table.title")}</Title>
          <ReferralsTable
            className="referrals__table"
            referrals={referralsList}
            isLoading={isReferralsListLoading}
          />
        </Card>
      </div>
    </section>
  );
};
