import { Address } from "@ton/core";
import { isAddress } from "ethers";
import TonWeb from "tonweb";
import { TON_TESTNET_RPC } from "../services/web3/rainbow";

export const shortenAddress = (address: string, chars = 6): string => {
  return `${address.substring(0, chars)}...${address.substring(42 - 2)}`;
};

export const getDdMmYy = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}.${month}.${year}`;
};

export const getDdMonthNameTimeUtc = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const utcString = date.toLocaleString("en-US", {
    ...options,
    timeZone: "UTC",
  });
  return utcString + " UTC";
};

export const toNormalFixed = (num: string | number | undefined, afterDot = 2): string => {
  if (!num) return "0";
  let stringifiedNum = typeof num === "number" ? num.toString() : num;
  const reg = new RegExp(`(\\.\\d{${afterDot}})\\d+`, "g");
  const value = stringifiedNum.replace(reg, "$1");
  return value;
};

export const getTonBalance = async (tonUserAddress: string | undefined) => {
  try {
    if (!tonUserAddress) return { balanceBn: "0", balance: "0" };
    const tonweb = new TonWeb(new TonWeb.HttpProvider(TON_TESTNET_RPC));
    const balanceBn = await tonweb.provider.getBalance(tonUserAddress);
    const balance = TonWeb.utils.fromNano(balanceBn);
    localStorage.setItem("tonBalance", balance);
    localStorage.setItem("tonBalanceBn", balanceBn);
    return { balanceBn, balance };
  } catch (e) {
    console.log({ e });
    const balanceBn = localStorage.getItem("tonBalanceBn");
    const balance = localStorage.getItem("tonBalance");
    return { balanceBn: balanceBn || "0", balance: balance || "0" };
  }
};

export const calculateIncomeBonus = (income: number, value: number) => {
  return income - value / 100;
};

export const isValidTonAddress = (tonAddress: string) => {
  try {
    const address = Address.parse(tonAddress);
    return address !== null;
  } catch (e) {
    return false;
  }
};

export const isValidEvmAddress = (evmAddress: string) => {
  return isAddress(evmAddress);
};

export const addCommaToEachK = (value: string) => {
  const parts = value.split(".");
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.length > 1 ? integerPart + "." + parts[1] : integerPart;
};
