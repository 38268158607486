import { useTranslation } from "react-i18next";
import { useIsMobileResolution } from "../../../../../hooks/useIsMobileResolution";
import { charactersStore } from "../../store";
import css from "./ClaimedEarly.module.scss";
import { EarlyNftCard } from "./EarlyNftCard";
import { TonWalletDisplay } from "./TonWalletDisplay";

export const ClaimedEarly = () => {
  const { t } = useTranslation()
  const isMobile = useIsMobileResolution(576)
  const { dashboard, clicker } = charactersStore(state => state.earlyNft)

  return <div className={css.wrapper}>
    <div className={css.wrapper_inner}>
      <div className={css.shine} />
      <h3>{t("claim.characters.early.title")}</h3>
      <p>{t("claim.characters.early.description")}</p>
      <EarlyNftCard
        titleI18Key={"claim.characters.early.botnft.title"}
        rarity={clicker?.rarity}
        avatarUrl={clicker?.url}
        className={css.mb}
        avatarType="bot"
      />
      <EarlyNftCard
        titleI18Key={"claim.characters.early.dashboardnft.title"}
        rarity={dashboard?.rarity}
        avatarUrl={dashboard?.url}
        className={css.mb}
        avatarType="dashboard"
      />
      {isMobile ? null : <TonWalletDisplay />}
    </div>
  </div>
}
