import { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import css from "../styles.module.scss";
import { getDMY } from "../../../../../utils/formatDate";
import PixelTkImg from "../../../../../assets/images/pixel-token.png";
import { DisclaimerIcon } from "../../../../../assets/iconComponents/DisclaimerIcon";
import { StackingTimer } from "./StackingTimer";
import { PurpleStackingItemGradient } from "../../../../../assets/iconComponents/PurpleStackingItemGradient";
import { Question } from "../../../../../assets/iconComponents/Question";
import { StakeDataType } from "../../../types";
import { getRewardPerDay } from "../../../../../utils/time";
import { useAccount, useWriteContract } from "wagmi";
import { CONTRACTS_DATA } from "../../../web3/contracts";
import { stakingStore } from "../../../store";
import { Loader } from "../../../../../components/Loader";
import { toBn } from "../../../../../utils/bigNumber";
import { useTranslation } from "react-i18next";

export const StackingCard = ({
  setSubModalOpen,
  onToggleGenesisModal,
  ...stake
}: StakeDataType & { setSubModalOpen: (c: boolean) => void }) => {
  const { t } = useTranslation();
  const [isFlipped, setIsFlipped] = useState(false);
  const [isTImerShown, setIsTImerShown] = useState<boolean>(stake.unstaked);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isWithoutPenalty, setIsWithoutPenalty] = useState<boolean>(false);
  const isTimerAbleToBeShown = stake.unstaked && stake.withdrawTimestamp && Date.now() <= +stake.withdrawTimestamp;
  const { isPending, isSuccess, isError, variables, writeContractAsync: writeContract } = useWriteContract();

  const { address } = useAccount();

  useEffect(() => {
    if (variables?.functionName === "cancelUnstake" && isSuccess) {
      stakingStore.setState({ isCancelUnstakeSuccess: true });
      setSubModalOpen(true);
    }
  }, [variables, isSuccess, setSubModalOpen]);

  const handleUnstake = async () => {
    if (address) {
      try {
        await writeContract({
          address: CONTRACTS_DATA.staking.address,
          abi: CONTRACTS_DATA.staking.abi,
          functionName: "unstake",
          args: [stake.id],
        });
        setIsTImerShown(true);
      } catch (e) {
        console.log("Failed to unstake: ", e);
      }
    }
  };
  const handleCancelUnstake = async () => {
    if (address) {
      try {
        await writeContract({
          address: CONTRACTS_DATA.staking.address,
          abi: CONTRACTS_DATA.staking.abi,
          functionName: "cancelUnstake",
          args: [stake.id],
        });
        setIsTImerShown(false);
      } catch (e) {
        console.log("Failed to cancel unstake: ", e);
      }
    }
  };

  const handlePreWithdraw = () => {
    stakingStore.setState({ unstakedTowithdrawId: stake.id });
    setSubModalOpen(true);
  };

  const handleFlippCard = (state: boolean) => {
    if (onToggleGenesisModal) {
      onToggleGenesisModal();
    } else {
      setIsFlipped(state);
    }
  };

  const penaltyDeadline = useMemo(() => {
    const deadline = +stake.stakedTimestamp + stake.penaltyDays * 24 * 60 * 60 * 1000;
    return {
      deadline,
      isPassed: toBn(new Date().getTime()).gte(deadline),
    };
  }, [stake]);

  const isContractIteraction = isPending && !isSuccess && !isError;

  return (
    <div className={cn(css.stakeCardWrapper, isFlipped && css.stakeCardWrapperFlipped)}>
      {isFlipped && <PurpleStackingItemGradient className={css.itemGradient} />}
      <div className={css.stakeCardFront}>
        <div className={css.stackingTop}>
          <span className={css.stackingDate}>{getDMY(new Date(+stake.stakedTimestamp))}</span>
          <button className={css.unstakeBtn} onClick={() => handleFlippCard(true)}>
            {stake.unstaked ? t("staking.stakingCard.details") : t("staking.stakingCard.unstrake")}
          </button>
        </div>
        <div className={css.stackingBottom}>
          {onToggleGenesisModal && (
            <div className={css.genesisLabelWrapper}>
              <span className={css.genesisLabel}>{t("staking.stakingCard.genesis")}</span>
            </div>
          )}

          <p className={css.stakeAmount} style={stake.amount.length > 7 ? { fontSize: "16px" } : {}}>
            <span>{stake.amount} PIXFI</span>
            <img src={PixelTkImg} alt="token-icon" />
          </p>
          <p className={css.stakePerDay}>
            <b>{getRewardPerDay(stake.rewardPerSecond).toFixed(2)}</b> <span>{t("staking.stakingCard.perDay")}</span>
          </p>
        </div>
      </div>

      <div className={css.stakeCardBack}>
        <div className={css.stakeCardBackHeader}>
          <span className={css.stackingDate}>{getDMY(new Date(+stake.stakedTimestamp))}</span>
          {stake.unstaked && (
            <button
              className={cn(css.unstakeBtn, isTImerShown && css.unstakeBtnActive)}
              onClick={() => {
                if (isTimerAbleToBeShown) {
                  setIsTImerShown((prev) => !prev);
                }
              }}
            >
              {"Unstaked"}
              {isTimerAbleToBeShown && <Question className={cn(css.questionIcon)} isBlack={isTImerShown} />}
            </button>
          )}
        </div>
        <p className={css.stakeAmount} style={stake.amount.length > 7 ? { fontSize: "16px" } : {}}>
          <span>{stake.amount} PIXFI</span>
          <img src={PixelTkImg} alt="token-icon" />
        </p>
        <p className={css.stakePerDay}>
          <b>{getRewardPerDay(stake.rewardPerSecond).toFixed(2, 1)}</b> <span>{t("staking.stakingCard.perDay")}</span>
        </p>
        {penaltyDeadline.isPassed ? (
          <p className={css.withdrawalWithoutPenalty}>{t("staking.stakingCard.youCanWithdraw")}</p>
        ) : isTImerShown && stake.unstaked ? (
          <StackingTimer
            date={+stake.stakedTimestamp}
            untilPenaltyDate={penaltyDeadline.deadline}
            setIsWithoutPenalty={setIsWithoutPenalty}
          />
        ) : (
          <div className={css.disclaimer}>
            <DisclaimerIcon className={css.disclaimerIcon} />
            <p>{t("staking.stakingCard.disclamerDesc")}</p>
          </div>
        )}
        <div className={css.stakeBtnsWrapper}>
          {stake.unstaked ? (
            <button
              className={css.stakeBtnPurple}
              onClick={handleCancelUnstake}
              disabled={isContractIteraction}
              style={isContractIteraction ? { opacity: "0.5" } : {}}
            >
              Cancel unstake
            </button>
          ) : (
            <button
              className={css.stakeBtnPurple}
              onClick={() => handleFlippCard(false)}
              style={isContractIteraction ? { opacity: "0.5" } : {}}
            >
              Cancel
            </button>
          )}

          {stake.unstaked ? (
            <button className={css.stakeBtnGrey} onClick={handlePreWithdraw} disabled={isContractIteraction}>
              {isContractIteraction ? <Loader /> : t("staking.stakingCard.withdraw")}
            </button>
          ) : (
            <button className={css.stakeBtnGrey} onClick={handleUnstake} disabled={isContractIteraction}>
              {isContractIteraction ? <Loader /> : t("staking.stakingCard.unstake")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
