import css from "./NorthShine.module.scss"

export const NorthShine = () => {
  return <div className={css.shine_wrapper}>
    <ul className={css.container1} >
      {Array.from({ length: 6 }).map((_, i) => <li key={i} className={css.particle1} />)}
    </ul>
    <ul className={css.container2} >
      {Array.from({ length: 7 }).map((_, i) => <li key={i} className={css.particle2} />)}
    </ul>
    <ul className={css.container3} >
      {Array.from({ length: 6 }).map((_, i) => <li key={i} className={css.particle3} />)}
    </ul>
  </div>
}