import Box5 from "../../../../assets/images/Box5.png";
import Box5BG from "../../../../assets/images/StakingRewards/Box5BG.png";

import Box4 from "../../../../assets/images/Box6.png";
import Box4BG from "../../../../assets/images/StakingRewards/Box4BG.png";

import Box3 from "../../../../assets/images/StakingRewards/Box3.png";
import Box3BG from "../../../../assets/images/StakingRewards/Box3BG.png";

import Box2 from "../../../../assets/images/StakingRewards/Box2.png";
import Box2BG from "../../../../assets/images/StakingRewards/Box2BG.png";

import Box1 from "../../../../assets/images/StakingRewards/Box1.png";
import Box1BG from "../../../../assets/images/StakingRewards/Box1BG.png";

import Box from "../../../../assets/images/StakingRewards/Box.png";
import BoxBG from "../../../../assets/images/StakingRewards/BoxBG.png";

import weapon from "../../../../assets/images/StakingRewards/Weapon.png";
import WeaponBG from "../../../../assets/images/StakingRewards/WeaponBG.png";

import clothet from "../../../../assets/images/StakingRewards/Clothet.png";
import ClothetBG from "../../../../assets/images/StakingRewards/ClothetBG.png";

export const rewards = [
  {
    iconUrl: Box,
    name: "staking.info.boxes.box.name",
    backgroundUrl: BoxBG
  },
  {
    iconUrl: Box1,
    name: "staking.info.boxes.box1.name",
    backgroundUrl: Box1BG
  },
  {
    iconUrl: Box2,
    name: "staking.info.boxes.box2.name",
    backgroundUrl: Box2BG
  },
  {
    iconUrl: Box5,
    name: "staking.info.boxes.box5.name",
    backgroundUrl: Box5BG
  },
  {
    iconUrl: Box4,
    name: "staking.info.boxes.box6.name",
    backgroundUrl: Box4BG
  },
  {
    iconUrl: Box3,
    name: "staking.rewards.Box3",
    backgroundUrl: Box3BG
  },
  {
    iconUrl: weapon,
    name: "staking.rewards.weapon",
    backgroundUrl: WeaponBG
  },
  {
    iconUrl: clothet,
    name: "staking.rewards.clothet",
    backgroundUrl: ClothetBG
  }
];
