export const ArrowUpIcon = ({ color = "#FFFFFF" }: { color: string }) => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.28972 12.125C2.8537 12.125 2.50024 11.7715 2.50024 11.3355V7.91447C2.50024 7.47846 2.14678 7.125 1.71077 7.125H1.04123C0.375204 7.125 0.00845677 6.35107 0.430206 5.8356L4.38922 0.996802C4.7051 0.610737 5.29539 0.610735 5.61126 0.996801L9.57028 5.8356C9.99203 6.35107 9.62528 7.125 8.95926 7.125H8.28972C7.8537 7.125 7.50024 7.47846 7.50024 7.91447V11.3355C7.50024 11.7715 7.14678 12.125 6.71077 12.125H3.28972Z"
        fill={color}
      />
    </svg>
  );
};
