import { AxiosResponse } from "axios";
import { BaseService } from "../../../../../services/BaseService";
import { $api } from "../../../../../services/interceptor";
import { Avatars, IAvatarsStore, avatarsStore } from "./store";
import { userService } from "../../../../../services/user/service";
import { userStore } from "../../../../../services/user/store";
import { userProfileService } from "../../../service/service";
import { NFT_AVATAR_ID } from "../../../constants";

class AvatarsAPI extends BaseService<IAvatarsStore> {
  constructor() {
    super(avatarsStore);
  }

  async getAvatars(successCallback?: () => void) {
    try {
      const response = await $api.get<Avatars>("/avatars");

      this.setState({
        avatars: response.data.map(a => {
          return {
            ...a,
            isNft: a.id === NFT_AVATAR_ID
          }
        })
      });
      successCallback && successCallback();
    } catch (error) {
      console.log(error);
    }
  }

  async setAvatar(userAvatarId: string) {
    try {
      this.setState({ isEditPending: true });

      await $api.post<AxiosResponse<Avatars>>(`/avatars/user-avatars/${userAvatarId}/select`);

    } catch (error) {
      console.log(error);
    } finally {
      const userID = userStore.getState().user?.id || "";

      if (userID) {
        await userProfileService.getUserData(userID);
      }
      await userService.getUserData();
      this.setState({ isEditPending: false });
    }
  }

  async buyAvatar(avatarID: string) {
    this.setState({ isBuyLoading: true })
    try {
      await $api.post<AxiosResponse<Avatars>>(`/avatars/${avatarID}/buy`);
      await this.getAvatars();
      await userService.getUserData();

      const userID = userStore.getState().user?.id || "";

      if (userID) {
        await userProfileService.getUserData(userID);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isBuyLoading: false });
    }
  }
}

const avatarsService = new AvatarsAPI();
export default avatarsService;
