import { useState } from "react";
import styles from "./styles.module.scss";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Question } from "../../../../../../assets/iconComponents/Question";
import { ACCORDIONS } from "../../../../mockUpData";
import { useTranslation } from "react-i18next";

export const Accordions = () => {
  const [expanded, setExpanded] = useState<number | false>(false);
  const { t } = useTranslation()

  const handleClick = (panel: number) => () => {
    setExpanded(expanded !== panel ? panel : false);
  };

  return (
    <div className={styles.accordions}>
      {" "}
      {ACCORDIONS.map((accordion) => (
        <Accordion
          expanded={expanded === accordion.id}
          onChange={handleClick(accordion.id)}
          key={accordion.title}
          sx={{
            bgcolor: "transparent",
            color: "white",
            boxShadow: "none",

            "&::before": {
              backgroundColor: "#2c2c2e",
              left: "56px",
              opacity: "1 !important",
            },

            "& .MuiAccordionSummary-root": {
              padding: "0px",
              minHeight: "56px",
              maxHeight: "56px",
              "& .MuiAccordionSummary-content": {
                gap: "16px",
                alignItems: "center",
              },
            },
            "&.Mui-expanded": {
              margin: "0px",
            },
            "& .MuiAccordionDetails-root": {
              paddingLeft: "56px",
              paddingRight: "0px",
            },
          }}
        >
          <AccordionSummary>
            <span className={"element-with-icon"} style={{ minWidth: 40 }}>
              <Question isBlack={false} width={23} height={23} />
            </span>
            <h3 className={styles.accordionTitle}>{t(accordion.title)}</h3>
            <button className={styles.accordionButton}>{expanded === accordion.id ? "-" : "+"}</button>
          </AccordionSummary>
          <AccordionDetails>
            {accordion.description.map((acc) => (
              <>
                <p key={acc.desc} className={styles.accordionDetailsDescription}>{t(acc.desc)}</p>
              </>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
