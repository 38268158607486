import React from "react";

export const HistoryWaitingGradient = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      {...props}
      width="500"
      height="162"
      viewBox="0 0 500 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" filter="url(#filter0_f_5004_39615)">
        <ellipse cx="324" cy="227.5" rx="344" ry="153.5" fill="#354063" />
      </g>
      <defs>
        <filter
          id="filter0_f_5004_39615"
          x="-94"
          y="0"
          width="836"
          height="455"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="37"
            result="effect1_foregroundBlur_5004_39615"
          />
        </filter>
      </defs>
    </svg>
  );
};
