import { InputHTMLAttributes, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { getFontClass } from '../../utils/getFontClass';
import styles from "./input.module.scss"

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  errorMessage?: string;
  successMessage?: string;
  borderColor?: string;
  variant?: 'primary' | 'secondary';
  inputBtn?: {
    text: string;
    onClick: () => void | Promise<void>;
  };
  iconButton?: JSX.Element;
  disabled?: boolean;
};

const Input = ({
  value,
  className = '',
  successMessage,
  errorMessage,
  inputBtn,
  iconButton,
  variant = 'primary',
  disabled,
  ...props
}: InputProps) => {
  const [focused, setFocused] = useState(false);
  const isPrimary = variant === 'primary';
  const borderColor = disabled ? '#2e2e38' : (isPrimary ? focused || value : focused) ? '#8610BD' : isPrimary ? '#C0C0C0' : '#474756';
  const { i18n } = useTranslation();

  return (
    <div
      className={`${styles.inputWrapper} ${className}`}
      style={{ borderColor: borderColor, ...props.style }}
    >
      <input
        type='text'
        className={cn(
          getFontClass(i18n.language),
          styles.field,
          inputBtn && styles.field_withButton,
        )}
        disabled={disabled}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        value={value}
        {...props}
      />
      {inputBtn && (
        <button
          onClick={inputBtn.onClick}
          className={
            styles.button
          }
        >
          {inputBtn.text}
        </button>
      )}

      {<div className={styles.iconButton}>{iconButton}</div> || null}

      {successMessage && (
        <div className='font-ptmono text-xl leading-[30px] text-green-success mt-2'>
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className='leading-[30px] text-red-error mt-2'>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default Input;
