import { useTranslation } from "react-i18next";

import { UiModal } from "../../../../../components/Modals/Modal";
import { Button } from "../../../../../components/Buttons/Button";

import css from "./BuyModal.module.scss";
import { useAvatarsStore } from "./store";
import { useUserStore } from "../../../../../services/user/store";
import classNames from "classnames";

interface PropsTypes {
  open: boolean;
  name: string;
  loading?: boolean;
  price: number;
  onClose: () => void;
  handleBuy: () => void;
}

export const BuyAvatarModal = ({
  open,
  loading,
  name,
  price,
  onClose,
  handleBuy,
}: PropsTypes) => {
  const { t } = useTranslation();
  const { isBuyLoading } = useAvatarsStore();
  const { user } = useUserStore();

  const isBuyDisabled =
    loading ||
    isBuyLoading ||
    (user?.spendablePoints?.amount || 0) < (price || 0);

  return (
    <UiModal
      open={open}
      handleClose={onClose}
      title={t("pets.buy.proof.title")}
      mobileFit
    >
      <div className={classNames(css.proofOfActionModalWrapper)}>
        <p className={classNames(css.proofOfActionSubtitle)}>
          {t(`pets.buy.proof.description`, { name: name, points: price })}
        </p>
        <div className={css.btnsWrapper}>
          <Button
            size="large"
            isUppercased
            onClick={onClose}
          >
            {t("buttons.no")}
          </Button>
          <Button
            size="large"
            variant="secondary"
            isUppercased
            onClick={handleBuy}
            disabled={isBuyDisabled}
            isLoading={loading || isBuyLoading}
          >
            {t("buttons.yes")}
          </Button>
        </div>
      </div>
    </UiModal>
  );
};
