import { create, useStore } from "zustand";

export interface IRetryTimerStore {
  refetchingCooldowns: Record<
    string,
    { intervalId: NodeJS.Timeout; time: number }
  >;
  refetchingTasksIds: Array<string>;
}

export const retryTimersStore = create<IRetryTimerStore>(() => ({
  refetchingCooldowns: {},
  refetchingTasksIds: [],
}));

export const useRetryTimersStore = () => useStore(retryTimersStore);
