import { Trans, useTranslation } from "react-i18next"
import { ConnectEmailMainModalWrapper, IConnectEmailModalSteps } from "../MainModalComponent/ConnectEmailMainModalWrapper"
import { AccountCreationSteps, AccountCreationType, AccountExistCreationSteps } from "../../../../login/types"
import { NewAccountOtpStep } from "./newAccountSteps/NewAccountOtpStep"
import { AccountCreationStepEmail } from "./AccountCreationStepEmail"
import { Button } from "../../../../../components/Buttons/Button"
import { ChooseAccountStep } from "./existingAccountSteps/ChooseAccountStep"
import { ConfirmationAccountStep } from "./existingAccountSteps/ConfirmationAccountStep"
import { useAccountLinkingStore } from "../../../../login/store/accountLinkingStore"
import { ExistAccountOtpStep } from "./existingAccountSteps/ExistAccountOtpStep"
import accountLinkingService from "../../../../login/services/accountLinkingService"
import "./AccountCreationModal.scss"

type ModalContentData = {
  component: JSX.Element,
  title: string,
  text?: JSX.Element | string,
  steps?: IConnectEmailModalSteps,
  onClose: () => void
}

type ICreationComponent = Record<AccountCreationSteps, ModalContentData>

type ICreationExistComponent = Record<AccountExistCreationSteps, ModalContentData>

export const AccountCreationModal = () => {
  const { t } = useTranslation()
  const {
    isAccountCreationModalOpen,
    accountCreationType,
    accountCreationExistSteps,
    accountCreationSteps,
    email
  } = useAccountLinkingStore()

  const newAccount: ICreationComponent = {
    "otp": {
      component: <NewAccountOtpStep />,
      title: t("auth.modal.accountCreation.step.email.title"),
      text: <Trans
        i18nKey="auth.linkAccount.step2.text"
        components={{ white: <span /> }}
        values={{ email }}
      />,
      onClose: () => accountLinkingService.setIsAccountCreationModalOpen(false)
    },
    "congrats": {
      component: <Button
        onClick={accountLinkingService.resetState}
        size="large"
        variant="secondary"
        isUppercased
      >{t("global.close")}</Button>,
      title: t("auth.linkAccount.step.congrats.title"),
      text: t("auth.linkAccount.step.congrats.text"),
      onClose: () => {
        accountLinkingService.closeCongrats()
      }
    }
  }

  const existAccount: ICreationExistComponent = {
    "otp": {
      component: <ExistAccountOtpStep />,//TODO maybe separate component needed
      title: t("auth.modal.accountCreation.exist.otp.title"),
      text: <Trans
        i18nKey="auth.linkAccount.step2.text"
        components={{ white: <span /> }}
        values={{ email }}
      />,
      onClose: () => accountLinkingService.setIsAccountCreationModalOpen(false)
    },
    "choose": {
      component: <ChooseAccountStep />,
      title: t("auth.modal.accountCreation.exist.choose.title"),
      text: <Trans
        i18nKey="auth.modal.accountCreation.exist.choose.text"
        components={{ white: <span /> }}
        values={{ email }}
      />,
      steps: { totalSteps: 2, currentStep: 1 },
      onClose: () => { }
    },
    "confirmation": {
      component: <ConfirmationAccountStep />,
      title: t("auth.modal.accountCreation.exist.confirm.title"),
      steps: { totalSteps: 2, currentStep: 2 },
      onClose: () => { }
    },
    "congrats": {
      component: <Button
        onClick={accountLinkingService.resetState}
        size="large"
        variant="secondary"
        isUppercased
      >{t("global.close")}</Button>,
      title: t("auth.linkAccount.step.congrats.title"),
      text: t("auth.linkAccount.step.congrats.text"),
      onClose: () => {
        accountLinkingService.closeCongrats()
      }
    }
  }

  const emailStep = {
    component: <AccountCreationStepEmail />,
    title: t("auth.modal.accountCreation.step.email.title"),
    text: t("auth.modal.accountCreation.step.email.text"),
    onClose: () => accountLinkingService.setIsAccountCreationModalOpen(false)
  }

  const step: Record<AccountCreationType, ModalContentData> = {
    "emailStep": emailStep,
    "newAccount": newAccount[accountCreationSteps],
    "existAccount": existAccount[accountCreationExistSteps]
  }

  return <ConnectEmailMainModalWrapper
    open={isAccountCreationModalOpen}
    title={step[accountCreationType].title}
    text={step[accountCreationType].text}
    steps={step[accountCreationType].steps}
    onClose={step[accountCreationType].onClose}
  >

    {step[accountCreationType].component}
  </ConnectEmailMainModalWrapper>
}