import React, {FC, ReactElement} from 'react';
import {Text} from "../../Text/Text";
import cn from "classnames";
import {Trans} from "react-i18next";
import './NotAuthAsideContent.scss';

interface INonAuthLayoutAsideContentProps {
  title: string;
  text?: ReactElement|string|null;
  badge?: ReactElement|string|null;
}

export const NotAuthAsideContent: FC<INonAuthLayoutAsideContentProps> = ({
  title,
  text,
  badge
}) => {
  return (
    <div className="not-auth-aside-content">
      {badge && <span className="not-auth-aside-content__badge">{badge}</span>}

      <h1
        className={
          cn("not-auth-aside-content__title", text && 'not-auth-aside-content__title--secondary')
        }>
        <Trans i18nKey={title} components={{b: <b/>, br: <br/>}}/>
      </h1>

      {text && <Text className="not-auth-aside-content__text">{text}</Text>}
    </div>
  );
};
