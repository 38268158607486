import { Abi, zeroAddress } from "viem";
import merkleAbi from "./merkleDistributor/MerkleDistributor.json";
import calculatorAbi from "./tenetCalculator/TenetCalculator.json";
import tokenAbi from "./erc20/Erc20ABI.json";
import stakingAbi from "./tenetStaking/TenetStakingNative.json";
import { MERKLE_ADDRESSES } from "../../raffles";
import withdrawAbi from "../contracts/withdraw/withdraw.json";
import { isMainnet } from "./constants";

//TESTNET
const tokenTestnet = "0x5fE5A143E62854CBdF878aE68d31B0F1A68975E6";
const stakingTestnet = "0xC7d119143577d4cec2f6517623A78fB22d08F7c3";
const calculatorTestnet = "0x152B386537b9539E66bEC3d2FfC8AecB38a8cF1B";
const withdrawTestnet = "0x29fD7484D0ADEF5124B1200D54265980C6E883DE";

//MAINNET
const token = "0xd795eb12034C2b77d787a22292c26fab5f5C70Aa";
const staking = "0x1729F8Cc02237bD360c94c15a8ee41ab724f19fB";
const calculator = "0x73f003320a096960c1fe08dbBC1566e321125141";
const withdraw = "0xAC049C76F98314d051d7B2692988a39BdE2558c2";

const tokenAddress = isMainnet ? token : tokenTestnet;
const stakingAddress = isMainnet ? staking : stakingTestnet;
const calculatorAddress = isMainnet ? calculator : calculatorTestnet;
const withdrawAddress = isMainnet ? withdraw : withdrawTestnet;

type ContractData = {
  abi: unknown[] | Abi;
  address: `0x${string}`;
  addresses?: { [key: string]: `0x${string}` };
};

type IContracts = "merkleDistributor" | "staking" | "calculator" | "token" | "withdraw";
type ContractsData = Record<IContracts, ContractData>;

export const CONTRACTS_DATA: ContractsData = {
  merkleDistributor: {
    abi: merkleAbi,
    address: zeroAddress,
    addresses: MERKLE_ADDRESSES,
  },
  staking: {
    abi: stakingAbi,
    address: stakingAddress,
  },
  calculator: {
    abi: calculatorAbi,
    address: calculatorAddress,
  },
  token: {
    abi: tokenAbi,
    address: tokenAddress,
  },
  withdraw: {
    abi: withdrawAbi,
    address: withdrawAddress,
  },
};
