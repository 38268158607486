import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AppRoutes } from "../constants/routes";
import BaseLayout from "../layouts/base-layout/BaseLayout";

interface PropsTypes {
  children: React.ReactNode;
  excludedRoutes?: string[];
}

export const PrivateRoute = ({ children, excludedRoutes }: PropsTypes) => {
  const accessToken = localStorage.getItem("accessToken");
  const { pathname } = useLocation();
  const excludedCondition = !excludedRoutes?.includes(pathname);

  if (!accessToken && pathname !== AppRoutes.login && excludedCondition) {
    return <Navigate to={AppRoutes.login} replace />;
  }

  if (accessToken && pathname === AppRoutes.login) {
    return <Navigate to={AppRoutes.myProfile} replace />;
  }

  if (!children) {
    return <Outlet />
  }
  return pathname === AppRoutes.login || pathname === AppRoutes.setup ?
    <>{children}</> : <BaseLayout>{children}</BaseLayout>;
};
