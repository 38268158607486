import React from "react";

export const PurpleStackingGradient = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      {...props}
      width="365"
      height="87"
      viewBox="0 0 365 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" filter="url(#filter0_f_5004_33095)">
        <ellipse
          cx="36.5"
          cy="167"
          rx="254.5"
          ry="130"
          fill="url(#paint0_radial_5004_33095)"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_5004_33095"
          x="-292"
          y="-37"
          width="657"
          height="408"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="37"
            result="effect1_foregroundBlur_5004_33095"
          />
        </filter>
        <radialGradient
          id="paint0_radial_5004_33095"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.5 167) rotate(90) scale(130 254.5)"
        >
          <stop stopColor="#B364D8" />
          <stop offset="1" stopColor="#B364D8" />
        </radialGradient>
      </defs>
    </svg>
  );
};
