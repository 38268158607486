import { TableCell } from "./TableCell";
import { AppRoutes } from "../../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { createRoutingLink } from "../../../../../utils/createRoutingLink";
import cn from "classnames";
import "../styles/TableRow.scss";
import { transformNumber } from "../../../../../utils/transformNumber";


export function TableRow({
  points,
  className,
  username,
  iconUrl,
  id,
  createdAt,
}: {
  id: string;
  username: string;
  points: number;
  iconUrl: string;
  createdAt: string;
  className?: string;
}) {
  const navigate = useNavigate();

  return (
    <button
      className={cn(
        "referral-table-row",
        className
      )}
      onClick={() =>
        navigate(createRoutingLink(AppRoutes.profileById, { userID: id }))
      }
    >
      <TableCell
        className="referral-table-row__cell referral-table-row__cell--user-dto"
        value={
          <>
            <div className="user-avatar referral-table-row__user-img">
              {iconUrl ? (
                <img src={iconUrl} alt={username} />
              ) : (
                <span className="user-avatar__no-avatar">?</span>
              )}
            </div>

            <div className="referral-table-row__data-inner">
              <p className="referral-table-row__data">
                {username}
              </p>
            </div>
          </>
        }
      />

      <TableCell
        className="referral-table-row__cell referral-table-row__cell--points"
        value={
          <p className="referral-table-row__table-text">{`${transformNumber(Math.floor(points || 0))} PIX`}</p>
        }
      />
    </button>
  );
}
