import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import tonIcon from "../../../../../assets/icons/tonIcon.png";
import { Button } from "../../../../../components/Buttons/Button";
import { useWindowWidth } from "../../../../../hooks/useWindowWidth";
import { QuestionMark } from "../../../../../newComponents/Icons/QuestionMark";
import { userStore } from "../../../../../services/user/store";
import { NFT_SLIDER_IMAGES } from "../../constants";
import { charactersService } from "../../service";
import { charactersStore } from "../../store";
import css from "./Claimer.module.scss";
import { rouletteSpinnerStore } from "./RouletteSpinner/service";
import { StaticSpinner } from "./RouletteSpinner/StaticSpinner";

const mobileBreakPoint = 576

export const Claimer = () => {
	const { t } = useTranslation()

	const { actions } = rouletteSpinnerStore()

	const [rouletteLoading, setRouletteLoading] = useState(false)

	const { isAbleToNewCalim, nftCollectionCurrent } = charactersStore()
	const userId = userStore(state => state.user?.id)

	const [sliderMode, setSliderMode] = useState<"static" | "roulette">("roulette")

	const friendlyTonAddress = useTonAddress();
	const [tonConnectUI] = useTonConnectUI();

	const windowWidth = useWindowWidth()

	// const [nftsList, setNftsList] = useState(NFT_SLIDER_IMAGES)

	const isMobile = windowWidth < mobileBreakPoint

	// const getInitialPos = (contSize: number, slideSize: number) => {
	// 	if (slideSize - slideSize * 3 < 0) {
	// 		return -(slideSize - (contSize - slideSize) / 2) - slideSize
	// 	} else {
	// 		return (slideSize - (contSize - slideSize) / 2) - slideSize * 2
	// 	}
	// }

	// const rollRoulette = () => {
	// 	let interval = 0
	// 	const slidesSwipeCount = 25
	// 	for (let i = 0; i < slidesSwipeCount; i++) {

	// 		interval = interval + 50 + i * i / 3.5
	// 		setTimeout(() => {
	// 			if (i === (slidesSwipeCount - 3)) {
	// 				setNftsList(prev => {
	// 					const newList = [...prev]
	// 					newList[isMobile ? 4 : 6] = {
	// 						imageUrl: "https://dashboard-images.pixelverse.xyz/unknown_nft.png",
	// 						index: "??????",
	// 						active: false,
	// 						isPrize: true
	// 					}
	// 					return newList
	// 				})
	// 				setTimeout(() => {
	// 					setNftsList(NFT_SLIDER_IMAGES)
	// 				}, 5000)
	// 			}
	// 			if (i === (slidesSwipeCount - 1)) {
	// 				setTimeout(() => {
	// 					charactersService.setIsNftCongratsModalOpen(true)
	// 					setRouletteLoading(false)
	// 				}, 2000)

	// 			}
	// 			if (slidesContainerRef.current) {
	// 				const slides = slidesContainerRef.current.getElementsByTagName("li")
	// 				if (isMobile) {
	// 					slides[0].classList.add(css.hideLeft)
	// 					slides[0].style.transitionDuration = `${50 + i * i / 3.5}ms`
	// 					slides[0].style.transitionTimingFunction = "linear"
	// 				} else {
	// 					for (let j = 0; j < slides.length - 3; j++) {
	// 						slides[j].style.transitionDuration = `${50 + i * i / 3.5}ms`
	// 						slides[j].style.transitionTimingFunction = "linear"

	// 					}
	// 				}
	// 			}
	// 			if (isMobile) {
	// 				setTimeout(() => {
	// 					setNftsList(prev => {
	// 						const clone = [...prev]
	// 						const first = clone[0]
	// 						if (slidesContainerRef.current) {
	// 							const slides = slidesContainerRef.current.getElementsByTagName("li")
	// 							slides[0].classList.remove(css.hideLeft)
	// 						}
	// 						return [...prev.slice(1), first]
	// 					})
	// 				}, 45 + i * i / 3.5)
	// 			} else {
	// 				setNftsList(prev => {
	// 					const clone = [...prev]
	// 					const first = clone[0]
	// 					return [...prev.slice(1), first]
	// 				})
	// 			}

	// 		}, interval)
	// 	}
	// }

	// useEffect(() => {

	// 	if (slidesContainerRef.current && sliderContainerRef.current) {
	// 		switch (sliderMode) {
	// 			case "static":
	// 				break;
	// 			case "roulette":
	// 				slidesContainerRef.current.classList.remove(css.animated)
	// 				slidesContainerRef.current.style.top = isMobile ? "" : "0px"
	// 				if (isMobile) {
	// 					slidesContainerRef.current.style.left = `${getInitialPos(
	// 						sliderContainerRef.current.offsetWidth,
	// 						slidesContainerRef.current.offsetHeight
	// 					)}px`
	// 				}

	// 				clearInterval(intervalId.current)
	// 				break
	// 		}
	// 	}
	// }, [sliderMode, isMobile])

	// useEffect(() => {
	// 	if (sliderContainerRef.current && slidesContainerRef.current) {
	// 		if (isMobile) {
	// 			const slider = sliderContainerRef.current
	// 			const slideSize = isMobile ? slider.offsetHeight : slider.offsetWidth

	// 			slidesContainerRef.current.style.left = `${-slideSize + (slider.offsetWidth - slideSize) / 2}px`
	// 		} else {
	// 			slidesContainerRef.current.style.left = "0px"
	// 		}
	// 	}
	// }, [isMobile, windowWidth, sliderMode])

	useEffect(() => {
		if (friendlyTonAddress) {
			sliderMode === "static" && setSliderMode("roulette")
		} else {
			sliderMode === "roulette" && setSliderMode("static")
		}
	}, [friendlyTonAddress, sliderMode])

	const handleSpin = async () => {
		if (userId) {
			setRouletteLoading(true)
			await charactersService._generateNft(userId, friendlyTonAddress)
			actions.roll()
		}
	}

	const handleClickInfo = () => {
		window.open("https://docs.pixelverse.xyz/pixelverse/pixelverse-bots-black-puma", "_blank")
	}

	return <div className={css.wrapper}>
		<div className={css.wrapper_inner}>
			<div className={css.wrapper_roulette}>

				{/* {sliderMode === "roulette" ? <div
					className={
						classNames(
							css.roulette_wrapper,
							sliderMode === "roulette" ? css.roulette : "",
							windowWidth < 576 ? css.mobile : ""
						)
					}
					ref={sliderContainerRef}
				>
					<ul
						className={css.nft_list}
						ref={slidesContainerRef}
					>
						{nftsList.map((el, i) => <li
							attr-index={el.index}
							key={el.imageUrl + el.index + i}
						>
							<img src={el.imageUrl} alt="nft" />
						</li>)}
					</ul>
				</div> : null} */}
				{rouletteLoading ? <StaticSpinner
					mode="spinner"
					direction={isMobile ? "horizontal" : "vertical"}
					slideChangeSpeedMs={500}
					items={NFT_SLIDER_IMAGES
						.map(i => ({ id: i.imageUrl, element: <img src={i.imageUrl} alt="nft" /> }))
					}
					targetItem={{
						id: "https://dashboard-images.pixelverse.xyz/unknown_nft.png",
						element: <img src={"https://dashboard-images.pixelverse.xyz/unknown_nft.png"} alt="dummy" />
					}}
					rollEndCallback={() => {
						charactersService.setIsNftCongratsModalOpen(true)
						setRouletteLoading(false)
					}}
				/> : <StaticSpinner
					direction={isMobile ? "horizontal" : "vertical"}
					slideChangeSpeedMs={1000}
					items={NFT_SLIDER_IMAGES
						.map(i => ({ id: i.imageUrl, element: <img src={i.imageUrl} alt="nft" /> }))
					}
				/>}



				<div className={css.wrapper_roulette_info}>
					<h3>{nftCollectionCurrent?.name || t("claim.characters.claim.title")}</h3>
					<div className={css.wrapper_roulette_info_price}>
						<img src={tonIcon} alt="ton-icon" />
						<span>{nftCollectionCurrent?.price || 0} TON</span>
					</div>
				</div>

			</div>
			<div className={css.wrapper_buttons}>
				<Button
					size="large"
					onClick={handleClickInfo}
					iconButton>
					<QuestionMark />
				</Button>
				{friendlyTonAddress ?
					<Button
						variant="secondary"
						size="large"
						isUppercased
						onClick={(isAbleToNewCalim && !rouletteLoading) ? handleSpin : () => { }}
						disabled={!isAbleToNewCalim || rouletteLoading}
						isLoading={rouletteLoading}
					>
						{isAbleToNewCalim ? t("claim.characters.claim.button.spin") : t("claim.characters.claim.button.not-able-to-claim")}
					</Button> :
					<Button
						onClick={() => tonConnectUI.openModal()}
						variant="secondary"
						size="large"
						isUppercased
					>{t("claim.characters.claim.button.wallet")}</Button>
				}
			</div>
		</div>
	</div>
}
