import { FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../components/Buttons/Button";
import { OTPCodeInput } from "../../../../../login/components/OTPCodeInput/OTPCodeInput";
import accountLinkingService from "../../../../../login/services/accountLinkingService";
import { useAccountLinkingStore } from "../../../../../login/store/accountLinkingStore";
import { useLoginStore } from "../../../../../login/store/loginStore";
import rootModalCss from "../../MainModalComponent/ConnectEmailMainModalWrapper.module.scss"

const MAX_OTP_SYMBOLS = 6;

export const ExistAccountOtpStep = () => {
  const { t } = useTranslation();

  const {
    email,
    otpErrorMessage,
    otpRequestLoading,
    getAccountDataLoading,
  } = useAccountLinkingStore();

  const { otpCode } = useLoginStore()

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await accountLinkingService.linkExistingAccountToStepChoose(email, otpCode)
  };

  useEffect(() => {
    if (otpCode.length === MAX_OTP_SYMBOLS) {
      accountLinkingService.linkExistingAccountToStepChoose(email, otpCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpCode]);

  const isSubmitDisabled = otpCode.length < MAX_OTP_SYMBOLS || Boolean(otpErrorMessage) || getAccountDataLoading;

  return <form
    id='exists-form-step-2'
    onSubmit={!isSubmitDisabled ? onSubmit : undefined}
  >
    <p className="login-step__code-label">{t("login.enter-code")}</p>
    <OTPCodeInput className={rootModalCss.customOtpCodeInput} />

    <div className="account-email-linking-modal__buttons">
      <Button
        onClick={accountLinkingService.backFromExistAccountOtp}
        isLoading={otpRequestLoading}
        size="large"
        isUppercased
      >
        {t("auth.linkAccount.step2.button.back")}
      </Button>
      <Button
        form="exists-form-step-2"
        type="submit"
        disabled={isSubmitDisabled}
        isLoading={otpRequestLoading}
        variant="secondary"
        size="large"
        isUppercased
      >
        {t("auth.linkAccount.step2.button.verify")}
      </Button>
    </div>
  </form>
}