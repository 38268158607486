import { Logo } from "../../../../assets/iconComponents/Logo";
import PixelatedBordersWrapper from "../../../../components/PixelatedBordersWrapper";
import SpiritIcon from "../../../../assets/icons/spiritIcon.svg";
import ScanIcon from "../../../../assets/icons/scanIcon.svg";
import { mobileBreakPoint } from "../../../../constants/breakpoints";
import { FooterItem } from "./FooterItem";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Welcome = () => {
  const isMobile = window.innerWidth < mobileBreakPoint;
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between items-center pt-[20px] md:pt-[32px] pb-[16px] md:pb-[24px] border-b-[1px] border-b-[#232330] mb-[72px] w-full">
        <Logo width={isMobile ? "84" : "130"} height={isMobile ? "30" : "46"} />
        <Link
          to="/"
          className="text-[#FFFFFF99] text-[0.6rem] md:text-[100%] capitalize"
        >
          {t("generation.welcome.back")}
        </Link>
      </div>

      <h1 className="text-[1rem] md:text-[3rem] capitalize mb-[24px]">
        {t("generation.welcome.title.1")} <br />{" "}
        {t("generation.welcome.title.2")} <br />{" "}
        {t("generation.welcome.title.3")}
      </h1>

      <p className="max-w-[330px] md:max-w-[644px] mb-[40px] text-[#FFFFFF99]">
        {t("generation.welcome.description")}
      </p>

      <PixelatedBordersWrapper
        color="#EEA12B"
        width={4}
        className="max-w-fit [&&&]:h-[56px] mb-[36px]"
      >
        <button className="text-[#EEA12B] h-[46px] px-[56px]">
          {t("generation.welcome.generate")}
        </button>
      </PixelatedBordersWrapper>

      <div className="flex-grow"></div>

      <div className="flex w-full md:justify-start justify-around gap-[30px] md:gap-[56px] mt-auto items-start pt-[24px] pb-[20px] md:pb-[40px] border-t-[1px] border-t-[#232330]">
        <FooterItem
          title={t("generation.welcome.footer.generation.title")}
          description={t("generation.welcome.footer.generation.description")}
          icon={SpiritIcon}
        />
        <FooterItem
          title={t("generation.welcome.footer.regeneration.title")}
          description={t("generation.welcome.footer.regeneration.description")}
          icon={ScanIcon}
        />
      </div>
    </>
  );
};
