import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import loginBg1 from "../../assets/images/loginBg1.png";
import loginBg2 from "../../assets/images/loginBg2.png";
import { NotAuthAsideContent, NotAuthAsideHeader } from "../../newComponents/NonAuth";
import { NotAuthLayout, NotAuthLayoutAside, NotAuthLayoutContent } from "../../newLayouts/NotAuthLayout";
import { actionPointsStore } from "../../services/actionPoints/store";
import { TelegramAuthData } from "../../services/user/service";
import { LoginStep1 } from "./components/LoginStep/LoginStep1";
import { LoginStep2 } from "./components/LoginStep/LoginStep2";
import { SignUpTypeSelectModal } from "./components/SignUpTypeSelectModal/SignUpTypeSelectModal";
import './index.scss';
import loginService from "./services/loginService";
import { useLoginStore } from "./store/loginStore";

export const Login = () => {
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const { isOTPScreen } = useLoginStore();
	const refCode = searchParams.get('ref');

	const referralPointsReward = actionPointsStore(
		useShallow(({ actionPoints }) => actionPoints?.ENTER_REFERRAL_CODE || 30)
	);

	const asideTitle = !refCode ? t("auth.welcome") : t("auth.congrats");

	const asideText = refCode
		? <Trans
			i18nKey={"auth.signup.congrats.text"}
			values={{ points: referralPointsReward }}
		/>
		: null;

	const badgeText = refCode
		? <Trans
			i18nKey={"auth.signup.congrats.badge"}
			values={{ points: referralPointsReward }}
			components={{ b: <b /> }}
		/>
		: null;

	useEffect(() => {
		const telegramData = searchParams.get('telegram');

		if (!!telegramData) {
			const parsedTelegramData: TelegramAuthData = JSON.parse(telegramData);

			loginService.loginWithTelegram(parsedTelegramData, refCode).catch(console.warn);
		}
	}, [searchParams]);

	return (
		<NotAuthLayout className="login-page">
			<NotAuthLayoutAside>
				<NotAuthAsideHeader />

				<NotAuthAsideContent
					badge={badgeText}
					title={asideTitle}
					text={asideText}
				/>

				<div className="login-page__image-wrapper">
					<img
						className="login-page__image"
						src={refCode ? loginBg1 : loginBg2}
						alt="Decoration background"
						loading="lazy"
					/>
				</div>
			</NotAuthLayoutAside>

			<NotAuthLayoutContent>
				{!isOTPScreen
					? <LoginStep1 />
					: <LoginStep2 />
				}
			</NotAuthLayoutContent>
			<SignUpTypeSelectModal />
		</NotAuthLayout>
	);
};