import styles from "./Streak.module.scss"
import { Card } from "../../../../components/Card/Card"
import { Rewards } from "../Rewards/Rewards"
import { usePetsStore } from "../../store/petsStore"
import { petsService } from "../../service/service"
import { useUserStore } from "../../../../services/user/store"
import { useMemo } from "react"
import { IPet, IUserPet } from "../../types"


export const Streak = () => {
  const user = useUserStore();
  const {
    pets,
    globalStreak,
    claiming,
    reward,
    rewardAction,
  } = usePetsStore();

  const userPet = useMemo(() => {
    const userPet = user.user?.pet;

    const originalPet = pets.find((item) => item.id === user.user?.pet?.petId);

    if (userPet && originalPet) {
      return { ...originalPet, ...userPet } as IPet & IUserPet;
    }
  }, [pets, user]);

  return <Card className={styles.card_root}>
    <Rewards
      isPetOnUse={!!user.user?.pet}
      claiming={claiming}
      reward={reward}
      globalStreak={globalStreak}
      rewardAction={rewardAction.map((item) => ({
        ...item,
        reward: item.reward * (userPet?.multiplier || 1),
      }))}
      onClaim={() => petsService.claimReward()}
    />
  </Card>
}