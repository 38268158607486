import { useTranslation } from "react-i18next";
import Checkbox from "../../../../components/Checkbox";
import { userService } from "../../../../services/user/service";
import { SocialName } from "../../../../services/user/types";
import { SocialButton } from "./SocialButton";
import styles from "./SocialEdit.module.scss"

export const SocialEdit = ({
  social,
  connectionReward,
  connectedName,
  isRewardRecieved,
  handleConnect,
  onSocialNetworksVisibilityChange,
  error,
  wrapperCalssName
}: {
  social: SocialName;
  connectionReward?: number;
  connectedName?: string | null;
  isRewardRecieved: boolean;
  handleConnect: () => void;
  onSocialNetworksVisibilityChange: (
    social: SocialName,
    isShown: boolean
  ) => void;
  error?: string;
  wrapperCalssName?: string
}) => {
  const isConnected = !!connectedName;

  const isSocialHidden = !userService.getSocialVisibility(social);

  const { t } = useTranslation();

  return (
    <div>
      <SocialButton
        social={social}
        connectionReward={connectionReward}
        handleConnect={handleConnect}
        connectedName={connectedName}
        isRewardRecieved={isRewardRecieved}
        wrapperClassName={wrapperCalssName || ""}
      />
      <div className={styles.checkbox}>
        <Checkbox
          disabled={!isConnected}
          checked={isSocialHidden}
          label={`${t("profile.info.socials.hide")} ${social}`}
          onChange={(checked) => {
            onSocialNetworksVisibilityChange(social, !checked);
          }}
        />
      </div>

      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
