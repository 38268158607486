import { useTranslation } from "react-i18next";
import css from "../StakingPage.module.scss";
import { stakingStore } from "../store";
import { transformNumber } from "../../../utils/transformNumber";
import { Convexity } from "../../../assets/iconComponents/Convexity";
import cn from "classnames";
import { useMemo } from "react";
import { getRewardPerDay } from "../../../utils/time";
import { roundNumber } from "../../../utils/roundNumber";

export const StakingTickets = () => {
  const { t } = useTranslation();
  const { stakingInfo, stakers } = stakingStore();

  const dailyIncome = useMemo(() => {
    const genesisIncome = stakingInfo.incomeTicketsPerDay;
    const stakedIncome = stakers.reduce((sum, curr) => {
      return sum + Number(getRewardPerDay(curr.rewardPerSecond).toFixed(2) || 0);
    }, 0);

    return genesisIncome + stakedIncome;
  }, [stakingInfo.incomeTicketsPerDay, stakers]);

  const totalTickets = useMemo(() => {
    const genesisTickets = stakingInfo.stakedCoins;

    const totalStakedTickets = stakers.reduce((sum, { reward }) => {
      return sum + reward.toNumber();
    }, 0);

    return genesisTickets + totalStakedTickets;
  }, [stakingInfo.stakedCoins, stakers]);

  return (
    <div className={css.ticketsBody}>
      <hr className={css.ticketsHrTop} />
      <hr className={css.ticketsHrBottom} />
      <Convexity className={css.ruffleConvexity} />
      <Convexity className={cn(css.ruffleConvexityBottom)} />
      <div className={css.left}>
        <h3>{t("staking.tickets.drop")}</h3>
        <span className={css.value}>{transformNumber(roundNumber(totalTickets || 0, 2))}</span>
      </div>
      <div className={css.right}>
        <h3>
          {t("staking.tickets.drop")}
          <br />
          <span>{t("staking.tickets.drop.day")}</span>
        </h3>
        <span className={css.value}>{transformNumber(roundNumber(dailyIncome, 2))}</span>
        {/* <button className={css.boostBtn}>
          {t("staking.tickets.boost")}
          <svg
            className={css.btnArrow}
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="mask0_6274_19463" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
              <rect y="0.679199" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_6274_19463)">
              <path d="M12.6 12.6792L8 8.0792L9.4 6.6792L15.4 12.6792L9.4 18.6792L8 17.2792L12.6 12.6792Z" fill="white" />
            </g>
          </svg>
        </button> */}
      </div>
    </div>
  );
};
