import { useMemo } from "react";
import css from "../../../styles.module.scss";

import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/Buttons/Button";
import { useUserStore } from "../../../../../services/user/store";
import { petsService } from "../../../service/service";
import { IPet } from "../../../types";
import classNames from "classnames";
import { getFontClass } from "../../../../../utils/getFontClass";
import { usePetsStore } from "../../../store/petsStore";
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader";
import { AvatarCard } from "../../../../profile/components/Avatar/AvatarSelection/AvatarCard";

interface PropsTypes {
  open: boolean;
  onClose: () => void;
  selectedPet: IPet;
}

export const UpgradeModal = ({ open, onClose, selectedPet }: PropsTypes) => {
  const { t, i18n } = useTranslation();
  const { user } = useUserStore();
  const { isLevelUpLoading } = usePetsStore();

  const isLevelUpOnCoolDown = useMemo(() => {
    if (selectedPet?.userPet?.levelUppedAt) {
      const lastLevelUpTime = new Date(selectedPet.userPet.levelUppedAt);
      const hoursSinceLastLevelUp =
        (new Date().getTime() - lastLevelUpTime.getTime()) / (1000 * 60 * 60);
      return hoursSinceLastLevelUp < 24;
    }
    return false;
  }, [selectedPet]);

  const isUpgradeDisabled = useMemo(() => {
    return (
      (user?.spendablePoints?.amount || 0) <
      (selectedPet.userPet?.levelUpPrice || 0) ||
      isLevelUpOnCoolDown ||
      isLevelUpLoading
    );
  }, [user, selectedPet, isLevelUpOnCoolDown, isLevelUpLoading]);

  const handleUpgrade = async () => {
    if (selectedPet.userPet?.id) {
      await petsService.levelUp(selectedPet.userPet.id);
      onClose();
    }
  };

  return (
    <ModalWithHeader open={open} onClose={onClose} title={t("pets.upgrade")}>
      <div className={css.confirmSellPetModalWrapper}>
        <div className={css.confirmSellPetInfo}>
          <div className={css.left}>
            <AvatarCard
              isPet
              className={css.sellAvatarCard}
              level={selectedPet.userPet?.level}
              maxLevel={10}
              isBought={true}
              noDecorations
              avatar={selectedPet}
            />
          </div>
          <div className={css.right}>
            <h4 className={css.confirmSellPetTitle}>
              {t("pets.modal.exciting")}
            </h4>
            <p className={css.confirmSellPetSubtitle}>
              {t("pets.modal.improve")}
            </p>

            <p
              className={classNames(
                "mt-4 sm:mt-14 sm:mb-10 w-full sm:pt-4",
                getFontClass(i18n.language)
              )}
            >
              <span className="md:text-nowrap">
                {t("pets.modal.boost")}:{" "}
                <span className="text-nowrap">
                  {selectedPet.userPet?.reward}{" "}
                  <span className="text-[#83D987]">
                    (+
                    {(selectedPet.userPet?.nextLevelPet?.reward || 0) -
                      (selectedPet.userPet?.reward || 0)}
                    )
                  </span>{" "}
                  <span className="text-[#FFFFFF99]">PIX</span>
                </span>
              </span>
            </p>
          </div>
        </div>

        <div className={`${css.btnsWrapper}`}>
          <Button
            onClick={onClose}
            size="large"
            isUppercased
          >
            {t("buttons.cancel")}
          </Button>
          <Button
            onClick={handleUpgrade}
            disabled={isUpgradeDisabled}
            isLoading={isLevelUpLoading}
            size="large"
            isUppercased
            variant="secondary"
          >
            {selectedPet.userPet?.levelUpPrice} PIX
          </Button>
        </div>
      </div>
    </ModalWithHeader>
  );
};
