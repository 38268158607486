import {BaseService} from "../../services/BaseService";
import {$api} from "../../services/interceptor";
import {IReferralList, IReferralsStats, IReferralsStore, referralsStore} from "./referrals.store";


export const REFERRALS_BASE_URI = '/referrals/my/';
export const REFERRALS_STATS_URI = REFERRALS_BASE_URI + 'stats';
export const REFERRALS_STATS_LIST = REFERRALS_BASE_URI + 'list';
export const RESPONSE_STATUS_CODE_SUCCESS = 200;


class ReferralsApi extends BaseService<IReferralsStore> {
  constructor() {
    super(referralsStore);
  }

  async getReferralsStats() {
    this.setState({ isReferralsStatsLoading: true });

    try {
      const { data, status } = await $api.get<IReferralsStats>(REFERRALS_STATS_URI);
      this.setState({ referralsStats: data });

      if (status === RESPONSE_STATUS_CODE_SUCCESS) {
        this.setState({ isReferralsStatsSuccess: true });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ isReferralsStatsLoading: false })
    }
  }

  async getReferralsList() {
    this.setState({ isReferralsListLoading: true });

    try {
      const { data, status } = await $api.get<IReferralList>(REFERRALS_STATS_LIST);
      this.setState({ referralsList: data });

      if (status === RESPONSE_STATUS_CODE_SUCCESS) {
        this.setState({ isReferralsListSuccess: true });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ isReferralsListLoading: false })
    }
  }
}

export const referralsService = new ReferralsApi();
