import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Buttons/Button";
import { utc_timestamp } from "../../../../utils/formatDate";
import css from "./Rewards.module.scss";
import { IPetsStore } from "../../types";
import { RewardsList } from "./RewardsList";

interface PropsTypes {
  claiming: boolean;
  isPetOnUse: boolean;
  reward: IPetsStore["reward"];
  globalStreak: IPetsStore["globalStreak"];
  rewardAction: IPetsStore["rewardAction"];
  onClaim: () => Promise<boolean>;
}

export const Rewards = ({
  claiming,
  rewardAction,
  onClaim,
  isPetOnUse,
  reward,
  globalStreak,
}: PropsTypes) => {
  const { t } = useTranslation();

  const [remainingTime, setRemainingTime] = useState("");
  const currentLength = reward?.dailyUserActions?.length || 0;
  const currentRewardAction = rewardAction.find(
    (item) => item.order === currentLength + 1
  );
  const nextClaimTime = reward?.nextClaimAfter
    ? new Date(reward.nextClaimAfter).getTime()
    : 0;
  const isAbleToClaim = +utc_timestamp > nextClaimTime;

  useEffect(() => {
    const updateRemainingTime = () => {
      const now = Date.now();
      const timeLeft = nextClaimTime - now;
      if (timeLeft > 0) {
        const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);
        setRemainingTime(
          `${t("buttons.claim")}: ${hours}h ${minutes}m ${seconds}s`
        );
      } else {
        setRemainingTime("");
      }
    };

    updateRemainingTime();
    const intervalId = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextClaimTime]);

  const splittedActions = rewardAction.map((action, i) => ({
    ...action,
    done: i < currentLength ? true : false,
  }));

  return (
    <div className={css.rewardsWrapper}>
      <div className={css.header}>
        <span className={css.label}>{t("pets.rewards.streak")}</span>
        <span className={css.value}>
          {t("pets.rewards.count")}: <span>{globalStreak}</span>
        </span>
      </div>
      <div className={css.streakWrapper}>
        <RewardsList
          rewardAction={splittedActions}
          currentLength={currentLength}
        />
        <Button
          disabled={claiming || !isAbleToClaim || !isPetOnUse}
          isLoading={claiming}
          onClick={onClaim}
          variant="secondary"
        >
          {remainingTime ||
            t(currentRewardAction?.nameLocalizationKey || "") ||
            rewardAction[0]?.name}
        </Button>
      </div>
    </div>
  );
};
