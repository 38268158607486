import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { ITask } from "../types";
import tasksService from "../service/tasksService";
import { TaskModal } from "./TaskModal";
import { transformNumber } from "../../../utils/transformNumber";
import cn from "classnames";
import { DoneIcon } from "./IconDone";
import { Title } from "../../../newComponents/Title/Title";
import { GoToIcon } from "./IconGoTo";
import { useIsMobileResolution } from "../../../hooks/useIsMobileResolution";
import { Text } from "../../../newComponents/Text/Text";

type IUserTaskProps = {
  task: ITask;
  color: string;
  index: number;
  onClick: () => void;
};

export const UserTask = ({ task, color, index, onClick }: IUserTaskProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobileResolution(1279)

  const { isTaskDone, title, description } = task;

  const [isTaskModalOpen, setIsTaskModalOpen] = useState<boolean>(false);

  const {
    disabled,
    disabledTooltip = "",
    actionHidden,
    actionTitle = "",
  } = tasksService.getUserTaskProps(task.type, task.id);

  return (<>
    <li className={cn("user-task", isTaskDone && "user-task--done")}
      onClick={() => setIsTaskModalOpen(true)}

    >
      <div className="user-task__header-new">
        {isMobile && isTaskDone &&
          <strong className="user-task__status">
            Done
            <DoneIcon />

          </strong>
        }

        <div className="user-task__title-wrapper">
          <Title
            className="user-task__title"
            tagName="h2"
            variant="l-fixed"
          >
            {t(title)}
          </Title>

          {!isMobile && isTaskDone &&
            <strong className="user-task__status user-task__status--desktop">
              Done
              <DoneIcon />

            </strong>
          }

          {!actionHidden && (
            <Tooltip title={t(disabledTooltip)}>
              <button
                className="user-task__goto-button"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                disabled={disabled}
              >
                <GoToIcon />

              </button >
            </Tooltip >
          )}
        </div >

        {!!task.pointsReward && (
          <span className="user-task__reward-new">
            {transformNumber(task.pointsReward)} {t("PIX")}
          </span>
        )}
      </div >

      <Text
        className="user-task__description"
        variant="m"
      >
        <Trans i18nKey={description}
          components={{
            a0: <a href="http://i.bybit.com/12taboIc" target="_blank" rel="noopener noreferrer"> </a>,
            a1: <a href="https://x.com/Bybit_Web3" target="_blank" rel="noopener noreferrer"> </a>,
            br: <br />
          }}
        />
      </Text>
    </li >

    <TaskModal
      color={color}
      task={task}
      num={index + 1}
      open={isTaskModalOpen}
      handleClose={() => setIsTaskModalOpen(false)}
      isProgressHidden
      withoutTitle
      disabled={disabled}
      disabledTooltip={disabledTooltip}
      actionHidden={actionHidden}
      actionTitle={actionTitle}
      onClick={onClick}
    />
  </>);
};
