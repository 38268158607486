import React, {FC, HTMLAttributes} from "react";
import {NavLink} from "react-router-dom";
import {ISvgIcon} from "../../../../../types/icons";
import ElementWithIcon from "../../../../Buttons/ElementWithIcon/ElementWithIcon";
import "./SocialItem.scss"
import cn from "classnames";


interface SocialItemProps extends HTMLAttributes<HTMLLIElement> {
  href: string,
  Icon: ISvgIcon,
}


const SocialItem: FC<SocialItemProps> = ({href, className, Icon}) => {
  return (
    <li className={cn("social-item", className)}>
      <NavLink
        className="social-item__link"
        to={href}
        target="_blank"
        rel="noreferrer"
      >
        <ElementWithIcon Icon={Icon} />
      </NavLink>
    </li>
  );
}


export default SocialItem;
