import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getFontClass } from "../../utils/getFontClass";
import styles from "./Checkbox.module.scss"
import checkIcon from "../../assets/icons/check_small.svg";

interface CheckboxProps {
  label?: string | JSX.Element;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked = false,
  disabled = false,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);
  const { i18n } = useTranslation();

  const toggleCheckbox = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onChange && onChange(newValue);
  };

  return (
    <label
      className={`${styles.label} ${disabled && styles.disabled}`}
      style={disabled ? { opacity: 1 } : {}}
    >
      <div className={styles.relative}>
        <input
          type="checkbox"
          className={styles.hidden}
          checked={isChecked}
          disabled={disabled}
          onChange={toggleCheckbox}
        />
        <div className={`${styles.checkboxIcon} ${isChecked && styles.checked}`}>
          {isChecked && <img src={checkIcon} alt="check" />}
        </div>
      </div>
      <div
        className={`${styles.text} ${getFontClass(i18n.language)}`}
      >
        {label}
      </div>
    </label>
  );
};

export default Checkbox;
