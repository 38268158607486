import { CSSProperties } from "react"

type IHalfCircleSvgProps = {
  progress: number
  lineWidth?: number
  color?: string
  style?: CSSProperties
  className?: string
  width?: number
}
export const HalfCircleProgressBar: React.FC<IHalfCircleSvgProps> = ({
  progress,
  color = "#24242A",
  style = {},
  className = "",
  lineWidth = 10,
  width = 400
}) => {

  const startCoord = width / 2 - lineWidth / 2
  const endCoord = width - lineWidth
  const circleLength = 2 * 3.1415 * width / 2

  const preparedProgress = Math.round(progress / 100 * (circleLength / 2))
  return <svg
    style={{ transform: "rotate(-90deg)", ...style }}
    viewBox={`0 0 ${width} ${width}`}
    width={width}
    height={width}
    className={className}
  >
    <path fill="none" strokeLinecap="round" strokeWidth={lineWidth} stroke={color}
      strokeDasharray={`${preparedProgress},${circleLength}`}
      d={`M${startCoord} ${lineWidth / 2} a ${startCoord} ${startCoord} 0 0 1 0 ${endCoord} a ${startCoord} ${startCoord} 0 0 1 0 -${endCoord}`} />
  </svg>
}