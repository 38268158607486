import { BaseService } from "../BaseService";
import { $api, AUTH_API_URL } from "../interceptor";
import { IActionPointsStore, actionPointsStore } from "./store";
import { EventPoints } from "./types";

class ActionPointsAPI extends BaseService<IActionPointsStore> {
  constructor() {
    super(actionPointsStore);
  }

  getActionPoints = async () => {
    try {
      const response = await $api.get<EventPoints>("/auth/actions-level-points", { baseURL: AUTH_API_URL });
      if (response.status === 200) {
        this.setState({
          actionPoints: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
}

const actionPointsService = new ActionPointsAPI();
export default actionPointsService;
