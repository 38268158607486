import { WalletProvider } from "../../constants/walletProviders";
import { BaseService } from "../../services/BaseService";
import { $api } from "../../services/interceptor";
import { userService } from "../../services/user/service";
import { IWalletStore, walletStore } from "./store";

class WalletAPI extends BaseService<IWalletStore> {
  constructor() {
    super(walletStore);
  }
  connectWallet = async (
    address: string,
    data: string,
    provider: WalletProvider
  ) => {
    this.setState({ isLoading: true });

    try {
      await $api.post("/wallets/connect", {
        address: address,
        signature: data,
        provider,
      });

      await userService.getUserData();
    } catch (error) {
    } finally {
      this.setState({ isLoading: false });
    }
  };

  openWalletModal = () => {
    this.setState({ isWalletModalOpened: true });
  };
  closeWalletModal = () => {
    this.setState({ isWalletModalOpened: false });
  };
}

export const walletService = new WalletAPI();
