import React from "react";

export const FooterItem = ({
  title,
  icon,
  description,
}: {
  title: string;
  icon: string;
  description: string;
}) => {
  const formattedTitle = title.split(" ").map((word, index) => (
    <React.Fragment key={index}>
      {word}
      {index < title.split(" ").length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <>
      <div className="gap-[18px] items-start hidden md:flex">
        <img src={icon} alt="" />
        <div>
          <p className="pb-[8px] text-[0.9rem]">{title}</p>
          <p className="max-w-[208px] text-[0.6rem] text-[#FFFFFF99]">
            {description}
          </p>
        </div>
      </div>

      <div className="gap-[8px] items-start flex flex-col md:hidden">
        <div className="flex gap-[12px] items-start">
          <img src={icon} alt="" className="" />
          <p className=" text-[0.7rem]">{formattedTitle}</p>
        </div>
        <p className="max-w-[150px] text-[0.5rem] text-[#FFFFFF99]">
          {description}
        </p>
      </div>
    </>
  );
};
