import React, {ChangeEvent, InputHTMLAttributes, ReactNode, useEffect, useRef, useState,} from 'react';
import {InputFieldErrors} from "./InputField.types";
import './InputField.scss';
import cn from 'classnames';

export interface IInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  isLoading?: boolean;
  status?: InputFieldErrors | null;
  message?: string|null;
  onChange?: (value: string) => void;
}

export const InputField = (props: IInputProps) => {
  const {
    className,
    value,
    type = 'text',
    name,
    placeholder,
    autoFocus,
    readOnly,
    iconLeft,
    iconRight,
    status,
    label,
    isLoading = false,
    disabled = false,
    message,
    onChange,
    ...otherProps
  } = props;

  const ref = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect((): void => {
    if (autoFocus) {
      setIsFocused(true);
      ref.current?.focus();
    }
  }, [autoFocus]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const onBlur = (): void => {
    setIsFocused(false);
  };

  const onFocus = (): void => {
    setIsFocused(true);
  };

  const cl = cn(
    'input-field',
    iconLeft && 'input-field--addon-left',
    iconRight && 'input-field--addon-right',
    readOnly && 'input-field--readonly',
    isFocused && 'input-field--focused',
    isLoading && 'input-field--loading',
    disabled && 'input-field--disabled',
    status && `input-field--${status}`,
    className,
  );

  return (
    <label className={cl} htmlFor={name}>
      <span className="input-field__label">{label}</span>

      <span className="input-field__wrapper">
        <span className="input-field__container">
          {iconLeft && (
            <span className="input-field__addon-left">
              {iconLeft}
            </span>
          )}

          <input
            ref={ref}
            className="input-field__input"
            type={type}
            value={value}
            id={name}
            name={name}
            readOnly={readOnly}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChangeHandler}
            onFocus={onFocus}
            onBlur={onBlur}
            {...otherProps}
          />

          {iconRight && (
            <span className="input-field__addon-right">
              {iconRight}
            </span>
          )}
        </span>

        {message && (
          <span className="input-field__message">
            {message}
          </span>
        )}
      </span>
    </label>
  );
};
