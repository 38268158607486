import {useSearchParams} from "react-router-dom";

interface IUseQueryParam {
  paramValue: string|null;
}

export const useQueryParam = (paramName: string): IUseQueryParam => {
  const [searchParams] = useSearchParams();

  const targetParamValue = searchParams.get(paramName);

  return {
    paramValue: targetParamValue,
  }
}
