import { healthStore } from "./store";
import { $api } from "../../services/interceptor";
import { AxiosError } from "axios";

class HealthService {
  REQUEST_TIMEOUT = 7000;

  checkIsTecnicalWorks = async () => {

    try {
      const res = await $api.get('/health', { timeout: this.REQUEST_TIMEOUT });
      const isWorking = res.data && res.data.status === "Working";
      if (isWorking) {
        healthStore.setState({ isTecnicalWorks: false, isTecnicalWorksLoading: false });
      } else {
        throw new Error("Service is not working");
      }
    } catch (error) {
      if ((error as AxiosError).response?.status !== 401)
        healthStore.setState({ isTecnicalWorks: true, isTecnicalWorksLoading: false });
    }
  };

  setIsTecnicalWorks = (isTecnicalWorks: boolean) => {
    healthStore.setState({ isTecnicalWorks });
  }
}

export const healthService = new HealthService();
