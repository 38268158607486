import { create, useStore } from "zustand";
import { EarlyNftType, GeneratedNftType, NftCollectionCurrent, NftPropsRaw } from "./types";

export type INftStore = {
  loading: boolean,

  currentNftCollectionLoading: boolean
  nftCollectionCurrent: NftCollectionCurrent | null
  nftCollectionClaimsArray: NftPropsRaw[]
  nftCollectionClaimsCurrent: NftPropsRaw | null
  isAbleToNewCalim: boolean
  isAbleToContinueCalim: boolean

  nftClaimingItem: GeneratedNftType | null

  selectedNftToShow: Partial<NftPropsRaw> | null
  isNftInfoModalOpen: boolean

  isNftCongratsModalOpen: boolean

  earlyNft: {
    dashboard: EarlyNftType | null
    clicker: EarlyNftType | null
  }
}

export const charactersStore = create<INftStore>(() => ({
  loading: false,

  currentNftCollectionLoading: false,
  nftCollectionCurrent: null,

  nftCollectionClaimsArray: [],
  nftCollectionClaimsCurrent: null,

  isAbleToNewCalim: false,
  isAbleToContinueCalim: false,

  nftClaimingItem: null,

  selectedNftToShow: null,
  isNftInfoModalOpen: false,
  isNftCongratsModalOpen: false,

  earlyNft: {
    dashboard: null,
    clicker: null
  }
}));

export const useCharactersStore = () => useStore(charactersStore);
