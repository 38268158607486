import { useTranslation } from "react-i18next";
import css from "../SelectedPetInfo/SelectedPetInfo.module.scss";
import { Button } from "../../../../components/Buttons/Button";

export const ChooseBotButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean
}) => {
  const { t } = useTranslation();

  return (
    <Button
      className={css.btnRight}
      disabled={disabled}
      onClick={onClick}
      variant="secondary"
      size="large"
      isUppercased
    >
      <div className={css.btnBody}>{t("buttons.choose")}</div>
    </Button>
  );
};
