import { SVGAttributes } from "react";

export const NewArrowIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10 19L16 12L10 5" stroke="white" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  );
};
