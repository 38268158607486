import { create, useStore } from "zustand";

export interface UserAvatar {
  id: string
}

export interface IAvatar {
  id: string;
  imageUrl: string;
  iconUrl: string;
  name: string;
  description: string;
  bannerUrl: string;
  nameLocalizationKey: string;
  descriptionLocalizationKey: string;
  price: number;
  userAvatar: UserAvatar
}

export interface IAvatarInernal extends IAvatar {
  isNft: boolean
}

export type Avatars = Array<IAvatar>;

export interface IAvatarsStore {
  avatars: IAvatarInernal[];
  isEditPending: boolean;
  isBuyLoading: boolean;
}

export const avatarsStore = create<IAvatarsStore>(() => ({
  avatars: [],
  isEditPending: false,
  isBuyLoading: false,
}));

export const useAvatarsStore = () => useStore(avatarsStore);
