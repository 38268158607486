import {FC, HTMLAttributes} from 'react';
import {ISvgIcon} from "../../../types/icons";
import './ElementWithIcon.scss';
import cn from "classnames";


interface IButtonWithIconProps extends HTMLAttributes<HTMLSpanElement> {
  Icon: ISvgIcon;
}


const ElementWithIcon: FC<IButtonWithIconProps> = ({Icon, className, ...restProps}) => {
  return (
    <span className={cn("element-with-icon", className)} {...restProps}>
      <Icon />
    </span>
  );
};


export default ElementWithIcon;
