import React from "react";

export const SliderArrow = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 7H1.5M1.5 7L7.5 1M1.5 7L7.5 13"
        stroke={props?.stroke ? props.stroke : "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
