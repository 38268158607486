import img0 from "../../../assets/images/nft/blackPumaSliderMock/0.png";
import img1 from "../../../assets/images/nft/blackPumaSliderMock/1.png";
import img2 from "../../../assets/images/nft/blackPumaSliderMock/2.png";
import img3 from "../../../assets/images/nft/blackPumaSliderMock/3.png";
import img4 from "../../../assets/images/nft/blackPumaSliderMock/4.png";
import img5 from "../../../assets/images/nft/blackPumaSliderMock/5.png";
import img6 from "../../../assets/images/nft/blackPumaSliderMock/6.png";
import img7 from "../../../assets/images/nft/blackPumaSliderMock/7.png";
import img8 from "../../../assets/images/nft/blackPumaSliderMock/8.png";
import img9 from "../../../assets/images/nft/blackPumaSliderMock/9.png";

export const NFT_SLIDER_IMAGES = [
  {
    imageUrl: img0,
    index: `${5}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img1,
    index: `${6}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img2,
    index: `${2}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img3,
    index: `${3}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img4,
    index: `${4}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img5,
    index: `${5}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img6,
    index: `${6}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img7,
    index: `${7}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img8,
    index: `${8}`,
    active: false,
    isPrize: false
  },
  {
    imageUrl: img9,
    index: `${9}`,
    active: false,
    isPrize: false
  },
]