import { useTonConnectUI } from "@tonconnect/ui-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "../../../../../components/Buttons/Button"
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader"
import { charactersService } from "../../service"
import { charactersStore } from "../../store"
import { GeneratedNftType, RarityType } from "../../types"
import { nftClaim } from "../../utils"
import { RarityChip } from "../ClaimedEarly/RarityChip"
import css from "./NftCongratsModal.module.scss"
import { NorthShine } from "./NorthShine"

type INftCongratsModalProps = {
  open: boolean
  onClose: () => void
  nftInfo: Partial<GeneratedNftType> | null
}

export const NftCongratsModal: React.FC<INftCongratsModalProps> = ({
  open,
  onClose,
  nftInfo
}) => {
  const { t } = useTranslation()
  const {
    isAbleToNewCalim,
    nftClaimingItem,
    nftCollectionCurrent
  } = charactersStore()

  const [tonConnectUI] = useTonConnectUI();

  const isCalimDisabled = !isAbleToNewCalim &&
    !(
      nftCollectionCurrent?.price &&
      nftCollectionCurrent?.address &&
      nftClaimingItem?.signature
    )

  const handleClick = async () => {
    if (nftCollectionCurrent?.price && nftCollectionCurrent?.address && nftClaimingItem?.signature) {
      const response = await nftClaim(
        `${nftCollectionCurrent?.price}`,
        nftCollectionCurrent?.address,
        nftClaimingItem?.signature,
        tonConnectUI
      )
      if (response) {
        await charactersService.setClaimStatusToProcessing(nftClaimingItem.id)
      }
    }
  }

  return <ModalWithHeader
    open={open}
    title={t("claim.characters.modal.result.title")}
    onClose={onClose}
    bodyClassName={css.wrapper}
  >
    <>
      <h3 className={css.title}>{t("claim.characters.modal.info.title")}</h3>
      <div className={css.nft}>
        {nftInfo?.claimStatus === "PROCESSING" ? <NorthShine /> : null}
        <img
          className={nftInfo?.claimStatus === "STARTED" ? "" : css.bordered}
          src={nftInfo?.url} alt="nft"
        />
        {nftInfo && "rarity" in nftInfo ? <RarityChip rarity={(nftInfo.rarity as RarityType)} size="large" /> : <div className={css.empty} />}
      </div>
      {nftInfo?.claimStatus === "STARTED" ? <Button
        onClick={handleClick}
        disabled={isCalimDisabled}
        size="large"
        variant="secondary"
      >{t("claim.characters.claim.button.claim")}</Button> : null}
    </>
  </ModalWithHeader>
}