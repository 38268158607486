import { useTasksStore } from "../store/tasksStore";
import {TabsEnum} from "../types";
import { GeneralTasks } from "./GeneralTasks";
import { UserTasks } from "./UserTasks";
import {useIsMobileResolution} from "../../../hooks/useIsMobileResolution";

export const TasksList = () => {
  const { currentTab } = useTasksStore();
  const isMobile = useIsMobileResolution(1279);

  return (
    <>
      {isMobile
        ? (currentTab === TabsEnum.Individual) ? <UserTasks /> : <GeneralTasks />
        : <div className="tasks__lists">
            <UserTasks />
            <GeneralTasks />
          </div>
      }
    </>
  )
};
