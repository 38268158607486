import {MouseEvent, useCallback, useRef, useState} from "react";

export const useToggleSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const toggleSidebar = useCallback((value?: boolean) => {
    if (value === undefined) {
      setIsSidebarOpen((currentState) => !currentState);
    } else {
      setIsSidebarOpen(value);
    }
  }, [setIsSidebarOpen]);


  const handleClickOutsideSidebar = (event: MouseEvent<HTMLDivElement>) => {
    if (sidebarRef.current && sidebarRef.current.contains(event.target as Node)) {
      return;
    }

    setIsSidebarOpen(false);
  };


  return {
    sidebarRef,
    isSidebarOpen,
    toggleSidebar,
    handleClickOutsideSidebar
  };
}
