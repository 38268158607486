import { Label } from "../../../../../components/Labels";
import { useTranslation } from "react-i18next";
import { getFontClass } from "../../../../../utils/getFontClass";
import checkCircle from "../../../../../assets/icons/check_circle1.svg";
import lockIcon from "../../../../../assets/icons/lock.svg";
import { IAvatarInernal, UserAvatar } from "./store";
import styles from "./AvatarCard.module.scss";
import { PriceLabel } from "./PriceLabel";
import classNames from "classnames";
import { useIsMobileResolution } from "../../../../../hooks/useIsMobileResolution";
import { CircleProgressBar } from "../../../../../components/CircleProgressBar/CircleProgressBar";
import { IPet } from "../../../../Pets/types";

type IAvataCardProps = {
  isCurrent?: boolean;
  isSelected?: boolean;
  isBought?: boolean;
  onClick?: () => void;
  small?: boolean;
  userAvatar?: UserAvatar;
  price?: number;
  isEnoughLevel?: boolean;
  level?: number;
  requiredLevel?: number;
  maxLevel?: number;
  className?: string;
  isPet?: boolean;
  noDecorations?: boolean;
  avatar: IAvatarInernal | IPet
  isNft?: boolean
}


export const AvatarCard: React.FC<IAvataCardProps> = ({
  avatar: {
    name,
    nameLocalizationKey,
    imageUrl,
    price,
  },
  userAvatar,
  isCurrent,
  isSelected,
  isBought,
  onClick,
  small,
  level,
  maxLevel,
  requiredLevel,
  isEnoughLevel,
  className,
  isPet = false,
  noDecorations = false,
  isNft = false
}) => {
  const { t, i18n } = useTranslation();

  const isMobileResolution = useIsMobileResolution()

  const locked = isPet ? !userAvatar : !userAvatar

  return (
    <div className={classNames(
      styles.container,
      className || "",
      isNft ? styles.nft : ""
    )}
      onClick={onClick}
    >
      <div
        className={
          classNames(
            styles.content,
            isCurrent && styles.isCurrent,
            isSelected && styles.isSelected,
            small && styles.small,
            isMobileResolution && styles.isMobile
          )
        }
      >
        <div
          className={styles.content_placeholder}
          style={isNft ? { backgroundImage: `url(${imageUrl})` } : {}}
        >
          {!noDecorations && <>
            {isCurrent && (
              <div className={styles.current_label}>
                <Label>Current</Label>
              </div>
            )}
            {level !== undefined && maxLevel && <div className={styles.levelProgress}>
              <CircleProgressBar progress={level * 100 / maxLevel} />
              <span className={styles.levelProgress__value}>{level}</span>
              <span className={styles.levelProgress__label}>lvl</span>
            </div>}
            {(isPet ? !!userAvatar : (isBought || !!userAvatar)) && (
              <div className={styles.checked_icon}>
                <img src={checkCircle} alt="check-icon" />
              </div>
            )}
            {!userAvatar && price !== undefined && (
              <div className={styles.price_label}>
                <PriceLabel amount={price.toString()} />
              </div>
            )}
            {locked ? (
              <div className={styles.checked_icon}>
                <img src={lockIcon} alt="lock-icon" />
              </div>
            ) : requiredLevel ?
              <span>{requiredLevel} lvl</span>
              : null}
          </>}
          <div className={styles.image_placeholder}>
            <img
              src={imageUrl}
              className={`${styles.image} ${small && styles.small} ${locked && !noDecorations && styles.grayscaled}`}
              alt="i"
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.avatar_name,
            getFontClass(i18n.language),
            isCurrent && styles.avatar_name__current,
            locked && styles.avatar_name__locked
          )}
        >
          {isNft ? name : t(nameLocalizationKey || "") || name}
        </div>
      </div>
    </div>
  );
};