import { create, useStore } from "zustand";

export interface IReferalStore {
  errorMessage?: string;
  successMessage?: string;
  isPending: boolean;
}

export const referralStore = create<IReferalStore>(() => ({
  isPending: false,
  errorMessage: "",
  successMessage: "",
}));

export const useReferralStore = () => useStore(referralStore);
