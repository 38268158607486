import React from "react";
import { LoaderIcon } from "../../assets/iconComponents/LoaderIcon";

type ILoaderProps = {
  className?: string,
  color?: string,
  center?: boolean
}

export const Loader: React.FC<ILoaderProps> = ({
  className = "",
  color,
  center = false
}) => {
  return (
    <div
      className={className}
      style={{
        alignSelf: "center",
        justifySelf: "center"
      }}
    >
      <LoaderIcon className="animate-spin" color={color} />
    </div>
  );
};
