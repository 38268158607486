import { Trans, useTranslation } from "react-i18next";
import { Convexity } from "../../../assets/iconComponents/Convexity";
import styles from "../StakingPage.module.scss";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { stakingService } from "../service";
import { validationSum } from "../../../utils/validationSum";
import { useAccount, useBalance, useReadContract, useWriteContract } from "wagmi";
import erc20Abi from "../web3/contracts/erc20/Erc20ABI.json";
import stakingAbiERC20 from "../web3/contracts/tenetStaking/TenetStakingNativeErc20.json";
import { CONTRACTS_DATA } from "../web3/contracts";
import { MaxUint256, parseEther } from "ethers";
import { stakingStore } from "../store";
import { timer } from "../../../utils/timer";
import pixFiIcon from "../../../assets/images/pixFiIcon.png";
import pixLogoGhost from "../../../assets/images/pixLogoGhostSmall.png";
import { Loader } from "../../../components/Loader";
import { StakeSucessModal } from "./StackingModals/components/StakeSucessModal";
import { HalfCircleProgressDisplay } from "../../../components/HalfCircleProgress/HalfCircleProgressDisplay";
import { walletService } from "../../../components/WalletSection/service";
import { Button } from "../../../components/Buttons/Button";
import { toBn } from "../../../utils/bigNumber";

export const Staking = () => {
  const [stakingSuccessModalOpen, setStakingSuccessModalOpen] = useState(false);
  const [stakingValue, setStakingValue] = useState<string>("");
  const [progressPercent, setProgressPercent] = useState("0");
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const { address } = useAccount();
  const balance = stakingStore((state) => state.tokenBalance);
  const { data: erc20Balance } = useBalance({ address, query: { enabled: true, refetchInterval: 7 * 1000 } });
  const { newEpoch, untilRaffleDate, isRaffleEnded } = stakingStore();
  const { t } = useTranslation();
  const [tickTimer, setTickTimer] = useState([
    { label: "d", value: "00" },
    { label: "h", value: "00" },
    { label: "m", value: "00" },
    { label: "s", value: "00" },
  ]);
  const { isSuccess, isPending, writeContract } = useWriteContract();

  const newIncome = stakingStore((state) => state.income);

  const { data: allowance } = useReadContract({
    account: address,
    address: CONTRACTS_DATA.token.address,
    abi: erc20Abi,
    functionName: "allowance",
    args: [address, CONTRACTS_DATA.staking.address],
  });

  useEffect(() => {
    if (newEpoch?.epoch) {
      const { epochEndTimestamp, epochStartTimestamp } = newEpoch.epoch;
      const period = toBn(epochEndTimestamp).minus(epochStartTimestamp);
      const currentProgress = toBn(new Date().getTime()).dividedBy(1000).minus(epochStartTimestamp);
      setProgressPercent(currentProgress.multipliedBy(100).dividedBy(period).toFixed(0));
    }
  }, [newEpoch]);

  useEffect(() => {
    isSuccess && setStakingSuccessModalOpen(true);
  }, [isSuccess]);

  useEffect(() => {
    if (untilRaffleDate) {
      intervalIdRef.current = setInterval(() => {
        const newTimer = timer(untilRaffleDate, true);
        // const newTimer = timer(new Date("8/2/2024 15:00:00 GMT+0000"), true);

        if (Array.isArray(newTimer)) {
          setTickTimer(newTimer);
        }
      }, 1000);
    }

    return () => {
      intervalIdRef.current && clearInterval(intervalIdRef.current);
    };
  }, [untilRaffleDate]);

  useEffect(() => {
    if (newEpoch?.epochIndex !== undefined && +newEpoch?.epochIndex >= 0) {
      const raffleEndedCondition = +newEpoch.epochIndex > 0 ||
        (!tickTimer.reduce((p, c) => p + parseInt(c.value), 0))
      if (raffleEndedCondition !== isRaffleEnded) {
        stakingService.setIsRaffleEnded(raffleEndedCondition)
      }
    }
  }, [tickTimer])

  useEffect(() => {
    isRaffleEnded && address && stakingService.getStakingData(address)
  }, [isRaffleEnded])

  useEffect(() => {
    address && stakingService.calcShares(address, stakingValue);
  }, [stakingValue, address]);

  const handleStakingClick = async () => {
    if (!address) {
      walletService.openWalletModal();
      return;
    }

    if (stakingValue && toBn(stakingValue).gt(0)) {
      const amountBN = parseEther(stakingValue.toString());
      if (toBn(`${allowance}`).lt(toBn(`${amountBN}`))) {
        writeContract({
          address: CONTRACTS_DATA.token.address,
          abi: erc20Abi,
          functionName: "approve",
          args: [CONTRACTS_DATA.staking.address, MaxUint256],
        });
      }
      //TODO handle waiting for previous transaction completion
      writeContract({
        address: CONTRACTS_DATA.staking.address,
        abi: stakingAbiERC20,
        functionName: "stake",
        args: [amountBN],
      });
    }
  };

  const handleChangeStakingValue = (event: ChangeEvent<HTMLInputElement>) => {
    let validated = validationSum(event.target.value);
    setStakingValue(validated || "");
  };

  const quikSelectHandler = (amountPercent: number) => {
    setStakingValue(
      toBn(balance || 0)
        .multipliedBy(amountPercent)
        .dividedBy(100)
        .toFixed(3, 1)
        .toString(),
    );
  };

  return (
    <div className={styles.stakingWrapper}>
      <Convexity className={styles.stakingConvexity} />
      <div className={styles.titleRow}>
        <h3 className={styles.stakingTitle}>
          {t("staking.era.neolithic")} <span>#{newEpoch?.epochIndex}</span>
        </h3>
        <div className={styles.stakingbalanceTop} onClick={() => quikSelectHandler(100)}>
          <span>
            {t("staking.balance")}: <span>{balance}</span>
          </span>
        </div>
      </div>

      <HalfCircleProgressDisplay value={+progressPercent} className={styles.stakingProgressBar} backLight>
        <div className={styles.stakingTimerTitle}>{t("staking.timer.title")}</div>
        <div className={styles.stakingTimerDigits}>
          <span className={styles.stakingTimerDigit}>
            {tickTimer[0].value}
            <span>{t("staking.days")}</span>
          </span>
          <span className={styles.stakingTimerLimiter}>:</span>
          <span className={styles.stakingTimerDigit}>
            {tickTimer[1].value}
            <span>{t("staking.hours")}</span>
          </span>
          <span className={styles.stakingTimerLimiter}>:</span>
          <span className={styles.stakingTimerDigit}>
            {tickTimer[2].value}
            <span>{t("staking.minutes")}</span>
          </span>
          <span className={styles.stakingTimerLimiter}>:</span>
          <span className={styles.stakingTimerDigit}>
            {tickTimer[3].value}
            <span>{t("staking.seconds")}</span>
          </span>
        </div>
      </HalfCircleProgressDisplay>
      <div className={styles.stakingActionsContainer}>
        <div className={styles.stakingInputContainer}>
          <div className={styles.stakingInputWrapper}>
            <div className={styles.stakingInput}>
              <img src={pixFiIcon} alt="pixFi" />
              <input value={stakingValue} onChange={handleChangeStakingValue} placeholder="500 000" />
            </div>
            <p className={styles.ticketsAmountWrapper}>
              {newIncome ? (
                <span className={styles.ticketsAmount}>
                  {stakingValue ? "+" : ""}
                  {newIncome}
                </span>
              ) : null}
              {newIncome ? t("staking.ticketsPerDay") : ""}
            </p>
          </div>
          <Button
            size="large"
            variant="secondary"
            onClick={handleStakingClick}
            className={styles.stakingButton}
            isUppercased
          >
            {isPending ? <Loader color="#FFFFFF" /> : address ? t("staking.button.stake") : t("wallet.title")}
          </Button>
        </div>
        <div className={styles.stakingQuickSelect}>
          <button disabled={!balance} onClick={() => quikSelectHandler(25)}>
            25%
          </button>
          <button disabled={!balance} onClick={() => quikSelectHandler(50)}>
            50%
          </button>
          <button disabled={!balance} onClick={() => quikSelectHandler(75)}>
            75%
          </button>
          <button disabled={!balance} onClick={() => quikSelectHandler(100)}>
            {t("staking.amount.max")}
          </button>
        </div>
        <button onClick={handleStakingClick} className={styles.stakingButton}>
          {address ? t("staking.button.stake") : t("wallet.title")}
        </button>
        <div className={styles.mobileInfo}>
          <img src={pixLogoGhost} alt="pix-logo" />
          <span>
            <Trans
              i18nKey="staking.mobile.info"
              components={{
                br: <br />,
              }}
            />
          </span>
        </div>
        <div className={styles.stakingbalance} onClick={() => quikSelectHandler(100)}>
          <span>
            {t("staking.balance")}: <span>{balance}</span>
          </span>
        </div>
      </div>

      <StakeSucessModal
        open={stakingSuccessModalOpen}
        onClose={() => setStakingSuccessModalOpen(false)}
        stakingAmount={stakingValue}
      />
    </div>
  );
};
