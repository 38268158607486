import React, {FC, HTMLAttributes} from 'react';
import "./Button.scss";
import cn from "classnames";

interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
}

const Button: FC<IButtonProps> = ({label, className, ...props}) => {
  return (
    <button type="button" className={cn("button", className)} {...props}>
      {label}
    </button>
  );
};


export default Button;
