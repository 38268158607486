import { AppRoutes } from "../../constants/routes";
import publicNavigate from "../../services/PublicNavigate";
import { userStore } from "../../services/user/store";
import { IEditAvatarPayload, UserData } from "../../services/user/types";
import { createRoutingLink } from "../../utils/createRoutingLink";

export const getDefaultEditInfo = (
  user: UserData | null
): IEditAvatarPayload => ({
  updateProfileOptions: {
    username: user?.profile?.username || "",
    biography: user?.profile?.biography || "",
  },
  updateUserOptions: {
    email: user?.email || "",
    isKol: false,
    role: "USER",
  },
  updatePrivacySettingsOptions: {
    showDiscord: user?.privacySettings?.showDiscord ?? true,
    showEmail: user?.privacySettings?.showEmail ?? true,
    showTelegram: user?.privacySettings?.showTelegram ?? true,
    showTwitter: user?.privacySettings?.showTwitter ?? true,
  },
});

export const navigateToUserProfile = () => {
  const isProfilePage =
    window.location.pathname === AppRoutes.myProfile ||
    window.location.pathname === AppRoutes.myProfile + "/";

  if (isProfilePage) {
    publicNavigate.navigate(
      createRoutingLink(AppRoutes.profileById, {
        userID: userStore.getState().user?.id,
      })
    );
  }
};
