import { CharactersIcon } from "../assets/iconComponents/new_design_icons/CharactersIcon"
import { LeaderboardIcon } from "../assets/iconComponents/new_design_icons/LeaderboardIcon"
import { PetsIcon } from "../assets/iconComponents/new_design_icons/PetsIcon"
import { ProfileIcon } from "../assets/iconComponents/new_design_icons/ProfileIcon"
import { ReferralIcon } from "../assets/iconComponents/new_design_icons/ReferralIcon"
import { StakingIcon } from "../assets/iconComponents/new_design_icons/StakingIcon"
import { IIconProps } from "../assets/iconComponents/new_design_icons/types"
import { TasksIcon } from "../assets/iconComponents/TasksIcon"
import { CharactersClaimPage } from "../pages/claim/BlackPumaClaim"
import { AvatarsGeneration } from "../pages/generation"
import { Leaderboard } from "../pages/leaderboard"
import { Login } from "../pages/login"
import { Pets } from "../pages/Pets"
import { Profile } from "../pages/profile"
import { MyProfile } from "../pages/profile/MyProfile"
import Referrals from "../pages/referrals"
import { Setup } from "../pages/setup"
import { StakingPage } from "../pages/staking"
import { Tasks } from "../pages/tasks"
import { isDevelopmentStage } from "./appStages"

export enum RoutesParams {
  userID = "userID",
}

export enum RouteNames {
  login = "login",
  setup = "setup",
  myProfile = "profile",
  profileById = "userProfile",
  referrals = "referrals",
  pets = "pets",
  // leaderboard = "leaderboard",
  pixelwars = "pixelwars",
  tasks = "tasks",
  generation = "generation",
  staking = "staking",
  // claim = "claim",
  bridge = "bridge",
  characters = "characters"
  // airdrop = "airdrop"
}

export enum AppRoutes {
  login = "/login",
  setup = "/setup",
  myProfile = "/profile",
  profileById = "/profile/:userID",
  referrals = "/referrals",
  pets = "/pets",
  battle = "/battle",
  friends = "/friends",
  staking = "/staking",
  // leaderboard = "/leaderboard",
  tasks = "/tasks",
  pixelwars = "/pixelwars",
  generation = "/generation",
  characters = "/characters",
  // airdrop = "/airdrop",
  // claim = "/claim",
  // bridge = "/bridge",
}

export const devRoutesArray = [
  { path: AppRoutes.login, name: RouteNames.login, Component: Login },
  { path: AppRoutes.setup, name: RouteNames.setup, Component: Setup },
  {
    path: AppRoutes.referrals,
    name: RouteNames.referrals,
    Component: Referrals,
  },
  {
    path: AppRoutes.pets,
    name: RouteNames.pets,
    Component: Pets,
  },
  {
    path: AppRoutes.profileById,
    name: RouteNames.profileById,
    Component: Profile,
  },
  {
    path: AppRoutes.myProfile,
    name: RouteNames.myProfile,
    Component: MyProfile,
  },
  // {
  //   path: AppRoutes.leaderboard,
  //   name: RouteNames.leaderboard,
  //   Component: Leaderboard,
  // },
  {
    path: AppRoutes.tasks,
    name: RouteNames.tasks,
    Component: Tasks,
  },
  {
    path: AppRoutes.generation,
    name: RouteNames.generation,
    Component: AvatarsGeneration,
  },
  {
    path: AppRoutes.characters,
    name: RouteNames.characters,
    Component: CharactersClaimPage,
  },
  // {
  //   path: AppRoutes.pixelwars,
  //   name: RouteNames.pixelwars,
  //   Component: PixelWars,
  // },
  {
    path: AppRoutes.staking,
    name: RouteNames.staking,
    Component: StakingPage,
  },
  // {
  //   path: AppRoutes.airdrop,
  //   name: RouteNames.airdrop,
  //   Component: AirdropPage,
  // },
  // {
  //   path: AppRoutes.bridge,
  //   name: RouteNames.bridge,
  //   Component: Bridge,
  // },
  // {
  //   path: AppRoutes.claim,
  //   name: RouteNames.claim,
  //   Component: ClaimPage,
  // }
]

const productionExcludedRoutes: string[] = []

export const routesArray = isDevelopmentStage
  ? devRoutesArray
  : devRoutesArray.filter(({ name }) => !productionExcludedRoutes.includes(name))

type RouteButtonProps = {
  name: `${RouteNames}`;
  Icon: (props: IIconProps) => JSX.Element;
  path: AppRoutes;
  disabled: boolean;
};

export const sidebarRoutes: RouteButtonProps[] = [
  {
    name: "profile",
    Icon: ProfileIcon,
    path: AppRoutes.myProfile,
    disabled: false,
  },
  {
    name: "characters",
    Icon: CharactersIcon,
    path: AppRoutes.characters,
    disabled: false
  },
  {
    name: "referrals",
    Icon: ReferralIcon,
    path: AppRoutes.referrals,
    disabled: false,
  },
  {
    name: "pets",
    Icon: PetsIcon,
    path: AppRoutes.pets,
    disabled: false,
  },
  {
    name: "tasks",
    Icon: TasksIcon,
    path: AppRoutes.tasks,
    disabled: false,
  },
  {
    name: "staking",
    Icon: StakingIcon,
    path: AppRoutes.staking,
    disabled: false,
  },
  // {
  //   name: "leaderboard",
  //   Icon: LeaderboardIcon,
  //   path: AppRoutes.leaderboard,
  //   disabled: false,
  // },
  // {
  //   name: "bridge",
  //   Icon: LeaderboardIcon,
  //   path: AppRoutes.bridge,
  //   disabled: false,
  // },
  // {
  //   name: "claim",
  //   Icon: ClaimIcon,
  //   path: AppRoutes.claim,
  //   disabled: false
  // },
  /* { name: "battle", Icon: BattleIcon, path: AppRoutes.battle, disabled: true }, */
  // {
  //   name: "pixelwars",
  //   Icon: PixelwarsIcon,
  //   path: AppRoutes.pixelwars,
  //   disabled: true,
  // }
] 
