import { useState } from "react";
import cn from "classnames";
import { getFontClass } from "../../utils/getFontClass";
import { useTranslation } from "react-i18next";
import styles from "./TextArea.module.scss"

export type InputProps = JSX.IntrinsicElements["textarea"] & {
  errorMessage?: string;
  successMessage?: string;
  borderColor?: string;
  variant?: "primary" | "secondary";
  textAreaClassName?: string;
  resize?: boolean
};

export const TextArea = ({
  value,
  className = "",
  successMessage,
  borderColor,
  errorMessage,
  variant = "primary",
  textAreaClassName = "",
  resize = true,
  ...props
}: InputProps) => {
  const [, setFocused] = useState(false);
  const isPrimary = variant === "primary";
  const { i18n } = useTranslation();

  return (
    <div className={`w-full ${className}`}>

      <textarea
        maxLength={200}
        className={cn(
          styles.textarea,
          `w-full px-4 pt-[12px] pb-[12px] min-h-[67px] outline-none placeholder:uppercase placeholder:text-l-600`,
          !isPrimary &&
          "min-h-[66px] h-[40px] font-ptmono font-medium placeholder:capitalize",
          getFontClass(i18n.language),
          textAreaClassName
        )}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        value={value}
        style={resize ? {} : { resize: "none" }}
        {...props}
      />
      {successMessage && (
        <div className="font-ptmono text-xl leading-[30px] text-green-success mt-2">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="font-ptmono text-xl leading-[30px] text-red-error mt-2">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
