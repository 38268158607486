import React, {FC, PropsWithChildren} from 'react';
import cn from "classnames";
import './Text.scss';


interface ITextProps {
  className?: string;
  variant?: 's' | 'm' | 'l';
}


export const Text: FC<PropsWithChildren<ITextProps>> = ({
  className,
  variant = 'm',
  children
}) => {
  const cl = cn(
    'text',
    `text--${variant}`,
    className
  )

  return (
    <p className={cl}>{children}</p>
  );
};
