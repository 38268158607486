import { Zoom } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ModalProps } from "@mui/material/Modal/Modal";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import { CloseIconPlain } from "../../../assets/iconComponents/CloseIconPlain";
import { PurpleStackingGradient } from "../../../assets/iconComponents/PurpleStackingGradient";
import { StackingBackIcon } from "../../../assets/iconComponents/StackingBackIcon";
import { getFontClass } from "../../../utils/getFontClass";
import css from "./styles.module.scss";

interface PropsTypes {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  modalContentClassName?: string;
  bodyClassName?: string
}

export const ModalWithHeader = ({
  open,
  onClose,
  title,
  subtitle,
  children,
  modalContentClassName,
  bodyClassName = "",
  ...rest
}: PropsTypes & ModalProps) => {
  const { i18n } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={onClose}
      classes={{
        root: `${css.modalRoot} ${getFontClass(i18n.language)}`,
        backdrop: css.modalBackdrop,
      }}
      sx={{ outline: "none !important" }}
      style={{ zIndex: 998 }}
      {...rest}
    >
      <Zoom
        in={open}
        style={{ transitionDelay: open ? "200ms" : "100ms", outline: "none" }}
      >
        <section className={`${css.modalContent} ${modalContentClassName}`}>
          <div className={css.titleWrapper}>
            {<PurpleStackingGradient className={css.gradient} />}
            <div className={css.left}>
              <StackingBackIcon
                className={css.backIcon}
                onClick={() => {
                  // @ts-ignore
                  onClose();
                }}
              />
              <hr className={css.divider} />
              <h2 className={css.titleText}>{title}</h2>
              {subtitle && <p className={css.subtitleText}>{subtitle}</p>}
            </div>
            <div className={css.right}>
              <CloseIconPlain
                className={css.closeIcon}
                onClick={() => {
                  // @ts-ignore
                  onClose();
                }}
              />
            </div>
          </div>

          <div className={`${css.cardsWrapper} ${bodyClassName} card-wrapper`}>
            <div>{children}</div>
          </div>

        </section>
      </Zoom>
    </Modal>
  );
};
