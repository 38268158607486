export function transformNumber(value: number): string | number {
  if (value >= 1000000) {
    const shortValue = parseFloat((value / 1000000).toFixed(1));
    return shortValue + "M";
  } else if (value >= 1000) {
    const shortValue = parseFloat((value / 1000).toFixed(1));
    return shortValue + "K";
  }

  return value;
}
