import React, {FC, HTMLAttributes} from "react";
import cn from "classnames";
import SocialItem from "./components/SocialItem";
import {PV_SOCIAL} from "../../sidebar.constants";
import "./Social.scss";


interface ISocialProps extends HTMLAttributes<HTMLUListElement> {}


const Social: FC<ISocialProps> = ({ className }) => {
  return (
    <ul className={cn("social", className)}>
      {PV_SOCIAL.map((s) => {
        const {href, Icon} = s;

        return (
          <SocialItem
            key={href}
            className="social__item"
            href={href}
            Icon={Icon}
          />
        )
      })}
    </ul>
  );
}

export default Social;
