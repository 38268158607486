import React from "react";

export const PurpleStackingItemGradient = (
  props: JSX.IntrinsicElements["svg"],
) => {
  return (
    <svg
      {...props}
      width="395"
      height="99"
      viewBox="0 0 395 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" filter="url(#filter0_f_5004_39703)">
        <ellipse
          cx="66.5"
          cy="204"
          rx="254.5"
          ry="130"
          fill="url(#paint0_radial_5004_39703)"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_5004_39703"
          x="-262"
          y="0"
          width="657"
          height="408"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="37"
            result="effect1_foregroundBlur_5004_39703"
          />
        </filter>
        <radialGradient
          id="paint0_radial_5004_39703"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(66.5 204) rotate(90) scale(130 254.5)"
        >
          <stop stopColor="#B364D8" />
          <stop offset="1" stopColor="#B364D8" />
        </radialGradient>
      </defs>
    </svg>
  );
};
