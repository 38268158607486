import { useTranslation } from "react-i18next";
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader";
import { IBoxItem } from './BoxItem';
import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  item: IBoxItem | null;
}

export const BoxItemModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  item
}) => {
  const { t } = useTranslation();

  if (!item) {
    return null;
  }

  return (
    <ModalWithHeader
      open={isOpen}
      onClose={handleClose}
      title={t(item.title)}
    >
      <>
        <h3 className={styles.modalTitle}>{t("staking.info.modals.description")}</h3>
        <div className={styles.modalDescription}>
          <p>{t(item.description)}</p>
        </div>
      </>
    </ModalWithHeader>
  );
};
