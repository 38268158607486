import React from "react";
import Modal from "@mui/material/Modal";
import css from "./styles.module.scss";
import { Zoom } from "@mui/material";
import { CloseIcon } from "../../../assets/iconComponents/CloseIcon";
import { getFontClass } from "../../../utils/getFontClass";
import { useTranslation } from "react-i18next";
import { isIOS } from "../../../utils/isIos";
import classNames from "classnames";

export interface ModalPropsTypes {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  title?: string;
  noScroll?: boolean;
  withoutCross?: boolean;
  headerText?: JSX.Element;
  mobileFit?: boolean;
}

export const UiModal = ({
  open,
  handleClose,
  children,
  title,
  noScroll,
  withoutCross,
  headerText,
  mobileFit,
}: ModalPropsTypes) => {
  const { i18n } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      classes={{
        root: css.modalRoot,
        backdrop: css.modalBackdrop,
      }}
      sx={{ outline: "none !important" }}
    >
      <Zoom
        in={open}
        style={{ transitionDelay: open ? "200ms" : "100ms", outline: "none" }}
      >
        <div
          className={classNames(
            css.modalBody,
            {
              [css.modalContent]: !isIOS(),
              [css.modalContentMobileFull]: !mobileFit,
              [css.mobileFitScroll]: mobileFit,
            })}
        >
          <div
            className={`${noScroll ? "max-h-[calc(100vh_-_50px)] overflow-hidden pt-4" : css.scrollableContainer} ${isIOS() ? css.scrollableContainer__ios : ""}`}
          >
            <div className={css.modalHeader}>
              {title ? (
                <>
                  <h3
                    className={`${getFontClass(i18n.language)} ${css.modalTitle} ${noScroll && "pl-4"} ${withoutCross && "w-full pt-2"}`}
                  >
                    {title}
                  </h3>
                  {!!headerText && <p>{headerText}</p>}
                </>
              ) : (
                <div />
              )}
              {!withoutCross && (
                <CloseIcon
                  className={classNames(css.closeIcon, !title && "mt-2")}
                  onClick={handleClose}
                />
              )}
            </div>
            {children}
          </div>
        </div>
      </Zoom>
    </Modal>
  );
};
