import { Card } from "../../../../components/Card/Card"
import { IPet } from "../../types"
import petShadow from "../../../../assets/images/bot-placeholder.png"
import styles from "./SelectedPetInfo.module.scss"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { useEffect, useMemo, useState } from "react"
// import { getNextEvolutionAvailability } from "../../helpers/getNextEvolutionAvailability"
import { useUserStore } from "../../../../services/user/store"
import { usePetsStore } from "../../store/petsStore"
import { SelectedPetInfoButtons } from "./SelectedPetButtons"
import { petsService } from "../../service/service"

type ISelectedPetInfoProps = {
  openProofModal: () => void;
}

export const SelectedPetInfo: React.FC<ISelectedPetInfoProps> = ({
  openProofModal
}) => {
  const { t } = useTranslation();

  const {
    pets,
    selectedPet,
    loading,
  } = usePetsStore();
  const { user } = useUserStore();
  const [currentPet, setCurrentPet] = useState<IPet | null>(null);
  const currentUserPet = pets.find(
    ({ userPet }) => !!userPet?.id && userPet?.id === user?.pet?.id
  ) || null;


  useEffect(() => {
    setCurrentPet(currentUserPet)
  }, [currentUserPet])

  const handleProgressionModalOpen = () => {
    petsService.setIsProgressionModalOpened(true);
  };

  const isBought = useMemo(
    () => !!selectedPet?.userPet,
    [selectedPet]
  );

  const isNotEnoughPointsToBuy = selectedPet ?
    !isBought && (user?.spendablePoints?.amount || 0) < selectedPet.price :
    true;
  const isNotEnoughLevel = selectedPet ?
    !isBought &&
    (user?.levelData.currentLevel?.value || 0) < selectedPet.requiredLevel : true;

  const isPetChoosen = selectedPet?.id === currentPet?.id;
  const petLvl = selectedPet?.userPet?.level;


  const isNextEvolutionBought = pets.some(
    ({ rarity, race }) =>
      race === selectedPet?.race && rarity === selectedPet?.rarity + 1
  )
  const isEvolutionAvailable =
    !isNextEvolutionBought && (selectedPet?.userPet?.level || 1) / 10 >= 1;

  const isAbleToLvlUp = petLvl === 20 && !isEvolutionAvailable;


  return <div className={styles.continer}>
    <div className={styles.avatarWrapper}>
      {selectedPet?.userPet && <div className={styles.levelDisplay}>
        <span>{selectedPet?.userPet?.level}</span>
        <span>{t("profile.level")}</span>
      </div>}
      <img src={selectedPet ? selectedPet.imageUrl : petShadow} alt="pet" />
      <div className={styles.shine} />
    </div>
    {selectedPet?.userPet && (
      <p className="absolute top-4 left-5">
        {`${selectedPet?.userPet?.level}`}
        <span className="text-xs ml-2">LVL</span>
      </p>
    )}
    <Card className={styles.cardRoot}>
      <div className={`custom-scrollbar-gray ${styles.cardContent}`}>
        <h5 className={styles.selectedPetName}>
          {t(selectedPet?.nameLocalizationKey || "") || selectedPet?.name}
        </h5>
        <span className={styles.selectedPetInfoLabel}>{t("pets.edit-bot.info")}</span>
        <p
          className={classNames(
            styles.selectedPetDesc,
            "custom-scrollbar-gray"
          )}
        >
          {t(selectedPet?.descriptionLocalizationKey || "") ||
            selectedPet?.description}
        </p>
      </div>

      <div>

        <p className={styles.petMultiplier}>
          <span>PIX multiplier </span>{selectedPet?.multiplier || 0}
        </p>
        <SelectedPetInfoButtons
          currentPet={currentPet}
          isAbleToLvlUp={isAbleToLvlUp}
          isBought={isBought}
          isNotEnoughLevel={isNotEnoughLevel}
          isNotEnoughPointsToBuy={isNotEnoughPointsToBuy}
          isPetChoosen={isPetChoosen}
          openProofModal={openProofModal}
          setCurrentPet={setCurrentPet}
          loading={loading}
        />
      </div>
      {/* {errorMessage && (
        <div className="text-xs leading-[30px] text-red-error mt-2">
          {errorMessage}
        </div>
      )} */}
    </Card>
  </div>
}