import React, { FC, memo } from "react";
import "./TopBarRestyled.scss";
import { ToggleSidebarBtn } from "./components/ToggleSidebarBtn/ToggleSidebarBtn";
import MiniProfile from "./components/MiniProfile/MiniProfile";
import {PV_CLAIM_PIXFI} from "../Sidebar/sidebar.constants";
import {ButtonLink} from "../Buttons/Button/ButtonLink";
import {useTranslation} from "react-i18next";

interface ITopBarRestyledProps {
  toggleSidebar: () => void;
}

export const TopBarRestyled: FC<ITopBarRestyledProps> = memo(({ toggleSidebar }) => {
  const { t } = useTranslation();

  return (
    <header className="top-bar-restyled">
      <ToggleSidebarBtn className="top-bar-restyled__burger" toggleSidebar={toggleSidebar} />
      <ButtonLink
        className="top-bar-restyled__button-trade"
        to={PV_CLAIM_PIXFI}
        variant="secondary"
        decoration="outlined"
        style={{ color: "#B364D8" }}
        isUppercased
      >
        {t("sidebar.button.trade")}
      </ButtonLink>
      <MiniProfile className="top-bar-restyled__mini-profile" />
    </header>
  );
});
