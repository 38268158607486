import {useTranslation} from "react-i18next";
import {SortQuery} from "../../../referrals.types";
import "../styles/TableHeader.scss";

export function TableHeader({sortQuery}: { sortQuery?: SortQuery }) {
  const {t} = useTranslation();

  return (
    <ul className="referral-table-header">
      <li className="referral-table-header__item">{t("leaderboard.name")}</li>
      <li className="referral-table-header__item">
        {/*{t(`leaderboard.column.${sortQuery.toLocaleLowerCase()}`)}*/}
        Total FI
      </li>
      {/*<li className="referral-table-header__item">{t('referrals.modal.date')}</li>*/}
    </ul>
);
}
