import {ITask} from "./types";

export const getDoneCount = (arr: ITask[]): number => {
  let start = 0;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].isTaskDone) {
      start++;
    }
  }

  return start;
}

export const getInProggressCount = (arr: ITask[]): number => {
  let start = 0;

  for (let i = 0; i < arr.length; i++) {
    if (!arr[i].isTaskDone) {
      start++;
    }
  }

  return start;
}
