import React, { ChangeEvent, InputHTMLAttributes } from "react";
import css from "./SwitchToggle.module.scss";
import classNames from "classnames";

interface ISwitchToggleProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string | JSX.Element;
  checked: boolean;
  onChange: (isChecked: boolean) => void
}

export const SwitchToggle: React.FC<ISwitchToggleProps> = (
  {
    checked,
    onChange,
    label,
    className,
    name,
    id,
    disabled = false,
  }
) => {

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return <label
    className={classNames(css.container, className)}
    htmlFor={id}
  >
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      disabled={disabled}
      onChange={!disabled ? onChangeHandler : undefined}
    />
    <span className={css.knob} />
    {label && <span className={css.label}>{label}</span>}
  </label>
}