import React, { SVGAttributes } from "react";

export const GoToIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.1 12.3C1.7134 12.6866 1.0866 12.6866 0.7 12.3C0.313401 11.9134 0.313401 11.2866 0.7 10.9L7.89289 3.70711C8.52286 3.07714 8.07669 2 7.18579 2H2C1.44772 2 1 1.55228 1 1C1 0.447715 1.44772 0 2 0H12C12.5523 0 13 0.447715 13 1V11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11V5.81421C11 4.92331 9.92286 4.47714 9.29289 5.10711L2.1 12.3Z"
        fill="currentColor"/>
    </svg>
  );
};
