import { IIconProps } from "./types"

export const ProfileIcon = ({ iconColor = "#FFFFFF", ...props }: IIconProps) => {
  return <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_5131_32436"
      style={{maskType: "alpha"}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="22"
    >
      <rect
        x="0.503906"
        y="0.675781"
        width="20.8695"
        height="20.8695"
      />
    </mask>
    <g mask="url(#mask0_5131_32436)">
      <path
        d="M4.72058 14.2279C5.18614 15.1437 6.36384 15.2251 7.29008 14.7806C7.57196 14.6453 7.86154 14.5235 8.15881 14.415C9.02996 14.0973 9.95721 13.9385 10.9405 13.9385C11.9239 13.9385 12.8511 14.0973 13.7223 14.415C14.0195 14.5235 14.3091 14.6453 14.591 14.7806C15.5172 15.2251 16.6949 15.1437 17.1605 14.2279C17.2552 14.0416 17.3427 13.8493 17.4229 13.6509C17.739 12.8694 17.897 12.0233 17.897 11.1124C17.897 9.18488 17.2195 7.54358 15.8644 6.18851C14.5094 4.83344 12.8681 4.15591 10.9405 4.15591C9.01301 4.15591 7.37171 4.83344 6.01664 6.18851C4.66157 7.54358 3.98403 9.18488 3.98403 11.1124C3.98403 12.0233 4.14208 12.8694 4.45816 13.6509C4.5384 13.8493 4.62588 14.0416 4.72058 14.2279ZM10.9405 11.7646C10.1468 11.7646 9.47736 11.492 8.93228 10.9468C8.38707 10.4017 8.11446 9.73227 8.11446 8.93851C8.11446 8.14474 8.38707 7.47532 8.93228 6.93025C9.47736 6.38503 10.1468 6.11242 10.9405 6.11242C11.7343 6.11242 12.4037 6.38503 12.9488 6.93025C13.494 7.47532 13.7666 8.14474 13.7666 8.93851C13.7666 9.73227 13.494 10.4017 12.9488 10.9468C12.4037 11.492 11.7343 11.7646 10.9405 11.7646ZM10.9405 19.3733C9.79344 19.3733 8.71736 19.1575 7.71229 18.7261C6.70722 18.2946 5.83294 17.7071 5.08947 16.9635C4.34584 16.22 3.75831 15.3457 3.32686 14.3407C2.89541 13.3356 2.67969 12.2595 2.67969 11.1124C2.67969 9.96531 2.89541 8.88923 3.32686 7.88416C3.75831 6.87909 4.34584 6.00482 5.08947 5.26134C5.83294 4.51772 6.70722 3.93018 7.71229 3.49874C8.71736 3.06729 9.79344 2.85156 10.9405 2.85156C12.0876 2.85156 13.1637 3.06729 14.1688 3.49874C15.1739 3.93018 16.0481 4.51772 16.7916 5.26134C17.5352 6.00482 18.1228 6.87909 18.5542 7.88416C18.9857 8.88923 19.2014 9.96531 19.2014 11.1124C19.2014 12.2595 18.9857 13.3356 18.5542 14.3407C18.1228 15.3457 17.5352 16.22 16.7916 16.9635C16.0481 17.7071 15.1739 18.2946 14.1688 18.7261C13.1637 19.1575 12.0876 19.3733 10.9405 19.3733Z"
      />
    </g>
  </svg>
}
