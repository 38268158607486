import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../../configs/firebase.config";
import { localStorageKeys } from "../../../constants/localStorageKeys";
import { AppRoutes } from "../../../constants/routes";
import { BaseService } from "../../../services/BaseService";
import { $api, AUTH_API_URL } from "../../../services/interceptor";
import publicNavigate from "../../../services/PublicNavigate";
import { TelegramAuthData } from "../../../services/user/service";
import { Auth } from "../../../types/auth";
import { createLinkWithSearchParams } from "../../../utils/createLinkWithSearchParams";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import referralService from "../../setup/services/referralService";
import { ILoginStore, loginStore } from "../store/loginStore";

class LoginAPI extends BaseService<ILoginStore> {
  constructor() {
    super(loginStore);
  }

  loginWithTelegram = async (
    userData: TelegramAuthData,
    refCode?: null | string
  ): Promise<Auth | undefined> => {
    try {
      const { data } = await $api.post<Auth>(
        "/auth/telegram",
        {
          telegramLoginWidgetPayload: userData,
        },
        { baseURL: AUTH_API_URL }
      );

      const accessToken: string = data?.tokens?.access;

      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
      }

      if (data.user.isNew) {
        localStorage.setItem(localStorageKeys.isNewTgAccount, "true")
        if (refCode) {
          await this.postReferralCode(refCode);
        } else {
          publicNavigate.navigate(createLinkWithSearchParams(AppRoutes.setup), {
            replace: true,
          });

          return;
        }
      }

      publicNavigate.navigate(AppRoutes.myProfile, {
        replace: true,
      });

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  loginWithGoogle = async () => {
    try {
      const data = await signInWithPopup(auth, provider);
      const googleToken = await data.user.getIdToken();
      const requestData = {
        googleToken: googleToken,
      };
      const response = await $api.post<Auth>("/auth/google", requestData, { baseURL: AUTH_API_URL });
      const accessToken: string = response?.data?.tokens?.access;

      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
      }

      if (response?.data.user.isNew) {
        publicNavigate.navigate(createLinkWithSearchParams(AppRoutes.setup), {
          replace: true,
        });
        return;
      }

      publicNavigate.navigate(AppRoutes.myProfile, {
        replace: true,
      });

      return response.data;
    } catch (error) {
      return undefined;
    } finally {
    }
  };

  sendOtpRequest = async (email: string) => {
    try {
      const requestData = {
        email,
      };
      this.setState({ isOTPRequestPending: true });

      await $api.post("/auth/otp/request", requestData, { baseURL: AUTH_API_URL });

      this.setState({ isOTPScreen: true });
    } catch (err) {
      this.setState({ emailErrorMessage: getErrorMessage(err) });
    } finally {
      this.setState({ isOTPRequestPending: false });
    }
  };

  loginWithOTP = async (email: string, OTPCode: string) => {
    try {
      this.setState({ isOTPLoginPending: true });

      const requestData = {
        email: email,
        otpCode: OTPCode,
      };

      const response = await $api.post<Auth>("/auth/otp", requestData, { baseURL: AUTH_API_URL });

      const accessToken: string = response?.data?.tokens?.access;
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
      }

      if (response.data.user.isNew) {
        publicNavigate.navigate(createLinkWithSearchParams(AppRoutes.setup), {
          replace: true,
        });
      } else {
        publicNavigate.navigate(AppRoutes.myProfile, {
          replace: true,
        });
      }
    } catch (error) {
      this.setState({ otpError: getErrorMessage(error) });
    } finally {
      this.setState({ isOTPLoginPending: false });
    }
  };

  postReferralCode = async (refCode: string) => {
    try {
      await referralService.setReferer(refCode);

      return true;
    } catch (error) {
      referralService.setErrorMessage(getErrorMessage(error));
      publicNavigate.navigate(createLinkWithSearchParams(AppRoutes.setup), {
        replace: true,
      });

      return false;
    }
  };

  referralOTPLogin = async (
    email: string,
    OTPCode: string,
    refCode: string
  ) => {
    try {
      this.setState({ isOTPLoginPending: true });

      const requestData = {
        email: email,
        otpCode: OTPCode,
      };

      const response = await $api.post<Auth>("/auth/otp", requestData, { baseURL: AUTH_API_URL });

      const accessToken: string = response?.data?.tokens?.access;
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
      }

      if (response.data.user.isNew) {
        const isReferralCodeValid = await this.postReferralCode(refCode);

        if (!isReferralCodeValid) {
          return;
        }
      }
      publicNavigate.navigate(AppRoutes.myProfile, {
        replace: true,
      });
    } catch (error) {
      this.setState({ otpError: getErrorMessage(error) });
    } finally {
      this.setState({ isOTPLoginPending: false });
    }
  };

  referralGoogleLogin = async (refCode: string) => {
    try {
      const data = await signInWithPopup(auth, provider);
      const googleToken = await data.user.getIdToken();
      const requestData = {
        googleToken: googleToken,
      };
      const response = await $api.post<Auth>("/auth/google", requestData, { baseURL: AUTH_API_URL });
      const accessToken: string = response?.data?.tokens?.access;

      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
      }

      if (response?.data.user.isNew) {
        const isReferralCodeValid = await this.postReferralCode(refCode);

        if (!isReferralCodeValid) {
          return;
        }
      }

      publicNavigate.navigate(AppRoutes.myProfile, {
        replace: true,
      });

      return response.data;
    } catch (error) {
      console.log(getErrorMessage(error));

      return undefined;
    }
  };

  setEmail = (email: string) => {
    this.setState({ email });
  };

  setOTPCode = (otpCode: string) => {
    this.setState({ otpCode });
  };

  cancelOTPLogin = () => {
    this.setState({ isOTPScreen: false });
  };

  clearOTPError = () => {
    this.setState({ otpError: "" });
  };

  clearEmailError = () => {
    this.setState({ emailErrorMessage: "" });
  }
}

const loginService = new LoginAPI();
export default loginService;
