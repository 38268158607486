import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";

import { Button } from "../../../../../components/Buttons/Button";

import { petsStore, usePetsStore } from "../../../store/petsStore";
import { getDateInMs } from "../../../../../utils/getDateInMS";
import { useTimer } from "../../../../../hooks/useTimer";
import { oneDayInMs } from "../../../../../constants/time";

import css from "../../../styles.module.scss";
import modalStyles from "../modals.module.scss"
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader";
import { petsService } from "../../../service/service";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";

export const BuyModal = () => {
  const lastBuyDate = petsStore(
    useShallow(({ pets }) => {
      if (pets.length) {
        const lastBoughtPet = pets.reduce((latestPet, currentPet) => {
          if (
            !latestPet ||
            getDateInMs(currentPet.userPet?.createdAt || "") >
            getDateInMs(latestPet.userPet?.createdAt || "")
          ) {
            return currentPet;
          }
          return latestPet;
        });
        return lastBoughtPet.userPet?.createdAt;
      }
      return ""
    })
  );

  const { loading, buyPetInfo, selectedPet } = usePetsStore()

  const newTargetDate = lastBuyDate
    ? new Date(new Date(lastBuyDate).getTime() + oneDayInMs)
    : new Date();
  const [time, isTimerFinished] = useTimer(newTargetDate);

  const { t } = useTranslation();

  const onClose = () => {
    petsService.setBuyPetInfo(null)
  }

  const handleBuy = async () => {
    if (selectedPet) {
      try {
        await petsService.buyPet({ petId: selectedPet.id });
        petsService.setBuyPetInfo(null);
      } catch (e) {
        petsService.setBuyError(getErrorMessage(e));
      }
    }
  };

  if (!buyPetInfo) {
    return null
  }

  return (
    <ModalWithHeader
      open={!!buyPetInfo}
      onClose={onClose}
      title={t("pets.buy.proof.title")}
      modalContentClassName={modalStyles.modalContent}
    >
      <div className={css.proofOfActionModalWrapper}>
        <p className={css.proofOfActionSubtitle}>
          {t(`pets.buy.proof.description`, { name: buyPetInfo.petName, points: buyPetInfo.petPrice })}
        </p>
        <div className={css.btnsWrapper}>
          <Button
            onClick={onClose}
            size="large"
            isUppercased
          >
            {t("buttons.no")}
          </Button>
          <Button
            variant={"secondary"}
            onClick={handleBuy}
            disabled={loading || !isTimerFinished}
            isLoading={loading}
            size="large"
            isUppercased
          >
            {isTimerFinished
              ? t("buttons.yes")
              : `${time.hour}h ${time.minute}m ${time.seconds}s`}
          </Button>
        </div>
      </div>
    </ModalWithHeader>
  );
};
