import { useEffect, useMemo } from "react";
import css from "./styles.module.scss";
import { useUserStore } from "../../services/user/store";
import { usePetsStore } from "./store/petsStore";
import { petsService } from "./service/service";
import { IPet, IUserPet } from "./types";
import { PetAvatar } from "./components/PetAvatar/PetAvatar";
import { PetsSelect } from "./components/PetsSelect/PetsSelect";
import { Streak } from "./components/Streak/Streak";
import { SelectedPetInfo } from "./components/SelectedPetInfo/SelectedPetInfo";
import { useIsMobileResolution } from "../../hooks/useIsMobileResolution";
import { ProgressionModal } from "./components/modals/ProgressionModal";
import { BuyModal } from "./components/modals/BuyModal";
import { SellModal } from "./components/modals/SellModal";

export const Pets = () => {
  const isMobileResolution = useIsMobileResolution()
  const { user } = useUserStore();

  const {
    pets,
    isProgressionModalOpened,
    isPetsModalOpen,
    selectedPet,
  } = usePetsStore();

  const userPet = useMemo(() => {
    const userPet = user?.pet;

    const originalPet = pets.find((item) => item.id === userPet?.petId);

    if (userPet && originalPet) {
      return { ...originalPet, ...userPet } as IPet & IUserPet;
    }
  }, [pets, user]);

  // const isUserHavePet = useMemo(() => {
  //   return pets.some(({ userPet: userPetInfo }) => !!userPetInfo);
  // }, [pets]);

  // const isBotsVisited =
  //   isUserHavePet || !!userPet || localStorage.getItem("botsVisited");

  useEffect(() => {
    petsService.getPets();
    petsService.getRewards();
    petsService.getRewardsActions();
    petsService.getGlobalStreak();
  }, []);

  const handleBuyModalOpen = () => {
    if (selectedPet) {
      petsService.setBuyPetInfo({
        petName: selectedPet.name,
        petPrice: selectedPet.price,
      });
    }
  };
  const handleSellModalOpen = () => {
    if (selectedPet) {
      petsService.setSellPetInfo({
        name: selectedPet.name,
        sellPrice: selectedPet.userPet?.sellPrice || 0,
        imageUrl: selectedPet.imageUrl,
      });
    }
  };

  const handleProofModalOpen = () => {
    if (!!selectedPet?.userPet) {
      handleSellModalOpen();
    } else {
      handleBuyModalOpen();
    }
  };

  return (
    <section className={isPetsModalOpen ? css.wrapper : css.wrapper_horizontal}>
      <div
        className={css.leftContainer}
        style={isPetsModalOpen ? { background: "#151517" } : {}}
      >
        {isPetsModalOpen ?
          <PetsSelect
            openProofModal={handleProofModalOpen}
          /> :
          <PetAvatar />}
      </div>

      <div className={css.rightContainer}>
        {isPetsModalOpen ?
          !isMobileResolution && <SelectedPetInfo
            openProofModal={handleProofModalOpen}
          /> :
          <Streak />}
      </div>

      {userPet && (
        <ProgressionModal
          currentPet={selectedPet || userPet}
          isOpened={isProgressionModalOpened}
        />
      )}
      <BuyModal />
      <SellModal />
    </section>
  );
};
