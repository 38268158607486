import React, {FormEvent, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {LoginHeader} from "../LoginHeader/LoginHeader";
import loginService from "../../services/loginService";
import {useLoginStore} from "../../store/loginStore";
import {useQueryParam} from "../../../../newHooks/useQueryParam";
import {OTPCodeInput} from "../OTPCodeInput/OTPCodeInput";
import {Btn} from "../../../../newComponents/Btn";
import {IconArrowBack} from "../../../../newComponents/Icons/IconArrowBack";

const MAX_OTP_SYMBOLS = 6;

export const LoginStep2 = () => {
  const {t} = useTranslation();
  const {paramValue} = useQueryParam('ref');

  const {
    otpCode,
    email,
    otpError,
    isOTPLoginPending
  } = useLoginStore();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await loginService.loginWithOTP(email, otpCode);
  };

  useEffect(() => {
    if (otpCode.length === MAX_OTP_SYMBOLS) {
      if (paramValue) {
        loginService.referralOTPLogin(email, otpCode, paramValue).catch(console.error);
      } else {
        loginService.loginWithOTP(email, otpCode).catch(console.error);
      }
    }
  }, [otpCode]);

  const headerText = <>{t("login.verification.code")} {" "} <a href={`mailto:${email}`}>{email}</a></>;

  const isSubmitDisabled = otpCode.length < MAX_OTP_SYMBOLS || Boolean(otpError);

  return (
    <section className="login-step">
      <button
        className="login-step__back"
        onClick={loginService.cancelOTPLogin}
      >
        <IconArrowBack />
        {t("dropdownmenu.back")}
      </button>

      <LoginHeader
        title={t("auth.signup.title")}
        text={headerText}
      />

      <form
        id='login-form-step-2'
        className="login-step__body"
        onSubmit={!isSubmitDisabled ? onSubmit : undefined}
      >
        <p className="login-step__code-label">{t("login.enter-code")}</p>

        <OTPCodeInput className="login-step__code-input"/>
      </form>

      <footer className="login-step__footer">
        <Btn
          form="login-form-step-2"
          type="submit"
          disabled={isSubmitDisabled}
          isLoading={isOTPLoginPending}
          isFullWidth
        >
          {t("login.sign-in")}
        </Btn>
      </footer>
    </section>
  );
};
