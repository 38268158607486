import { SVGAttributes } from "react";

export const LoaderIcon = ({ color, ...props }: SVGAttributes<SVGElement> & { color?: string }) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9753 10.7233C8.95486 13.4338 7.03911 17.1623 6.59423 21.1961L10.2902 21.1896C10.729 18.1367 12.2523 15.3439 14.5814 13.3221C16.9105 11.3002 19.8897 10.1845 22.974 10.1791C26.0582 10.1736 29.0413 11.2788 31.3776 13.2924C33.7138 15.3061 35.2469 18.0935 35.6965 21.1448L39.3925 21.1383C38.9334 17.106 37.0045 13.3843 33.9745 10.6845C30.9445 7.98471 27.0258 6.49614 22.9675 6.5033C18.9092 6.51046 14.9958 8.01285 11.9753 10.7233Z"
        fill={color || "#B364D8"}
      />
      <path
        opacity="0.6"
        d="M12.0176 35.319C8.98761 32.6192 7.05872 28.8975 6.59961 24.8652L10.2956 24.8587C10.7452 27.91 12.2783 30.6975 14.6146 32.7111C16.9508 34.7247 19.9339 35.8299 23.0181 35.8245C26.1024 35.819 29.0816 34.7033 31.4107 32.6815C33.7398 30.6596 35.2631 27.8668 35.7019 24.8139L39.3979 24.8074C38.953 28.8412 37.0373 32.5697 34.0168 35.2802C30.9963 37.9907 27.0829 39.4931 23.0246 39.5002C18.9663 39.5074 15.0477 38.0188 12.0176 35.319Z"
        fill={color || "#B364D8"}
      />
    </svg>
  );
};
