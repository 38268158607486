import { create, useStore } from "zustand";

export interface IWalletStore {
  isLoading: boolean;
  isWalletModalOpened: boolean;
}

export const walletStore = create<IWalletStore>(() => ({
  isLoading: false,
  isWalletModalOpened: false,
}));

export const useWalletStore = () => useStore(walletStore);
