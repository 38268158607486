import { SocialStates } from "../constants/common";
import { AppRoutes } from "../constants/routes";

const rootUrl = "https://discord.com/oauth2/authorize";

export const getDiscordLoginLink = (redirectUrl = "") => {
  const discordRedirectURI = window.location.origin + AppRoutes.myProfile;

  const options = {
    redirect_uri: discordRedirectURI,
    client_id: process.env.REACT_APP_DISCORD_CLIENT_ID as string,
    state: JSON.stringify({
      type: SocialStates.discord,
      redirectTo: redirectUrl,
    }),
    response_type: "code",
    scope: ["identify", "guilds"].join(" "),
  };

  const qs = new URLSearchParams(options).toString();

  return `${rootUrl}?${qs}`;
};
