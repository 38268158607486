import React from "react";

export const StackingBackIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      {...props}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="10" fill="white" fillOpacity="0.05" />
      <rect
        x="0.5"
        y="0.5"
        width="43"
        height="43"
        rx="9.5"
        stroke="url(#paint0_linear_5004_44752)"
        strokeOpacity="0.1"
      />
      <mask
        id="mask0_5004_44752"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="12"
        width="20"
        height="20"
      >
        <rect x="12" y="12" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5004_44752)">
        <path
          d="M19.5 27L14.5 22L19.5 17L20.6667 18.1667L17.6667 21.1667H29.5V22.8333H17.6667L20.6667 25.8333L19.5 27Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5004_44752"
          x1="17.6957"
          y1="12.913"
          x2="37.3043"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
      </defs>
    </svg>
  );
};
