import { userStore } from "../../../../services/user/store";
import { Social } from "./components/Social";
import { useShallow } from "zustand/react/shallow";
import { SocialName, UserData } from "../../../../services/user/types";
import { EditProfileModal } from "../EditProfileModal";
import { useState } from "react";
import { getConnectedSocial } from "../../../../utils/getConnectedSocial";
import { userService } from "../../../../services/user/service";
import { useTranslation } from "react-i18next";
import { userProfileStore } from "../../store/store";
import { getFontClass } from "../../../../utils/getFontClass";
import styles from "./ProfileInfo.module.scss"
import { Card } from "../../../../components/Card/Card";
import { HalfCircleProgressDisplay } from "../../../../components/HalfCircleProgress/HalfCircleProgressDisplay";
import { roundNumber } from "../../../../utils/roundNumber";
import { EditIcon } from "../../../../assets/iconComponents/new_design_icons/EditIcon";
import ElementWithIcon from "../../../../components/Buttons/ElementWithIcon/ElementWithIcon";
import { Button } from "../../../../components/Buttons/Button";

const getUserProgress = (user: UserData | null) => {
  const currentXP = user?.levelData.progress || 0;
  const currentLevelPoints = user?.levelData.currentLevel?.pointsToReach || 0;
  const nextLevelPoints = user?.levelData.nextLevelPoints?.pointsToReach || 0;

  const progressInCurrentLevel =
    ((currentXP - currentLevelPoints) /
      (nextLevelPoints - currentLevelPoints)) *
    100;

  return roundNumber(progressInCurrentLevel);
};

export const ProfileInfo = () => {
  const { t, i18n } = useTranslation();
  const [isEditProfileOpen, setIsEditProfileOpen] = useState<boolean>(false);
  const userProfile = userProfileStore(useShallow(({ user }) => user));
  const { user } = userStore();
  const isLoading = userStore(
    useShallow(({ loading }) => (userProfile ? false : loading))
  );

  const isAnotherUser = userProfile?.id !== user?.id;

  const {
    userLevel,
    progress,
    username,
    email,
    bio,
    discordUsername,
    telegramURL,
    telegramUsername,
    twitterURL,
    twitterUsername,
  } = userProfileStore(
    useShallow(({ user }) => {
      const currentUser = userProfile ?? user;

      const discord = getConnectedSocial(currentUser, SocialName.Discord);
      const telegram = getConnectedSocial(currentUser, SocialName.Telegram);
      const twitter = getConnectedSocial(currentUser, SocialName.Twitter);

      const tgUsername = telegram?.socialData?.username;
      const tgName = telegram?.socialData?.firstName || "";

      const twitterUsername = twitter?.socialData?.username || "";
      const discordUsername = discord?.socialData?.username || "";

      const currentUserLevel = user?.levelData.currentLevel?.value || 0
      const progress = getUserProgress(user);

      return {
        userLevel: currentUserLevel,
        progress,
        username: currentUser?.profile.username,
        email: currentUser?.email,
        bio: currentUser?.profile.biography,
        discordUsername: discordUsername,
        telegramUsername: tgUsername || tgName,
        telegramURL: tgUsername ? `t.me/${tgUsername}` : "",
        twitterURL: `twitter.com/${twitterUsername}`,
        twitterUsername: twitterUsername,
      };
    })
  );

  const isSocialNetworksVisible = userService.isAnySocialVisible();

  const isProfileEmailVisible = !!email;

  const isNotEmailAccount = !user?.email

  return (<>
    <Card className={`${styles.card_root} ${styles.container}`}>
      <HalfCircleProgressDisplay value={progress} className={styles.progressBar}>
        <div className={styles.level}>
          <span className={styles.level_value}>{userLevel}</span>
          <span className={styles.level_label}>level</span>
        </div>
      </HalfCircleProgressDisplay>


      <div className={styles.card_container}>
        <div className={styles.content}>
          <div
            className={`${styles.username} ${getFontClass(i18n.language)}`}
          >
            {username}
            {!isAnotherUser && (
              <ElementWithIcon
                Icon={EditIcon}
                className={styles.editButton}
                onClick={() => setIsEditProfileOpen(true)}
              />
            )}
          </div>
          {isNotEmailAccount && <Button
            variant="secondary"
            onClick={() => setIsEditProfileOpen(true)}
          >{t("auth.linkAccount.connect.email.button")}</Button>
          }
          {isProfileEmailVisible && (
            <div
              className={`${styles.email} ${getFontClass(i18n.language)}`}
            >
              {email}
            </div>
          )}
          <div
            className={`${styles.text} ${getFontClass(i18n.language)}`}
          >
            {bio || t("profile.info.bio.default")}
          </div>
        </div>

        <div className={styles.footer}>
          {(telegramUsername || twitterUsername || discordUsername) ?
            isSocialNetworksVisible ? (
              <div className={styles.socials}>
                {telegramUsername && (
                  <Social
                    social={SocialName.Telegram}
                    link={telegramURL}
                    username={telegramUsername}
                  />
                )}
                {twitterUsername && (
                  <Social
                    social={SocialName.Twitter}
                    username={twitterUsername}
                    link={twitterURL}
                  />
                )}
                {discordUsername && (
                  <Social
                    social={SocialName.Discord}
                    username={discordUsername}
                  />
                )}
              </div>
            ) : <div /> : <div />}
        </div>
      </div>

    </Card>
    <EditProfileModal
      loading={isLoading}
      isOpen={isEditProfileOpen}
      handleClose={() => setIsEditProfileOpen(false)}
    />
  </>
  );
};
