import React, { FC } from 'react';
import AdvTelBotBanner from '../../../../assets/images/banners/adv_tel_bot.png';
import cn from "classnames";
import './SidebarBanner.scss';
import { NavLink } from "react-router-dom";
import { PV_TG_BOT_HREF } from "../../sidebar.constants";


interface IBannerProps {
  className?: string;
  bgImageUrl?: string;
  link?: string;
  target?: string;
}


const SidebarBanner: FC<IBannerProps> = ({
  className,
  bgImageUrl,
  link,
  target
}) => {
  return (
    <NavLink
      className={cn("sidebar-banner", className)}
      to={link || PV_TG_BOT_HREF}
      target={target || "_self"}
      rel="noreferrer"
    >
      <picture className="sidebar-banner__picture">
        <img src={bgImageUrl || AdvTelBotBanner} alt="Pixel Verse Telegram Bot" />
      </picture>
    </NavLink>
  );
}

export default SidebarBanner;
