import { Dispatch, memo, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react"
import css from "./RouletteSpinner.module.scss"
import { rouletteSpinnerStore } from "./service"

type IStaticSpinnerProps = {
  direction?: "vertical" | "horizontal"
  slideChangeSpeedMs: number
  items: {
    id: string
    element: JSX.Element
  }[]
  mode?: "static" | "spinner"
  targetItem?: {
    id: string
    element: JSX.Element
  },
  rollEndCallback?: () => void
}

export const StaticSpinner: React.FC<IStaticSpinnerProps> = memo(({
  direction = "horizontal",
  slideChangeSpeedMs = 300,
  items,
  mode = "static",
  targetItem,
  rollEndCallback
}) => {
  const [initialized, setInitialized] = useState(false)
  const [slides, setSlides] = useState<IStaticSpinnerProps["items"]>(items)
  const initTimerRef = useRef<NodeJS.Timeout>()
  const containerRef = useRef<HTMLDivElement>(null)
  const slidesRef = useRef<HTMLUListElement>(null)
  const { rouletteRolling, actions } = rouletteSpinnerStore()

  useEffect(() => {

    initTimerRef.current = setInterval(() => {
      if (containerRef.current && slidesRef.current) {
        clearInterval(initTimerRef.current)
        setInitialized(true)
      }
    }, 500)
    return () => clearInterval(initTimerRef.current)
  }, [])

  useEffect(() => {
    if (initialized && containerRef.current && slidesRef.current && rouletteRolling && targetItem) {
      initializeRoller(
        containerRef.current,
        slidesRef.current,
        direction,
        slideChangeSpeedMs,
        css.animate,
        setSlides,
        items,
        targetItem,
        () => {
          actions.stopRoll()
          rollEndCallback && rollEndCallback()
        }
      )
    }
  }, [rouletteRolling])

  useEffect(() => {
    if (initialized && containerRef.current && slidesRef.current) {
      clearInterval(initTimerRef.current)
      if (mode === "static") {
        initializeSlider(
          containerRef.current,
          direction,
          slideChangeSpeedMs,
          css.animate,
          setSlides,
          items,
          initTimerRef
        )
      } else {
        console.log("init")
        !rouletteRolling && setStartPosition(
          containerRef.current,
          slidesRef.current,
          direction,
          setSlides,
          items,
        )

      }
    }
  }, [initialized])

  return <div
    ref={containerRef}
    className={`${css.static} ${css[direction]}`
    }>
    <ul
      ref={slidesRef}
      className={css.slides}
    >
      {slides.map((item, i) => <li key={item.id + i} className={css.slide}>
        {item.element}
      </li>)}
    </ul>
  </div>
})

const initializeSlider = (
  container: HTMLDivElement,
  direction: IStaticSpinnerProps["direction"],
  speed: IStaticSpinnerProps["slideChangeSpeedMs"],
  animateClass: string,
  setSlides: Dispatch<SetStateAction<{
    id: string;
    element: JSX.Element;
  }[]>>,
  items: IStaticSpinnerProps["items"],
  intervalTimer: MutableRefObject<NodeJS.Timeout | undefined>
) => {
  const itemsCount = items.length

  const contXSize = direction === "horizontal" ? container.offsetWidth : container.offsetHeight
  const slides = container.getElementsByTagName("ul")[0]
  const slideSize = direction === "horizontal" ? slides.offsetHeight : slides.offsetWidth

  const freeSpace = (contXSize - slideSize) / slideSize
  const cloneSlidesCount = freeSpace < 1 ? 2 : Math.ceil(freeSpace / 2) + 1

  setSlides([
    ...Array.from({ length: cloneSlidesCount }).map((_, i) => (items[itemsCount - cloneSlidesCount + i] || items[itemsCount - 1])),
    ...items,
    ...Array.from({ length: cloneSlidesCount }).map((_, i) => (items[(i)] || items[0]))
  ])

  const startPosition = -(slideSize - (contXSize - slideSize) / 2) - (cloneSlidesCount - 1) * slideSize
  const endPosition = startPosition - ((itemsCount - cloneSlidesCount + 2) * slideSize)

  const interval = itemsCount * speed

  slides.style[direction === "horizontal" ? "left" : "top"] = `${startPosition}px`
  slides.classList.add(animateClass)
  slides.style.transitionDuration = `${interval - 10}ms`

  setTimeout(() => {
    slides.style[direction === "horizontal" ? "left" : "top"] = `${endPosition}px`
  }, 20)

  intervalTimer.current = setInterval(() => {
    slides.style.transitionDuration = ""
    slides.classList.remove(animateClass)
    slides.style[direction === "horizontal" ? "left" : "top"] = `${startPosition}px`
    setTimeout(() => {
      slides.classList.add(animateClass)
      slides.style.transitionDuration = `${interval}ms`
      slides.style[direction === "horizontal" ? "left" : "top"] = `${endPosition}px`
    }, 20)

  }, interval)
}

const setStartPosition = (
  container: HTMLDivElement,
  slides: HTMLUListElement,
  direction: IStaticSpinnerProps["direction"],
  setSlides: Dispatch<SetStateAction<{
    id: string;
    element: JSX.Element;
  }[]>>,
  items: IStaticSpinnerProps["items"],
) => {
  console.log("init pos")
  const itemsCount = items.length

  const contXSize = direction === "horizontal" ? container.offsetWidth : container.offsetHeight
  const slideSize = direction === "horizontal" ? slides.offsetHeight : slides.offsetWidth

  const freeSpace = (contXSize - slideSize) / slideSize
  const cloneSlidesCount = freeSpace < 1 ? 2 : Math.ceil(freeSpace / 2) + 1

  setSlides([
    ...Array.from({ length: cloneSlidesCount }).map((_, i) => (items[itemsCount - cloneSlidesCount + i] || items[itemsCount - 1])),
    ...items,
    ...Array.from({ length: cloneSlidesCount }).map((_, i) => (items[(i)] || items[0]))
  ])

  const startPosition = -(slideSize - (contXSize - slideSize) / 2) - (cloneSlidesCount - 1) * slideSize
  slides.style[direction === "horizontal" ? "left" : "top"] = `${startPosition}px`
}

const initializeRoller = (
  container: HTMLDivElement,
  slides: HTMLUListElement,
  direction: IStaticSpinnerProps["direction"],
  speed: IStaticSpinnerProps["slideChangeSpeedMs"],
  animateClass: string,
  setSlides: Dispatch<SetStateAction<{
    id: string;
    element: JSX.Element;
  }[]>>,
  items: IStaticSpinnerProps["items"],
  targetItem: {
    id: string
    element: JSX.Element
  },
  endCallback: () => void
) => {
  const itemsCount = items.length

  const contXSize = direction === "horizontal" ? container.offsetWidth : container.offsetHeight
  const slideSize = direction === "horizontal" ? slides.offsetHeight : slides.offsetWidth

  const freeSpace = (contXSize - slideSize) / slideSize
  const cloneSlidesCount = freeSpace < 1 ? 2 : Math.ceil(freeSpace / 2) + 1

  setSlides([
    ...Array.from({ length: cloneSlidesCount }).map((_, i) => (items[itemsCount - cloneSlidesCount + i] || items[itemsCount - 1])),
    ...items,
    ...Array.from({ length: cloneSlidesCount }).map((_, i) => (items[(i)] || items[0]))
  ])

  const startPosition = -(slideSize - (contXSize - slideSize) / 2) - (cloneSlidesCount - 1) * slideSize
  const endPosition = startPosition - ((itemsCount - cloneSlidesCount + 2) * slideSize)

  slides.style[direction === "horizontal" ? "left" : "top"] = `${startPosition}px`
  slides.classList.add(animateClass)
  slides.style.transitionDuration = `${speed}ms`

  setTimeout(() => {
    slides.style[direction === "horizontal" ? "left" : "top"] = `${endPosition}px`
  })

  for (let i = 0; i < 4; i++) {
    setTimeout(() => {
      if (i === 3) {
        setSlides(prev => {
          const newItems = [...prev]
          newItems[prev.length - 2] = targetItem
          return newItems
        })
        setTimeout(() => {
          slides.style.transitionDuration = ""
          slides.classList.remove(animateClass)
          endCallback()
        }, speed * i + 1500)
      }
      slides.style.transitionDuration = ""
      slides.classList.remove(animateClass)
      slides.style[direction === "horizontal" ? "left" : "top"] = `${startPosition}px`
      setTimeout(() => {
        slides.classList.add(animateClass)
        slides.style.transitionDuration = `${speed * i - 5}ms`
        slides.style[direction === "horizontal" ? "left" : "top"] = `${endPosition}px`
      }, 15)

    }, speed * i)
  }
}