import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { stakingService } from "../../../../service";
import {Button} from "../../../../../../components/Buttons/Button";

export const StartStake = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.startStake}>
      <h3 className={styles.startStake__title}>{t("staking.info.getLootBox")}</h3>
      <p className={styles.startStake__description}>
        {t("staking.info.start")}
      </p>

      <p className={styles.startStake__footer}>
        <Button
          variant="secondary"
          size="large"
          isUppercased
          onClick={() => stakingService.setStakingInfoIsOpen(false)}
        >
          {t("staking.info.startStake")}
        </Button>

        <Button
          variant="primary"
          size="large"
          isUppercased
          decoration="outlined"
          onClick={() => stakingService.setStakingInfoIsOpen(false)}
        >
          {t("global.close")}
        </Button>
      </p>
    </div>
  );
};
