import { SocialStates, isDevelopment } from "../constants/common";

const rootUrl = "https://twitter.com/i/oauth2/authorize";

const options = {
  redirect_uri: isDevelopment
    ? "http://www.localhost:3000/profile"
    : window.location.origin + "/profile",
  client_id: process.env.REACT_APP_TWITTER_CLIENT_ID as string,
  state: SocialStates.twitter,
  response_type: "code",
  code_challenge: process.env.REACT_APP_TWITTER_CODE_CHALLENGE as string,
  code_challenge_method: "S256",
  scope: ["users.read", "tweet.read"].join(" "),
};

const qs = new URLSearchParams(options).toString();

export const TWITTER_LOGIN_LINK = `${rootUrl}?${qs}`;
