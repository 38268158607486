import { TaskItem } from "./TaskItem";
import { handleExternalLinkOpen } from "../../../utils/handleExternalLinkOpen";
import "../styles/UserTasks.scss";
import {Title} from "../../../newComponents/Title/Title";
import {useTranslation} from "react-i18next";
import {useTasksStore} from "../store/tasksStore";
import {getDoneCount} from "../tasks.helpers";
import bluredTask from '../../../assets/images/tasks/blured-task.png';

export const GeneralTasks = () => {
  const { t } = useTranslation();
  const { generalTasks } = useTasksStore();

  const doneGeneralTasks = getDoneCount(generalTasks);
  const generalTasksCount = generalTasks.length;
  const isTasksCompleted = doneGeneralTasks === generalTasksCount;

  return (
    <div className="tasks__list">
      <div className="tasks__list-header">
        <Title tagName="h2" className="tasks__list-title">{t("tasks.tab.society")}</Title>

        <span className="tasks__list-counter">
          {!isTasksCompleted
            ? <>
              {t("tasks.card.complete")}: <strong>{doneGeneralTasks}</strong>/{generalTasksCount}
            </>
            : <strong>{t("tasks.card.done")}</strong>
          }
        </span>
      </div>

      <ul className="general-tasks">
        {generalTasks.map((task, index) => (
          <TaskItem
            key={task.id}
            index={index}
            task={task}
            onClick={() => handleExternalLinkOpen(task.payload?.link || "")}
          />
        ))}
        <li className="user-task user-task--img">
          <img
            src={bluredTask}
            alt=""
          />
        </li>
      </ul>
    </div>
  )
};
