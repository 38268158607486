import { useTranslation } from "react-i18next";
import checkIcon from "../../../../../assets/icons/check_cirle_black_bordered.svg";
import avatarPlaceholderGreen from "../../../../../assets/images/nft/EarlyPlaceholderGreen.png";
import avatarPlaceholderBlue from "../../../../../assets/images/nft/EarlyPlaceholderBlue.png";
import { NFTRarity, RarityType } from "../../types";
import css from "./EarlyNftCard.module.scss";
import { RarityChip } from "./RarityChip";

type IEarlyNftCardProps = {
  avatarUrl?: string
  titleI18Key: string
  rarity?: NFTRarity
  className?: string
  avatarType: "dashboard" | "bot"
}

export const EarlyNftCard: React.FC<IEarlyNftCardProps> = ({
  avatarUrl,
  titleI18Key,
  rarity,
  className,
  avatarType
}) => {
  const { t } = useTranslation()
  return <div className={`${css.wrapper} ${rarity ? css.wrapper_withChip : ""} ${className || ""}`}>
    <div className={css.wrapper_card}>
      {avatarUrl ? <img className={css.wrapper_card_check} src={checkIcon} alt="check_icon" /> : null}
      <div
        className={css.wrapper_card_img}
        style={{ backgroundImage: `url(${avatarUrl || (avatarType === "dashboard" ? avatarPlaceholderBlue : avatarPlaceholderGreen)})` }}
      />
      {rarity ? <RarityChip rarity={RarityType[rarity]} /> : null}
    </div>
    <div className={css.wrapper_title}>{t(titleI18Key)}</div>
  </div>
}
