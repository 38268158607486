import React, {FC, PropsWithChildren} from 'react';
import './NotAuthLayout.scss';
import cn from "classnames";

interface INotAuthLayoutProps {
  className?: string;
}

export const NotAuthLayout: FC<PropsWithChildren<INotAuthLayoutProps>> = ({ children, className }) => {
  return (
    <div className={cn("not-auth-layout", className)}>
      {children}
    </div>
  );
};
