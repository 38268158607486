import { petsStore } from "../store/petsStore";
import { IPet } from "../types";

export const getNextEvolutionAvailability = (currentPet: IPet) => {
  const isNextEvolutionBought = petsStore(({ pets }) =>
    pets.some(
      ({ rarity, race }) =>
        race === currentPet.race && rarity === currentPet.rarity + 1
    )
  );

  const isEvolutionAvailable =
    !isNextEvolutionBought && (currentPet.userPet?.level || 1) / 10 >= 1;

  return isEvolutionAvailable;
};
