import { NFTRarityDisplayProps, RarityType } from "../pages/claim/BlackPumaClaim/types";


export const NFT_RARITY_DICT: Record<RarityType, NFTRarityDisplayProps> = {
  [RarityType.common]: {
    color: "#CCCCCC",
    titleI18key: "claim.characters.rarity.common",
  },
  [RarityType.uncommon]: {
    color: "#83D987",
    titleI18key: "claim.characters.rarity.uncommon"
  },
  [RarityType.epic]: {
    color: "#C64EFF",
    titleI18key: "claim.characters.rarity.epic"
  },
  [RarityType.legendary]: {
    color: "#EEA12B",
    titleI18key: "claim.characters.rarity.legendary"
  },
  [RarityType.rare]: {
    color: "#2EB4FF",
    titleI18key: "claim.characters.rarity.rare"
  },
}
