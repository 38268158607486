import { create, useStore } from "zustand";

export interface ILoginStore {
  email: string;
  otpCode: string;
  emailErrorMessage: string;
  isOTPScreen: boolean;
  isOTPRequestPending: boolean;
  isOTPLoginPending: boolean;
  otpError: string;
  isMobileStartScreen: boolean;
  isLoggedIn: boolean;
}

export const loginStore = create<ILoginStore>(() => ({
  email: "",
  emailErrorMessage: "",
  otpCode: "",
  isMobileStartScreen: true,
  isOTPScreen: false,
  isOTPRequestPending: false,
  isOTPLoginPending: false,
  otpError: "",
  isLoggedIn: false,
}));

export const useLoginStore = () => useStore(loginStore);
