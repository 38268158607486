import { BaseService } from "../../../services/BaseService";
import { $api } from "../../../services/interceptor";
import { charactersStore, INftStore } from "./store";
import { ClaimStaus, EarlyNftType, GeneratedNftType, NftCollectionClaimsResponse, NftCollectionCurrentResponse, NftPropsRaw } from "./types";


class NftApi extends BaseService<INftStore> {
  constructor() {
    super(charactersStore);
  }

  _getCurrentNftCollection = async (): Promise<string | null> => {
    try {
      const { data } = await $api
        .get<NftCollectionCurrentResponse>(
          `/nft-collections/current`
        )
      if (data) {
        this.setState({
          nftCollectionCurrent: data || null
        })
        return data?.id || null
      } else {
        return null
      }

    } catch (error) {
      console.log("Error getCurrentNftCollection", error)
      return null
    }
  }

  _getUserNfts = async (
    collectionId: string,
    userId: string,
    wallet: string
  ) => {
    const walletParamString = wallet ? "wallet=" + wallet : ""
    const userIdParamString = userId ? "userId=" + userId : ""
    const requestString = `?${walletParamString}&${userIdParamString}`

    try {
      const { data } = await $api.get<NftCollectionClaimsResponse>("/nft-collection-claims" + requestString)

      const { claims, currentProcessingClaim } = data
      let nftCollectionClaimsArray = claims
      let nftCollectionClaimsCurrent = null

      if (currentProcessingClaim) {
        nftCollectionClaimsArray = [currentProcessingClaim, ...claims]
        nftCollectionClaimsCurrent = currentProcessingClaim
      }
      // for congrats modal claim button
      const isAbleToContinueCalim = nftCollectionClaimsCurrent?.claimStatus === ClaimStaus.started
      // for spinner button
      const isAbleToNewCalim = nftCollectionClaimsCurrent?.claimStatus === ClaimStaus.finished || !nftCollectionClaimsCurrent

      this.setState({
        nftCollectionClaimsArray,
        nftCollectionClaimsCurrent,
        isAbleToContinueCalim,
        isAbleToNewCalim
      })

    } catch (error) {
      this.setState({
        isAbleToNewCalim: true
      })
      console.log("Error getCurrentNftCollection", error)
    }
  }

  _generateNft = async (
    userId: string,
    wallet: string
  ) => {
    const request = {
      userId,
      wallet
    }

    try {
      const { data } = await $api.post<GeneratedNftType>(`/nft-collection-claims`, request)
      const { nftCollectionClaimsArray, nftCollectionCurrent } = this.getState()
      if (data) {
        this.setState({
          nftCollectionClaimsArray: [{
            ...data,
            collectionId: nftCollectionCurrent?.id || "",
          },
          ...nftCollectionClaimsArray
          ],
          nftClaimingItem: data,
          selectedNftToShow: data,
          isAbleToContinueCalim: true,
          isAbleToNewCalim: false
        })
      } else {
        console.log("Warning")
      }
    } catch (error) {
      console.log("Error getCurrentNftCollection", error)
    }
  }

  getEarlyNft = async (wallet: string) => {
    try {
      const { data } = await $api.get<EarlyNftType[]>(`/nft-collection-claims/early-claimed?wallet=${wallet}`)
      const dashboard = data.find(el => el.source === "dashboard") || null
      const clicker = data.find(el => el.source === "clicker") || null
      this.setState({ earlyNft: { dashboard, clicker } })
    } catch (error) {
      console.log(error)
    }

  }

  setClaimStatusToProcessing = async (nftCollectionClaimId: string) => {
    try {
      const { data } = await $api.patch<NftPropsRaw>(`/nft-collection-claims/${nftCollectionClaimId}/processing`)
      this.setState({
        nftClaimingItem: data,
        selectedNftToShow: data,
        isAbleToContinueCalim: false,
        isAbleToNewCalim: false
      })
      this.updateNftInState(data)
    } catch (error) {
      console.log(error)
    }
  }

  updateNftInState = (nft: NftPropsRaw) => {
    const nftList = [...this.getState().nftCollectionClaimsArray]
    const index = nftList.findIndex(el => el.id === nft.id)
    const isAbleToNewCalim = nft.claimStatus === "FINISHED"
    const isAbleToContinueCalim = nft.claimStatus === "STARTED"
    if (index >= 0) {
      nftList[index] = {
        ...nftList[index],
        ...nft
      }
      this.setState({
        nftCollectionClaimsArray: nftList,
        isAbleToNewCalim,
        isAbleToContinueCalim
      })
    } else {
      this.setState({
        nftCollectionClaimsArray: [nft, ...nftList],
        isAbleToNewCalim,
        isAbleToContinueCalim
      })
    }
  }

  getCurrentCollectionData = async (userId: string, wallet: string) => {
    this.setState({ currentNftCollectionLoading: true })
    await this.getEarlyNft(wallet)
    const currentCollectionId = await this._getCurrentNftCollection()
    if (currentCollectionId) {
      await this._getUserNfts(currentCollectionId, userId, wallet)
      this.setState({ currentNftCollectionLoading: false })
    } else {
      this.setState({ currentNftCollectionLoading: false })
    }
  }

  selectNft = (nftInfo: Partial<NftPropsRaw>) => {
    this.setState({ selectedNftToShow: nftInfo })
  }

  setIsNftInfoModalOpen = (value: boolean) => {
    this.setState({ isNftInfoModalOpen: value })
  }

  setIsNftCongratsModalOpen = (value: boolean) => {
    this.setState({ isNftCongratsModalOpen: value })
  }

}

export const charactersService = new NftApi();
