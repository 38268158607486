import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/Buttons/Button"
import { FormEvent } from "react";
import { InputField, InputFieldErrors } from "../../../../../newComponents/Input";
import accountLinkingService from "../../../../login/services/accountLinkingService";
import { useAccountLinkingStore } from "../../../../login/store/accountLinkingStore";

export const AccountCreationStepEmail = () => {
  const { t } = useTranslation()

  const {
    email,
    emailErrorMessage,
    otpRequestLoading
  } = useAccountLinkingStore()

  const onEmailChange = (value: string) => {
    if (emailErrorMessage) {
      accountLinkingService.clearEmailError();
    }

    accountLinkingService.setEmail(value);
  }

  const onSubmitEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    accountLinkingService.sendEmailForLinkingFromProfileEditPage(email)
    // TODO
    // await loginService.sendOtpRequest(email);
  };
  let emailInputStatus: InputFieldErrors | null = null;
  let emailInputMessage: string | null = null;
  if (emailErrorMessage) {
    emailInputStatus = InputFieldErrors.ERROR;
    emailInputMessage = emailErrorMessage;
  }

  return <form
    id="account-email-linking"
    className="account-email-linking-modal"
    onSubmit={onSubmitEmail}
  >
    <InputField
      label={t("auth.signup.input.label")}
      type="email"
      placeholder={t("login.email")}
      onChange={onEmailChange}
      status={emailInputStatus}
      message={emailInputMessage}
    />
    <Button
      form="account-email-linking"
      isLoading={otpRequestLoading}
      variant="secondary"
      size="large"
      isUppercased
      type="submit"
    // onClick={handleSendEmail}
    >
      {t("auth.linkAccount.step1.button")}
    </Button>
  </form>
}