import { create, useStore } from "zustand";

export interface IHealthResponse {
  isTecnicalWorks: boolean,
  isTecnicalWorksLoading: boolean,
}

export const healthStore = create<IHealthResponse>(() => ({
  isTecnicalWorks: true,
  isTecnicalWorksLoading: true,
}));

export const useHealthStore = () => useStore(healthStore);
