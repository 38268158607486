import React, { SVGAttributes } from "react";

export const DoneIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M8.90604 0.68883C9.10754 0.866919 9.12828 1.17666 8.95237 1.38065L3.87865 7.26442C3.78668 7.37108 3.65365 7.43228 3.51381 7.43228C3.37396 7.43228 3.24094 7.37108 3.14897 7.26442L1.11948 4.91091C0.94357 4.70692 0.964312 4.39718 1.16581 4.21909C1.3673 4.041 1.67325 4.062 1.84916 4.266L3.51381 6.19642L8.2227 0.735733C8.3986 0.53174 8.70455 0.510741 8.90604 0.68883Z"
        fill="currentColor" stroke="currentColor" strokeWidth="0.5" strokeLinecap="round"/>
    </svg>
  );
};
