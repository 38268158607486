import { PropsWithChildren } from "react";
import { useIsMobileResolution } from "../../hooks/useIsMobileResolution";
import classNames from "classnames";

export interface IPixelatedBordersWrapperProps {
  width?: number;
  mobileWidth?: number;
  color: string;
  className?: string;
}

const Border = ({
  bgColor = "bg-gray-primary",
  left,
  right,
  top,
  bottom,
  width,
  height,
}: {
  bgColor?: string;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  width?: number;
  height?: number;
}) => {
  return (
    <div
      className={"absolute"}
      style={{
        left,
        right,
        bottom,
        top,
        width,
        height,
        backgroundColor: bgColor,
      }}
    ></div>
  );
};

const Borders = ({ bgColor, width }: { bgColor?: string; width: number }) => (
  <>
    <Border top={-width} right={0} left={0} height={width} bgColor={bgColor} />
    <Border right={-width} top={0} bottom={0} width={width} bgColor={bgColor} />
    <Border
      bottom={-width}
      right={0}
      left={0}
      height={width}
      bgColor={bgColor}
    />
    <Border left={-width} top={0} bottom={0} width={width} bgColor={bgColor} />
  </>
);

const PixelatedBordersWrapper: React.FC<
  PropsWithChildren<{
    width?: number;
    mobileWidth?: number;
    color: string;
    className?: string;
  }>
> = ({ children, color, width = 6, mobileWidth = 4, className = "" }) => {
  const isMobile = useIsMobileResolution();

  return (
    <div
      className={classNames(
        "relative w-full h-full border-transparent",
        className
      )}
      style={{ borderWidth: isMobile ? mobileWidth : width }}
    >
      {children}
      <Borders bgColor={color} width={isMobile ? mobileWidth : width} />
    </div>
  );
};

export default PixelatedBordersWrapper;
