import styles from "./PointsLabel.module.scss"

export const PointsLabel = ({
  amount,
  isShown = true,
}: {
  amount?: number;
  isShown?: boolean;
}) => {
  if (!isShown || !amount) {
    return null;
  }

  return (
    <div className={styles.text}>
      (+{amount} PIX)
    </div>
  );
};
