import React from "react";
import css from "../styles.module.scss";
import classNames from "classnames";
import Social from "../../../../../components/Sidebar/components/Social/Social";
import { SliderArrow } from "../../../../../assets/iconComponents/SliderArrow";
import { StakeDataType } from "../../../types";
import { Trans, useTranslation } from "react-i18next";

interface GenesisWithdrawSectionProps {
  genesis: StakeDataType;
  withdrawAvailableDate?: string;
  GENESIS_PERCENT: number;
  onToggleGenesisModal: () => void;
  handleWithdraw: () => Promise<void>;
  isPending: boolean;
}

export const GenesisWithdrawSection: React.FC<GenesisWithdrawSectionProps> = ({
  genesis,
  GENESIS_PERCENT,
  onToggleGenesisModal,
  handleWithdraw,
  isPending,
}) => {
  const { t } = useTranslation();

  const withdraw = async () => {
    await handleWithdraw();
    onToggleGenesisModal();
  };

  return (
    <div className={css.genesisWithdrawWrapper}>
      <h3>{t("staking.genesisWithdraw.areYouSure")}</h3>

      <div className={css.genesisWithdrawWarning}>
        <div className={css.genesisWithdrawLabel}>{t("staking.genesisWithdraw.warning")}</div>

        <div className={css.genesisWithdrawPersentBlock}>
          <span className={css.genesisWithdrawPersent}>{GENESIS_PERCENT}%</span>
          <div>
            <span className={css.genesisWithdrawAvaibleValue}>
              {t("staking.genesisWithdraw.availableToWithdraw")}
              <br />
              {`${((GENESIS_PERCENT / 100) * +genesis.amount).toFixed(2)} `}
              {t("airdrop.claim.pixfi.currency")}
            </span>
          </div>
        </div>

        <p>
          {t("staking.genesisWithdraw.atTheMoment")}
          <strong>
            <Trans
              i18nKey={"staking.genesisWithdraw.willLostForever"}
              values={{ value: `${(+genesis.amount - (GENESIS_PERCENT / 100) * +genesis.amount).toFixed(2)} ` }}
            />
          </strong>
        </p>
      </div>

      <div>
        <div className={css.genesisWithdrawWrapperSocials}>
          <div>
            <p>{t("staking.genesisWithdraw.WeWillNotify")}</p>
          </div>

          <div className={css.genesisWithdrawSocials}>
            <Social />
          </div>
        </div>

        <div className={css.withdrawBtnsWrapper}>
          <button onClick={onToggleGenesisModal} className={classNames(css.withdrawBtnCencel)}>
            {t("staking.genesisWithdraw.cancel")}
          </button>

          <button className={classNames(css.withdrawBtnWithdraw)} onClick={withdraw} disabled={isPending}>
            <SliderArrow className={css.arrow} stroke="#FF3D3D" />
          </button>
        </div>
      </div>
    </div>
  );
};
