import React from "react"
import { IIconProps } from "./new_design_icons/types"

export const TasksIcon = ({ iconColor, ...props }: IIconProps) => {
	return (
		<svg
			width="16"
			height="22"
			viewBox="0 0 16 22"
			xmlns="http://www.w3.org/2000/svg"
			fill={iconColor || "currentColor"}
			{...props}
		>
			<path
				d="M15 4V3H12V2H11V1H10V0H6V1H5V2H4V3H1V4H0V21H1V22H15V21H16V4H15ZM6 2H7V1H9V2H10V4H9V5H7V4H6V2ZM2 5H4V6H12V5H14V20H2V5Z"
			/>
		</svg>
	)
}
