import { Button } from "../../../../components/Buttons/Button";
import { SocialName } from "../../../../services/user/types";
import { PointsLabel } from "./PointsLabel";
import { userService } from "../../../../services/user/service";
import { useTranslation } from "react-i18next";
import styles from "./SocialButton.module.scss";

export const SocialButton = ({
  social,
  connectionReward,
  connectedName,
  isRewardRecieved,
  handleConnect,
  wrapperClassName
}: {
  social: SocialName;
  connectionReward?: number;
  connectedName?: string | null;
  isRewardRecieved: boolean;
  handleConnect: () => void;
  wrapperClassName?: string;
}) => {
  const isConnected = !!connectedName;
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <span className={styles.label_text}>{social}</span>
        {!isRewardRecieved && <PointsLabel amount={connectionReward} />}
      </div>
      <Button
        className={`${styles.btn} ${isConnected ? "" : styles.not_connected}`}
        onClick={
          !isConnected
            ? handleConnect
            : () => userService.disconnectSocial(social)
        }
      >
        {isConnected ? (
          <div className={styles.text_name}>@{connectedName}</div>
        ) : (
          <div className={styles.text_connect}>
            {t('profile.info.socials.connect')}
          </div>
        )}
      </Button>
    </div>

  );
};
