import {useState} from "react";

type ICopyTextArg = string;

interface IUseCopyText {
  onCopyTextClick: (value: ICopyTextArg) => Promise<void>;
  isTextCopied: boolean;
}

export const useCopyText = (): IUseCopyText => {
  const [
    isTextCopied,
    setIsTextCopied
  ] = useState<IUseCopyText['isTextCopied']>(false);

  const onCopyTextClick = async (value: ICopyTextArg): Promise<void> => {
    try {
      await navigator.clipboard.writeText(value);

      setIsTextCopied(true);

      setTimeout(() => setIsTextCopied(false), 1500);
    } catch (err) {
      console.error(err);
    }
  }

  return {
    onCopyTextClick,
    isTextCopied,
  }
}
