import { SocialName, UserData } from "../services/user/types";

export const getConnectedSocial = (
  user: UserData | null,
  socialName: SocialName
) => {
  if (!user) {
    return;
  }

  const socials = user.connectedSocials;

  const requiredSocial = socials.find(
    ({ social }) => social.name === socialName
  );

  return requiredSocial;
};
