import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";

import AvatarPlaceholder from "../../../../assets/images/AvatarPlaceholder.png";
import { userStore } from "../../../../services/user/store";
import { Loader } from "../../../../components/Loader";
import { userProfileStore } from "../../store/store";
import styles from "./avatar.module.scss";
import React, { useEffect, useState } from "react";
import { useAvatarsStore } from "./AvatarSelection/store";
import { Card } from "../../../../components/Card/Card";
import { Reactions } from "../Info/reactions";
import { EditIcon } from "../../../../assets/iconComponents/new_design_icons/EditIcon";
import { useIsMobileResolution } from "../../../../hooks/useIsMobileResolution";
import classNames from "classnames";
import { NFT_AVATAR_ID } from "../../constants";

type IProfileAvatarProps = {
  handleOpen: () => void
}

export const ProfileAvatar: React.FC<IProfileAvatarProps> = ({ handleOpen }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobileResolution()
  const [avatarName, setAvatarName] = useState("")

  const userProfile = userProfileStore(useShallow(({ user }) => user));

  const accountUserID = userStore(({ user }) => user?.id);
  const { avatars } = useAvatarsStore();

  const isLoading = userProfileStore(
    useShallow(({ loading }) => (userProfile ? false : loading))
  );

  useEffect(() => {
    if (userProfile && userProfile.avatar && userProfile.avatar.id) {
      setAvatarName(avatars.find(a => a.userAvatar ? a.userAvatar?.id === userProfile.avatar.id : false)?.name!)
    }

  }, [avatars, userProfile])

  const isAnotherUser = userProfile?.id !== accountUserID;
  return (
    <div className={styles.container}>
      <div
        className={styles.avatarCard}
        style={!isAnotherUser ? { cursor: "pointer" } : {}}
        onClick={!isAnotherUser ? handleOpen : () => { }}
      >
        {!isMobile && userProfile && <div className={styles.mask}>
          <span>{t("profile.avatar.change")}</span>
          <EditIcon />
        </div>}
        <div className={styles.shine} />
        <div className={styles.header}>
          <h3>{avatarName}</h3>
        </div>
        <div className={classNames(
          styles.avatar_container,
          userProfile?.avatar?.avatar.id === NFT_AVATAR_ID ? styles.nft : ""
        )}>
          {isLoading ? (
            <div className={styles.loader_container}>
              <Loader />
            </div>
          ) : (
            <img
              src={
                userProfile?.avatar?.avatar?.imageUrl || AvatarPlaceholder
              }
              alt="avatar"
            />
          )}
        </div>
      </div>
      <Card className={styles.avatarReactions}>
        <div className={styles.avatarReactions_title}>
          {t("profile.reactions")}
        </div>
        <Reactions />
      </Card>
    </div>
  );
};
