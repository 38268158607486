import { SVGAttributes } from "react";

export const TwitterIcon = ({ ...props }: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1136 2.10709H17.7915L11.941 8.79375L18.8236 17.8928H13.4338L9.21293 12.3743L4.38331 17.8928H1.70379L7.96141 10.7407L1.35974 2.10709H6.88474L10.7 7.15123L15.1119 2.10709H15.1136ZM14.1737 16.2899H15.6576L6.07927 3.62575H4.48693L14.1737 16.2899Z"
      />
    </svg>
  );
};
