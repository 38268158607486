import { ChangeEvent, InputHTMLAttributes, PropsWithChildren } from 'react';
import './CheckboxField.scss';
import cn from 'classnames';

interface ICheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: boolean;
  onChange?: (isChecked: boolean) => void;
}

export const CheckboxField = (props: PropsWithChildren<ICheckboxProps>) => {
  const {
    children,
    label,
    className,
    name,
    id,
    checked,
    disabled = false,
    onChange,
  } = props;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <label
      className={cn('checkbox-field', className)}
      htmlFor={id}
    >
      <span className="checkbox-field__label">{label || children}</span>

      <span className="checkbox-field__container">
        <input
          id={id}
          className="checkbox-field__input"
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={!disabled ? onChangeHandler : undefined}
        />

        <span className="checkbox-field__checked"/>
      </span>
    </label>
  );
};
