import React, { HTMLAttributes } from 'react';
import cn from "classnames";


interface ICircleProgressBarProps extends HTMLAttributes<HTMLOrSVGElement> {
  progress: number;
}

export const CircleProgressBar: React.FC<ICircleProgressBarProps> = ({
  progress = 0,
  className
}) => {
  const radius = 19.5;
  const strokeWidth = 5;

  const circumference = 2 * Math.PI * radius;
  const offset = circumference - ((progress / 100) * circumference);
  return (
    <svg className={cn("progress-bar", className)} width="44" height="44" viewBox="0 0 44 44">
      <circle
        cx="22"
        cy="22"
        r={radius}
        fill="transparent"
        stroke="#393143"
        strokeWidth={strokeWidth}
      />
      <circle
        cx="22"
        cy="22"
        r={radius}
        fill="transparent"
        stroke="#B364D8"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset || 0}
        strokeLinecap="round"
        transform="rotate(-90 22 22)"
      />
    </svg>
  );
}

