import React, {FC} from 'react';
import {CopyIcon} from "../../../../../assets/iconComponents/new_design_icons/CopyIcon";
import {useTranslation} from "react-i18next";
import {useCopyText} from "../../../../../newHooks/useCopyText";
import './ReferralsCopyLink.scss';
import cn from "classnames";
import {Card} from "../../../../../newComponents/Card/Card";

interface IReferralsCopyLinkProps {
  className?: string;
  linkValue: string;
}

export const ReferralsCopyLink: FC<IReferralsCopyLinkProps> = ({ linkValue, className }) => {
  const { t } = useTranslation();
  const { onCopyTextClick, isTextCopied } = useCopyText();

  const cl = cn('referrals-copy-link', className);

  return (
    <Card className={cl}>
      <b className="referrals-copy-link__label">{t("referrals.link")}</b>

      <input className="referrals-copy-link__input" type="text" defaultValue={linkValue} readOnly />

      {isTextCopied && <span className="referrals-copy-link__notification">Copied</span>}

      <button
        className="referrals-copy-link__button"
        type="button"
        onClick={!isTextCopied ? () => onCopyTextClick(linkValue): undefined}
        disabled={isTextCopied}
      >
        <CopyIcon/>
      </button>
    </Card>
  );
};
