export enum TabsEnum {
  Society = "Society",
  Individual = "Individual",
}

export interface IRawTask {
  id: string;
  descriptionLocalizationKey: string;
  titleLocalizationKey: string;
  target: number;
  progress: number;
  iconUrl: string;
  color: string;
  payload?: { link?: string };
  userSnapshotResult?: {
    snapshotsUrls?: string[];
    status: "IN_PROGRESS" | "DONE" | "REVIEW" | "REJECTED";
  };
}

export enum RawTaskType {
  twitter = "TWITTER",
  referral = "REFERRAL",
  telegram = "TELEGRAM",
  discord = "DISCORD",
  snapshot = "SNAPSHOT",
  registerWithTelegramTask = "REGISTER_WITH_TELEGRAM",
}
export enum TaskType {
  twitter = "TWITTER",
  referral = "REFERRAL",
  telegram = "TELEGRAM",
  discord = "DISCORD",
  general = "GENERAL",
  snapshot = "SNAPSHOT",
  registerWithTelegramTask = "REGISTER_WITH_TELEGRAM",
  connectBybitWallet = "CONNECT_BYBIT_WALLET",
  connectOkxWallet = "CONNECT_OKX_WALLET",
}

export enum TwitterTaskType {
  SUBSCRIBE = "SUBSCRIBE",
  RETWEET_POST = "RETWEET_POST",
  LIKE_POST = "LIKE_POST",
}

export interface ITwitterTask {
  id: string;
  type: TwitterTaskType;
  twitterResourceId: string;
  taskId: string;
}

export interface IRawUserTask {
  id: string;
  type: RawTaskType;
  availableFromLevel: number;
  title: string;
  description: string;
  rewardPointsAmount: number;
  rewardTicketsAmount?: number;
  created_at: string;
  redirectUrl?: string;
  inviteCount: number;
  maxSuccessfullyCompletions?: number;
  userTaskId?: string;
  twitterTask?: ITwitterTask;
  userSnapshotResult?: {
    snapshotsUrls?: string[];
    status: "IN_PROGRESS" | "DONE" | "REVIEW" | "REJECTED";
  };
}

export interface IRawUserTasks {
  available: Array<IRawUserTask>;
  inProgress: Array<IRawUserTask>;
  done: Array<IRawUserTask>;
}

export interface ITaskPayload {
  link?: string;
  userTaskID?: string;
  twitterTaskInfo?: ITwitterTask;
}

export interface ITask {
  id: string;
  title: string;
  description: string;
  currentProgress: number;
  maxProgress: number;
  isTaskDone: boolean;
  color: string;
  iconURL: string;
  type: TaskType;
  pointsReward?: number;
  payload?: ITaskPayload;
  userSnapshotResult?: {
    snapshotsUrls?: string[];
    status: "IN_PROGRESS" | "DONE" | "REVIEW" | "REJECTED";
  };
}

export interface TaskItemProps {
  task: ITask;
  index: number;
  disabled?: boolean;
  disabledTooltip?: string;
  hideDetailsProgress?: boolean;
  withoutTitle?: boolean;
  onClick: () => void;
}

export type TaskListProps = ITask[];
