import { TelegramIcon } from "../../assets/iconComponents/TelegramIcon";
import { TwitterIcon } from "../../assets/iconComponents/TwitterIcon";
import { DiscordIcon } from "../../assets/iconComponents/DiscordIcon";
import { ISvgIcon } from "../../types/icons";

export const PV_SOCIAL: Array<{ Icon: ISvgIcon, href: string }> = [
  { Icon: TelegramIcon, href: "https://t.me/pixelverse_xyz" },
  { Icon: TwitterIcon, href: "https://twitter.com/pixelverse_xyz" },
  { Icon: DiscordIcon, href: "https://discord.gg/pixelverse" },
];

export const PV_DOCS_HREF: string = "https://docs.pixelverse.xyz";
export const PV_TG_BOT_HREF: string = "https://t.me/pixelversexyzbot";
export const PV_GP_MARKET: string = "https://play.pixelverse.xyz";
export const PV_CLAIM_PIXFI: string = "https://partner.bybit.com/b/annoucpixlv";
