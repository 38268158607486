import React, { CSSProperties } from "react";

import { CardsAnimation } from "./components/CardsAnmation";
import { Welcome } from "./components/Welcome/Welcome";
import GenerationBg from "../../assets/images/DashboardBG.png";

interface CustomStyle extends CSSProperties {
  "--generation-bg-image"?: string;
}

const style: CustomStyle = {
  "--generation-bg-image": `url(${GenerationBg})`,
};

export const AvatarsGeneration: React.FC = () => {
  return (
    <div
      className="h-screen w-full flex justify-end overflow-hidden bg-[image:var(--generation-bg-image)]"
      style={style}
    >
      <div className="bg-[#00000080] absolute w-full md:w-[68%] min-h-fit md:h-full top-[32%] md:top-0 bottom-0 left-0 z-10 backdrop-blur-sm px-[14px] md:px-[40px] flex flex-col items-center md:items-start overflow-y-auto">
        <Welcome />
      </div>

      <CardsAnimation />
    </div>
  );
};
