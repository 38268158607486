export enum PointActions {
  ENTER_REFERRAL_CODE = "ENTER_REFERRAL_CODE",
  ENTER_USERNAME = "ENTER_USERNAME",
  CONNECT_TELEGRAM = "CONNECT_TELEGRAM",
  CONNECT_TWITTER = "CONNECT_TWITTER",
  CONNECT_DISCORD = "CONNECT_DISCORD",
  CONNECT_WALLET = "CONNECT_WALLET",
  CONNECT_WALLET_BYBIT = "CONNECT_WALLET_BYBIT",
  CONNECT_WALLET_OKX = "CONNECT_WALLET_OKX",
  ENTER_BIO = "ENTER_BIO",
}

export type EventPoints = Record<PointActions, number>;
