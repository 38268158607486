import { useShallow } from "zustand/react/shallow";
import { Button } from "../../../../../components/Buttons/Button";
import { IAvatarInernal, avatarsStore } from "./store";
import { useTranslation } from "react-i18next";
import { BuyAvatarModal } from "./BuyModal";
import { useState } from "react";
import avatarsService from "./service";
import styles from "./InformationCard.module.scss"
import { Card } from "../../../../../components/Card/Card";
import { useIsMobileResolution } from "../../../../../hooks/useIsMobileResolution";
import { userProfileService } from "../../../service/service";
import classNames from "classnames";

export const InformationCard = ({
  avatar,
  isChoosen = false,
  setIsModalOpen,
  isBought,
}: {
  avatar?: IAvatarInernal;
  isChoosen?: boolean;
  isBought?: boolean;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    name,
    id,
    description,
    descriptionLocalizationKey,
    nameLocalizationKey,
  } = avatar || {};
  const isEditPending = avatarsStore(
    useShallow(({ isEditPending }) => isEditPending)
  );
  const { t } = useTranslation();
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const isMobile = useIsMobileResolution()

  const handleBuy = async () => {
    await avatarsService.buyAvatar(avatar?.id || "");
    setIsBuyModalOpen(false);
  };

  if (isMobile) {
    return null
  }

  return (
    <>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.avatar,
            avatar?.isNft ? styles.nft : ""
          )}
        >
          {avatar?.imageUrl && (<img src={avatar.imageUrl} alt="" />)}
          <div className={styles.shine} />
        </div>
        <Card className={styles.card}>
          <div className={styles.name}>
            {t(nameLocalizationKey || "") || name}
          </div>
          <div className={styles.helper_text}>
            {t("profile.avatars.info.subtitle")}
          </div>
          <div className={`${styles.description} custom-scrollbar`}>
            {t(descriptionLocalizationKey || "") || description}
          </div>
          <div className={styles.btn}>
            <Button
              variant="secondary"
              size="large"
              decoration="outlined"
              isUppercased
              style={!isBought ? { borderColor: "#83D987" } : {}}

              disabled={isEditPending}
              onClick={() => {
                if (isBought) {
                  id && userProfileService.setSelectedAvatarToEditId(id);
                  setIsModalOpen && setIsModalOpen(false);
                } else {
                  setIsBuyModalOpen(true);
                }
              }}
            >
              {isBought && (
                <span className="">
                  {isChoosen
                    ? t("buttons.chosen-avatar")
                    : t("profile.avatars.choose")}
                </span>
              )}

              {!isBought && (
                <span className={styles.text}>{t("buttons.buy")}</span>
              )}
            </Button>
          </div>
        </Card>
      </div >

      <BuyAvatarModal
        open={isBuyModalOpen}
        onClose={() => setIsBuyModalOpen(false)}
        name={avatar?.name || ""}
        price={avatar?.price || 0}
        handleBuy={handleBuy}
      />
    </>
  );
};
