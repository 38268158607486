import css from "../styles.module.scss";
import cn from "classnames";
import Modal from "@mui/material/Modal/Modal";
import { Zoom } from "@mui/material";
import { SubModalCloseIcon } from "../../../../../assets/iconComponents/SubModalCloseIcon";
import pixfiIcon from "../../../../../assets/images/pixFiIcon.png"
import golds from "../../../../../assets/images/golds.png";
import { useTranslation } from "react-i18next";

export const StakeSucessModal = ({
  onClose,
  open,
  stakingAmount,
}: {
  onClose: () => void;
  open: boolean;
  stakingAmount: string;
}) => {
  const { t } = useTranslation();
  const subtitle = "You have successfully staked PIXFI"
  const paragraph = t("staking.lockYourFunds")
  const btnText = "Excellent"

  return (
    <Modal
      open={open}
      onClose={onClose}
      classes={{
        root: css.modalRoot,
        backdrop: css.modalBackdrop,
      }}
      sx={{ outline: "none !important" }}
    >
      <Zoom
        in={open}
        style={{ transitionDelay: open ? "200ms" : "100ms", outline: "none" }}
      >
        <div className={css.subModalWrapper}>

          <img
            src={golds}
            alt="content"
            className={cn(
              css.contentIcon,
              css.contentIconWithdrawn,
            )}
          />
          <div className={css.stakingValue}>
            <img
              src={pixfiIcon}
              alt="content"
            />
            <span>{stakingAmount}</span>
          </div>
          <header className={css.subModalHeader}>
            <h3 className={css.subModalTitle}>
              Staking
            </h3>
            <SubModalCloseIcon
              className={css.subCloseIcon}
              onClick={onClose}
            />
          </header>
          <div className={css.subModalFooter}>
            <p className={css.subtitle}>
              {subtitle}
            </p>
            <p className={css.subtitleLight}>
              {paragraph}
            </p>
            <button className={css.stakeBtnPurple} onClick={onClose}>
              {btnText}
            </button>
          </div>
        </div>
      </Zoom>
    </Modal>
  );
};
