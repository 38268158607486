import styles from "./ToggleSidebarBtn.module.scss"
import {FC, HTMLAttributes} from "react";

interface IToggleSidebarBtnProps extends HTMLAttributes<HTMLButtonElement> {
  toggleSidebar: () => void
}

export const ToggleSidebarBtn: FC<IToggleSidebarBtnProps> = ({ toggleSidebar, className }) => {
  return <button className={`${styles.sidebar_btn} ${className}`} onClick={toggleSidebar}>
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="63" height="63" rx="22.5" fill="#151517"/>
      <rect x="0.5" y="0.5" width="63" height="63" rx="22.5" stroke="url(#paint0_linear_5171_58875)"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M43.6654 25.332C43.6654 25.5972 43.56 25.8516 43.3725 26.0391C43.1849 26.2267 42.9306 26.332 42.6654 26.332H21.332C21.0668 26.332 20.8125 26.2267 20.6249 26.0391C20.4374 25.8516 20.332 25.5972 20.332 25.332C20.332 25.0668 20.4374 24.8125 20.6249 24.6249C20.8125 24.4374 21.0668 24.332 21.332 24.332H42.6654C42.9306 24.332 43.1849 24.4374 43.3725 24.6249C43.56 24.8125 43.6654 25.0668 43.6654 25.332ZM43.6654 31.9987C43.6654 32.2639 43.56 32.5183 43.3725 32.7058C43.1849 32.8933 42.9306 32.9987 42.6654 32.9987H21.332C21.0668 32.9987 20.8125 32.8933 20.6249 32.7058C20.4374 32.5183 20.332 32.2639 20.332 31.9987C20.332 31.7335 20.4374 31.4791 20.6249 31.2916C20.8125 31.1041 21.0668 30.9987 21.332 30.9987H42.6654C42.9306 30.9987 43.1849 31.1041 43.3725 31.2916C43.56 31.4791 43.6654 31.7335 43.6654 31.9987ZM35.6654 38.6654C35.6654 38.9306 35.56 39.1849 35.3725 39.3725C35.1849 39.56 34.9306 39.6654 34.6654 39.6654H21.332C21.0668 39.6654 20.8125 39.56 20.6249 39.3725C20.4374 39.1849 20.332 38.9306 20.332 38.6654C20.332 38.4001 20.4374 38.1458 20.6249 37.9583C20.8125 37.7707 21.0668 37.6654 21.332 37.6654H34.6654C34.9306 37.6654 35.1849 37.7707 35.3725 37.9583C35.56 38.1458 35.6654 38.4001 35.6654 38.6654Z"
            fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_5171_58875" x1="5.82904" y1="9.69327" x2="37.5702" y2="33.3753"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#1D1D20"/>
          <stop offset="1" stopColor="#2B2C30" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  </button>
}
