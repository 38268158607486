import { create, useStore } from "zustand";
import { IUserStore } from "./types";

export const userStore = create<IUserStore>(() => ({
  user: null,
  loading: false,
  error: false,
  socialError: "",
  reactions: [],
}));

export const useUserStore = () => useStore(userStore);
