import { charactersService } from "../../service";
import { NftPropsRaw } from "../../types";
import { RarityChip } from "../ClaimedEarly/RarityChip";
import css from "./ClaimedItem.module.scss";

type IClaimedItemProps = Partial<NftPropsRaw>

export const ClaimedItem: React.FC<IClaimedItemProps> = (props) => {
  const {
    url,
    id,
    rarity,
    index
  } = props

  const isEmpty = !(url && id)

  const handleClick = () => {
    if (url && id) {
      charactersService.selectNft(props)
      charactersService.setIsNftInfoModalOpen(true)
    } else {
      console.log("NFT Select error")
    }

  }

  return <div
    className={`${css.wrapper} ${isEmpty ? css.empty : ""}`}
    onClick={isEmpty ? () => { } : handleClick}
  >
    <div
      className={isEmpty ? css.wrapper_image_empty : css.wrapper_image}
      style={url ? { backgroundImage: `url(${url})` } : {}}
    />
    <div className={isEmpty ? css.wrapper_content_empty : css.wrapper_content}>
      {isEmpty ? null : <>
        <h3>#{index || "??????"}</h3>
        {rarity ? <RarityChip rarity={rarity} /> : null}
      </>}
    </div>
  </div>
}
