import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/Buttons/Button";

import css from "../../../styles.module.scss";
import { ModalWithHeader } from "../../../../../components/Modals/ModalWithHeader";
import { AvatarCard } from "../../../../profile/components/Avatar/AvatarSelection/AvatarCard";
import { PetRace } from "../../../types";
import { usePetsStore } from "../../../store/petsStore";
import { petsService } from "../../../service/service";
import { getErrorMessage } from "../../../../../utils/getErrorMessage";

export const SellModal = () => {
  const { t } = useTranslation();
  const { sellPetInfo, selectedPet } = usePetsStore()

  if (!sellPetInfo) {
    return null
  }

  const handleSell = async () => {
    if (selectedPet) {
      // const isSellingUsedPet = selectedPet.id === userPet?.id;
      try {
        await petsService.sellPet({ userPetId: selectedPet?.userPet?.id! });
        petsService.setSellPetInfo(null);

        // if (isSellingUsedPet) {
        //   setCurrentPet(undefined);
        // }
      } catch (e) {
        petsService.setSellError(getErrorMessage(e));
      }
    }
  };

  const onClose = () => {
    petsService.setSellPetInfo(null)
  }

  return (
    <ModalWithHeader
      open={!!sellPetInfo}
      onClose={onClose}
      title={`${t("pets.sell.title")} ${sellPetInfo.name}`}
    >
      <div className={css.confirmSellPetModalWrapper}>
        <div className={css.confirmSellPetInfo}>
          <div className={css.left}>
            <AvatarCard
              isPet
              className={css.sellAvatarCard}
              isBought={true}
              noDecorations
              avatar={{
                ...sellPetInfo,
                id: "",
                description: "",
                price: 0,
                multiplier: 0,
                requiredLevel: 0,
                rarity: 0,
                race: PetRace.CACTUSES,
                descriptionLocalizationKey: "",
                nameLocalizationKey: ""
              }}
            />
          </div>
          <div className={css.right}>
            <h4 className={css.confirmSellPetTitle}>
              {t("pets.modal.attention")}
            </h4>
            <p className={css.confirmSellPetSubtitle}>{t("pets.modal.sell")}</p>
          </div>
        </div>

        <div className={`${css.btnsWrapper}`}>
          <Button
            onClick={onClose}
            size="large"
            isUppercased
          >
            {t("buttons.cancel")}
          </Button>
          <Button
            onClick={() => {
              handleSell();
              onClose();
            }}
            variant={"secondary"}
            size="large"
            isUppercased
          >
            {t("buttons.pet-sell", {
              points: sellPetInfo.sellPrice,
            })}
          </Button>
        </div>
      </div>
    </ModalWithHeader>
  );
};
