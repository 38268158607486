import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { useCharactersStore } from "../../store";
import { NftPropsRaw } from "../../types";
import { ClaimedItem } from "./ClaimedItem";
import css from "./ClaimedList.module.scss";


export const ClaimedList = () => {
  const { t } = useTranslation()
  const windowSize = useWindowSize()
  const [elementsWillFitInTheList, setElementsWillFitInTheList] = useState(1)
  const [resultNftList, setResultNftList] = useState<(NftPropsRaw | null)[]>([])
  const { nftCollectionClaimsArray } = useCharactersStore()
  const listRef = useRef<HTMLUListElement | null>(null)

  useEffect(() => {
    if (windowSize.width < 476) {
      setElementsWillFitInTheList(3)
    } else {
      const listHeight = listRef.current?.offsetHeight
      if (listHeight) {
        setElementsWillFitInTheList(Math.floor(listHeight / 85))
      }
    }
  }, [windowSize])

  useEffect(() => {
    if (nftCollectionClaimsArray.length > elementsWillFitInTheList) {
      setResultNftList([...nftCollectionClaimsArray, null])
    } else {
      const emptyItems = elementsWillFitInTheList - nftCollectionClaimsArray.length
      setResultNftList(
        nftCollectionClaimsArray
          .concat(Array.from({ length: emptyItems }))
      )

    }
  }, [elementsWillFitInTheList, nftCollectionClaimsArray])

  return <div className={css.wrapper}>
    <div className={`${css.wrapper_inner}`}>
      <h3 className={css.wrapper_inner_title}>{t("claim.characters.claimed.title")}</h3>
      <ul
        ref={listRef}
        className={css.wrapper_inner_list}
      >
        {
          resultNftList
            .map((el, i) => <ClaimedItem
              key={el?.createdAt || `${el?.id}` + i}
              {...el}
            />)
        }
      </ul>
      <div className={css.wrapper_bottom_fade} />
    </div>
  </div>
}
