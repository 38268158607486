import pixCoin from "../../../../../../assets/icons/PIXcoin.png";
import avatarPlaceholder from "../../../../../../assets/images/AvatarPlaceholder.png";
import css from "./AccountCard.module.scss";

type IAccountCardProps = {
  selected?: boolean
  level?: number | string
  name?: string
  accountSocial?: string
  pixAmount?: string | number
  avatarUrl?: string | null
  onSelect: () => void
}

export const AccountCard: React.FC<IAccountCardProps> = ({
  selected = false,
  name = "Name not defined",
  accountSocial = "",
  level = 0,
  pixAmount = 0,
  avatarUrl,
  onSelect
}) => {
  return <div
    className={`${css.card} ${selected ? css.selected : ""}`}
    onClick={onSelect}
  >
    <div className={css.avatar}>
      <div className={css.shine} />
      <div className={css.img} style={{ backgroundImage: `url(${avatarUrl || avatarPlaceholder})` }} />
      <span className={css.level}>{level} lvl</span>
    </div>
    <h3 className={css.name}>{name}</h3>
    <p className={css.social}>{accountSocial}</p>
    <div className={css.points}>
      <img src={pixCoin} alt="" />
      <span>{pixAmount} PIX</span>
    </div>
  </div>
}