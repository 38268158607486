import cn from "classnames"
import css from "./PetsSelect.module.scss"
import { Button } from "../../../../components/Buttons/Button";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IPet } from "../../types";
import { PetsList } from "./components/PetsList/PetsList";
import avatarStyles from "../../../profile/components/Avatar/AvatarSelection/AvatarSection.module.scss"
import { petsStore } from "../../store/petsStore";
import { petsService } from "../../service/service";
import { useUserStore } from "../../../../services/user/store";

interface IPetsSelect {
  openProofModal: () => void
}

export const PetsSelect: React.FC<IPetsSelect> = ({
  openProofModal
}) => {
  const { user } = useUserStore();
  const { pets, selectedPet, changingPet: loading } = petsStore()

  const currentUserPet = pets.find(
    ({ userPet }) => !!userPet?.id && userPet?.id === user?.pet?.id
  ) || null;

  const [currentPet, setCurrentPet] = useState<IPet | null>(
    currentUserPet
  );

  // const { levelUpError } = usePetsStore();

  const { t } = useTranslation();

  const handleSave = async () => {
    if (selectedPet?.userPet) {
      const isSaved = await petsService.changePet({ userPetId: selectedPet.userPet.id });
      if (isSaved) {
        setCurrentPet(selectedPet);
        petsService.setSelectedPet(selectedPet)
        petsService.setIsPetsModalOpen(false)
      }
    }
  };

  const handleCancel = () => {
    petsService.setSelectedPet(currentUserPet)
    petsService.setIsPetsModalOpen(false)
  }

  useEffect(() => {
    setCurrentPet(
      pets.find(({ id }) => currentUserPet?.id === id) || currentUserPet
    );
    // setSelectedPet(currentUserPet || pets[0]);
  }, [currentUserPet, pets]);

  return <div className={`${css.petsModalWrapper} custom-scrollbar`}>
    <div
      className={cn(
        css.petsListWrapper,
        !selectedPet && css.petsListWrapperWide
      )}
    >
      <ul
        className={`${css.petsList} custom-scrollbar`}
        style={loading ? { opacity: 0.5 } : {}}
      >
        <PetsList
          selectedPet={selectedPet}
          openProofModal={openProofModal}
        />
      </ul>
    </div>
    <div className={`${avatarStyles.avatarActions} ${css.avatarActions}`}>
      <Button
        className={avatarStyles.large_button}
        disabled={loading}
        isUppercased
        onClick={handleCancel}>
        {t("buttons.cancel")}
      </Button>
      <Button
        className={`${avatarStyles.large_button} ${avatarStyles.purple}`}
        isUppercased
        isLoading={loading}
        onClick={handleSave}
        disabled={currentPet?.id === selectedPet?.id || !pets.find(pet => {
          return pet.id === selectedPet?.id
        })?.userPet}

      >
        {t("buttons.save")}
      </Button>
    </div>
  </div>
}