import React from "react";

export const CloseIcon = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="63" height="63" rx="22.5" fill="#151517"/>
      <rect x="0.5" y="0.5" width="63" height="63" rx="22.5" stroke="url(#paint0_linear_5164_688)"/>
      <rect x="22.8076" y="39.7782" width="24" height="2" rx="1" transform="rotate(-45 22.8076 39.7782)" fill="white"/>
      <rect x="39.7781" y="41.1924" width="24" height="2" rx="1" transform="rotate(-135 39.7781 41.1924)" fill="white"/>
      <defs>
        <linearGradient id="paint0_linear_5164_688" x1="5.82904" y1="9.69327" x2="37.5702" y2="33.3753"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#1D1D20"/>
          <stop offset="1" stopColor="#2B2C30" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>

  );
};
