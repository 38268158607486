import React from "react";
import { StackingModal } from "./index";

interface PropsTypes {
  isModalOpen: boolean;
  setIsModalOpen: (c: boolean) => void;
}

export interface HistoryTypes {
  winnersListLink?: string;
  epochIndex?: number;
  currentAddress?: string;
  snapshotDate: string;
  lotteryHeldDate: Date;
  hideRewards?: boolean;
  winnerUrl?: string;
  contractAddress: string;
  timeout?: number;
  isEthChainConnected: boolean;
  isWaiting: boolean;
  isWinner: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const arr: HistoryTypes[] = [
  {
    epochIndex: 0,
    currentAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    snapshotDate: (+Date.now()).toString(),
    lotteryHeldDate: new Date(),
    hideRewards: false,
    winnerUrl: "https://google.com",
    contractAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    timeout: 1000,
    isEthChainConnected: true,
    isWaiting: true,
    isWinner: false,
  },
  {
    epochIndex: 1,
    currentAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    snapshotDate: (+Date.now() + 100000).toString(),
    lotteryHeldDate: new Date(1719914424841),
    hideRewards: false,
    winnerUrl: "https://google.com",
    contractAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    timeout: 2000,
    isEthChainConnected: true,
    isWaiting: false,
    isWinner: false,
  },
  {
    epochIndex: 2,
    currentAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    snapshotDate: (+Date.now() + 90000).toString(),
    lotteryHeldDate: new Date(1718914424841),
    hideRewards: false,
    winnerUrl: "https://google.com",
    contractAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    timeout: 3000,
    isEthChainConnected: true,
    isWaiting: false,
    isWinner: true,
  },
  {
    epochIndex: 3,
    currentAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    snapshotDate: (+Date.now() + 90000).toString(),
    lotteryHeldDate: new Date(1718914424841),
    hideRewards: false,
    winnerUrl: "https://google.com",
    contractAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    timeout: 3500,
    isEthChainConnected: false,
    isWaiting: true,
    isWinner: false,
  },
  {
    epochIndex: 5,
    currentAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    snapshotDate: (+Date.now() + 80000).toString(),
    lotteryHeldDate: new Date(1717914424841),
    hideRewards: false,
    winnerUrl: "https://google.com",
    contractAddress: "0x7baBf95621f22bEf2DB67E500D022Ca110722FaD",
    timeout: 3500,
    isEthChainConnected: true,
    isWaiting: true,
    isWinner: false,
  },
];

export const StackingHistoryModal = ({ isModalOpen, setIsModalOpen }: PropsTypes) => {
  return (
    <StackingModal
      title={"History"}
      type={"history"}
      titleValue={"34"}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <></>
    </StackingModal>
  );
};
