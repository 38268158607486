import React from "react";

export const CloseIconPlain = (props: JSX.IntrinsicElements["svg"]) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.40541 0.930532C2.72199 0.247114 1.61395 0.247114 0.930532 0.930532C0.247114 1.61395 0.247114 2.72199 0.930532 3.4054L5.52645 8.00133L0.930581 12.5972C0.247164 13.2806 0.247164 14.3887 0.930581 15.0721C1.614 15.7555 2.72204 15.7555 3.40546 15.0721L8.00132 10.4762L12.5971 15.072C13.2806 15.7554 14.3886 15.7554 15.072 15.072C15.7554 14.3886 15.7554 13.2806 15.072 12.5972L10.4762 8.00133L15.0721 3.40545C15.7555 2.72204 15.7555 1.614 15.0721 0.930579C14.3887 0.247162 13.2806 0.247162 12.5972 0.930579L8.00132 5.52645L3.40541 0.930532Z"
        fill="#737374"
      />
    </svg>
  );
};
