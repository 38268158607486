import { DiscordIcon } from "../../../../../assets/iconComponents/DiscordIcon";
import { TelegramIcon } from "../../../../../assets/iconComponents/TelegramIcon";
import { TwitterIcon } from "../../../../../assets/iconComponents/TwitterIcon";
import { userService } from "../../../../../services/user/service";
import { SocialName } from "../../../../../services/user/types";
import { handleExternalLinkOpen } from "../../../../../utils/handleExternalLinkOpen";
import styles from "./Social.module.scss"

export const socialIcons = {
  [SocialName.Telegram]: <TelegramIcon />,
  [SocialName.Twitter]: <TwitterIcon />,
  [SocialName.Discord]: <DiscordIcon />,
};

export const Social = ({
  social,
  link,
  username,
}: {
  social: SocialName;
  link?: string;
  username?: string;
}) => {
  const isVisible = userService.getSocialVisibility(social);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (link) {
          handleExternalLinkOpen(link);
        }
      }}
    >
      {socialIcons[social]}
      <div className="hidden md:block text-white text-sm font-bold max-w-[150px] truncate">
        {username ? "@" + username : "Connect"}
      </div>
    </div>
  );
};
